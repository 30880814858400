import { Inject, Injectable } from '@angular/core';
import { ActivationEnd, ActivationStart, Router } from '@angular/router';
import { PageFacade } from '@common/core/frontend-shared';
import { ScopeState } from '@core/frontend-shared/scope/scope.state';
import { Store } from '@ngxs/store';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { combineLatest, distinctUntilChanged, filter, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '@core/shared/config';
import { extractHostFromUrl } from '@core/shared/utils';

const SUBDOMAIN_META_STRATEGY: 'noindex'|'canonical' = 'canonical';

@Injectable({providedIn:'root'})
@AutoUnsubscribe()
export class ScopeMetadataService {

	constructor(
		store:Store,
		page:PageFacade,
		router:Router, 
		config:ConfigService,
		@Inject(DOCUMENT) private document: Document
	) {
		if(SUBDOMAIN_META_STRATEGY==='noindex') {
			store.select(ScopeState.scope).pipe(
				takeWhileAlive(this)
			).subscribe(scope=>{
				const isDefaultScope = scope === 'default';

				if(!isDefaultScope) {
					// we only want crawlers to index the main page (-> scope default).
					// so whenever we are in a custom scope, add noindex instruction.
					page.setMeta([
						{name:'robots',content:'noindex'}
					])
				} else {
					page.setMeta([
						{name:'robots',content:null}
					])
				}
			})
		}

		if(SUBDOMAIN_META_STRATEGY==='canonical') {

			const mainHost = config.get('app.frontendHost');

			combineLatest([
				store.select(ScopeState.scope),
				/**
				 * Note: the event may emit multiple times during navigation.
				 * We cannot work around that because that is how lazy loaded routes work.
				 */
				router.events.pipe(
					filter((event)=>event instanceof ActivationEnd),
				)
			]).pipe(
				takeWhileAlive(this)
			).subscribe(([scope,event])=>{
				const isDefaultScope = scope === 'default';

				if(!isDefaultScope) {
					// window.location is not accessible during SSR, use solution found here:
					// https://github.com/angular/universal/issues/1137#issuecomment-581623682
					const l = this.document.location;
					const canonical = `${l.protocol}//${mainHost}${l.pathname}${l.search}`

					// console.log('lcoation',l,canonical);
					page.setMeta([
						{name:'canonical',content:canonical}
					])
				} else {
					page.setMeta([
						{name:'canonical',content:null}
					])
				}
			})
		}
	}

}

