import { ROUTES } from '@angular/router';
import { CustomRouteType } from './custom-route.interface';
import { RouteManager } from './route-manager';
export function provideRootRoutes(factoryOrRoutes, dependencies) {
  return routeProviderFactory(CustomRouteType.ROOT, factoryOrRoutes, dependencies);
}
export function provideChildRoutes(factoryOrRoutes, dependencies) {
  return routeProviderFactory(CustomRouteType.CHILD, factoryOrRoutes, dependencies);
}
export function provideFeatureAddonRoutes(factoryOrRoutes, dependencies) {
  return routeProviderFactory(CustomRouteType.ADDON, factoryOrRoutes, dependencies);
}
function routeProviderFactory(type, factoryOrRoutes, dependencies) {
  const allDeps = [...(dependencies || []), RouteManager];
  const wrappingFactory = (...deps) => {
    const routeManager = deps.pop();
    let routes;
    if (typeof factoryOrRoutes === 'function') {
      routes = factoryOrRoutes.call(undefined, ...deps);
    } else {
      routes = factoryOrRoutes;
    }
    addTypeInfoToRoutes(routes, type);
    routeManager.registerRoutes(routes);
    // addon routes are NOT provided to ROUTES!
    // instead routeManager will cache them and add them as children to matching addonSlot routes.
    if (type === CustomRouteType.ADDON) return [];else return routes;
  };
  return {
    provide: ROUTES,
    multi: true,
    deps: allDeps,
    useFactory: wrappingFactory
  };
}
function addTypeInfoToRoutes(routes, type) {
  routes.forEach(r => {
    r._type = type;
  });
}