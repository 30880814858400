'use strict';

export function transformEnvNameToConfigName(input) {
  if (input.substring(0, 5) === 'APP__') input = input.substring(5);
  input = input.toLowerCase();
  return input.replace(/[_]/g, '.');
}
export function transformKeysToLowerCase(oldObj) {
  let newObj;
  if (Array.isArray(oldObj)) {
    newObj = [];
    for (let i = 0; i < oldObj.length; i++) {
      newObj[i] = transformKeysToLowerCase(oldObj[i]);
    }
  } else if (typeof oldObj === 'object' && oldObj !== null) {
    newObj = {};
    for (const k in oldObj) {
      if (Object.prototype.hasOwnProperty.call(oldObj, k)) newObj[k.toLowerCase()] = transformKeysToLowerCase(oldObj[k]);
    }
  } else {
    return oldObj;
  }
  return newObj || null;
}
;