import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { Config, ENV_CONFIG_KEY } from '@core/shared/config';
import { domainInfoToHostName, getCurrentDomainInfo } from '../utils';
export function loadConfigForFrontend() {
  return _loadConfigForFrontend.apply(this, arguments);
}
function _loadConfigForFrontend() {
  _loadConfigForFrontend = _asyncToGenerator(function* () {
    return new Promise((res, rej) => {
      const currHost = domainInfoToHostName(getCurrentDomainInfo(document.location.href));
      const req = new XMLHttpRequest();
      req.open("GET", '//' + currHost + '/assets/static_config.json');
      req.setRequestHeader('Accept', 'text');
      req.setRequestHeader('Cache-Control', 'max-age=' + 60 * 15); // allow age of 15 min
      req.setRequestHeader('Pragma', "");
      req.send();
      req.addEventListener("load", () => {
        const configData = JSON.parse(req.response);
        res(configData);
      });
      req.addEventListener("error", e => {
        rej('static_config.json failed to load.' + e);
      });
    }).then(configData => {
      Config.initializeRuntimeConfig(configData[ENV_CONFIG_KEY]);
      delete configData[ENV_CONFIG_KEY];
      Config.initializeStaticConfig(configData);
    });
  });
  return _loadConfigForFrontend.apply(this, arguments);
}