import { arraySplitByCondition } from '@core/shared/utils';
export function appMessageIsSameAs(messageA, messageB) {
  // only match messages that have been created at nearly same time
  if (Math.abs(messageA.timestamp - messageB.timestamp) > 1000) return false;
  if (messageA.getSeverity() !== messageB.getSeverity()) return false;
  if (messageA.isError !== messageB.isError) return false;
  if (messageA.getStatus() !== messageB.getStatus()) return false;
  if (messageA.getSummary() !== messageB.getSummary()) return false;
  return true;
}
export function handleDuplicateAppMessages(messages) {
  let leftoverMessages = [...messages];
  const outputMessages = [];
  while (leftoverMessages.length > 0) {
    const refMessage = leftoverMessages[0];
    const duplicateIndexes = [];
    for (let searchIndex = 1; searchIndex < leftoverMessages.length; searchIndex++) {
      if (appMessageIsSameAs(refMessage, leftoverMessages[searchIndex])) {
        duplicateIndexes.push(searchIndex);
      }
    }
    if (duplicateIndexes.length) {
      // separate matched duplicates from other messages found in leftoverMessages
      const [duplicates, others] = arraySplitByCondition(leftoverMessages, (message, index) => {
        return index === 0 || duplicateIndexes.includes(index);
      });
      leftoverMessages = others;
      // check list of duplicates for an APpMessage that already lists duplicates.
      // if one is found, use this as a base, else use the oldest AppMessage (first element)
      let baseMessageIndex = duplicates.findIndex(message => message.duplicateCount > 0);
      if (baseMessageIndex < 0) baseMessageIndex = 0;
      // add duplicates to baseMessage
      const baseMessage = duplicates[baseMessageIndex];
      duplicates.splice(baseMessageIndex, 1);
      baseMessage.addDuplicates(...duplicates);
      // push only the baseMessage of these duplicates to the to-be-returned array
      outputMessages.push(baseMessage);
    } else {
      outputMessages.push(leftoverMessages.shift());
    }
  }
  return outputMessages;
}