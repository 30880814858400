import { Subject } from 'rxjs';
/**
 * ProtocolDistributor acts as a router to one or many loggers.
 * TODO: rename to something more appropriate. ProtocolDistributor is just used as a helper service for distribution to loggers.
 */
export class ProtocolDistributor {
  constructor(registry, name, ProtocolLoggerCtors = []) {
    this.name = name;
    this.protocol$ = new Subject();
    this.loggers = [];
    this.displayName = name;
    registry.register(this);
    ProtocolLoggerCtors.forEach(Ctor => this.registerLogger(Ctor));
    this.protocol$.subscribe(entry => {
      this.loggers.forEach(logger => logger.log(entry));
    });
    this.write('log created');
  }
  write(message, data) {
    this.protocol$.next({
      message,
      group: null,
      data,
      date: new Date()
    });
  }
  writeToGroup(group, message, data) {
    this.protocol$.next({
      message,
      group,
      data,
      date: new Date()
    });
  }
  registerLogger(Ctor) {
    this.loggers.push(new Ctor(this));
  }
  setDisplayName(name) {
    this.displayName = name;
  }
}