import * as memoizee from 'memoizee';
import { shareReplay, Subject, takeUntil } from 'rxjs';
// a very simple decorator allowing the result of a method to be cached for a certain time or permanently.
export function MemoizedMethod(config = {}) {
  return (target, key, descriptor) => {
    const oldFunction = descriptor.value;
    let newFunction;
    if (config.observable) {
      newFunction = memoizedObservableMethod(oldFunction, config);
    } else {
      newFunction = memoizee(oldFunction, config);
    }
    descriptor.value = function (...args) {
      return newFunction.apply(this, args);
    };
  };
}
;
function memoizedObservableMethod(toWrap, options) {
  const disposeSymbol = Symbol('___dispose');
  const newOptions = {
    // for some reason, a custom normalizer is required, 
    // without it, memoizee would ignore arguments and always return the data that was cached on first call.
    normalizer: function (...args) {
      return JSON.stringify(args);
    },
    ...options,
    dispose(value) {
      // allow memoizee to trigger expired$ which will unsubscribe from observable via takeUntil
      value[disposeSymbol]();
    }
  };
  /**
   * this is kind of a double-cache implementation - memoizee will cache the observable itself, not its result.
   * But the observable will be wrapped as shared, so subscribing to it again will not re-run the observable source.
   */
  return memoizee(function (...args) {
    const result$ = toWrap.apply(this, args);
    const expired$ = new Subject();
    const sharedResult$ = result$.pipe(takeUntil(expired$), shareReplay(1));
    sharedResult$[disposeSymbol] = () => expired$.next(void 0);
    return sharedResult$;
  }, newOptions);
}
export function clearMemoizedData(func) {
  if (typeof func['clear'] === 'function') func['clear']();
}