import { registerDecorator } from "class-validator";
// import { ValidationMetadata } from 'class-validator/esm5/metadata/ValidationMetadata';
export function AllowEmpty(validationOptions) {
  // setup as suggested by manual
  return function (object, propertyName) {
    registerDecorator({
      name: 'conditionalValidation',
      // same as type: ValidationTypes.CONDITIONAL_VALIDATION, (which is unsupported by registerDecorator)
      target: object.constructor,
      propertyName: propertyName,
      constraints: [
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (obj, value) => {
        return obj[propertyName] !== null && obj[propertyName] !== undefined && obj[propertyName] !== '';
      }],
      options: validationOptions,
      validator: () => true
    });
  };
}