import { Observable } from 'rxjs';
function notifyObserver(message, observer) {
  if (!observer || observer.closed === true || observer.next === undefined) {
    return;
  }
  observer.next(message);
}
/**
 * rxjs does not provide any info on number of subscribers.
 * This pipe allows to expose number of subscriptions by sending it to a passed-in behaviorSubject.
 * Found at https://github.com/LayZeeDK/rxjs-subscription-count
 */
export function subscriptionCount(counterObserver) {
  return source => {
    let subscriptionCounter = 0;
    return new Observable(observer => {
      const innerSubscription = source.subscribe(observer);
      subscriptionCounter += 1;
      notifyObserver(subscriptionCounter, counterObserver);
      return () => {
        innerSubscription.unsubscribe();
        subscriptionCounter -= 1;
        notifyObserver(subscriptionCounter, counterObserver);
      };
    });
  };
}