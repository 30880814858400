export function isAbsoluteUrl(url) {
  return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//');
}
export function isSpecialUrl(url) {
  return url.startsWith('mailto:') || url.startsWith('tel:');
}
export function isRelativeUrl(url) {
  return !isAbsoluteUrl(url) && !isSpecialUrl(url);
}
export function extractHostFromUrl(url) {
  return new URL(url).host;
}