import { clamp, randomString } from '@core/shared/utils';
import { dayjs } from '@core/shared/utils/dayjs';
export var MembershipHelper;
(function (MembershipHelper) {
  function isMembershipValid(membership) {
    if (!membership) return false;
    const isValid = dayjs().isBefore(membership.validUntil);
    return isValid;
  }
  MembershipHelper.isMembershipValid = isMembershipValid;
  function membershipDaysLeft(membership) {
    if (!membership) return 0;
    const daysLeft = dayjs(membership.validUntil).diff(dayjs(), 'days');
    return clamp(daysLeft, 0, 9999);
  }
  MembershipHelper.membershipDaysLeft = membershipDaysLeft;
  function paymentPending(membership) {
    if (!membership) return null;
    if (!membership.paymentRequired) return false;
    return !membership.paymentCompleted;
  }
  MembershipHelper.paymentPending = paymentPending;
  // reusable helper, most plugins will use this to calculate validUntil date for new memberships
  function calculateValidUntil(days, existingMembershipValidity) {
    if (existingMembershipValidity) {
      const membershipIsStillValid = dayjs.utc().isBefore(dayjs.utc(existingMembershipValidity));
      let baseDate;
      if (membershipIsStillValid) {
        baseDate = dayjs.utc(existingMembershipValidity);
      } else {
        baseDate = dayjs.utc();
      }
      const validUntil = baseDate.add(days, 'day').toISOString();
      return validUntil;
    } else {
      return dayjs.utc().add(days, 'day').toISOString();
    }
  }
  MembershipHelper.calculateValidUntil = calculateValidUntil;
  // generate a complex token used to safely limit public access.
  function generateUuidToken() {
    return randomString(64);
  }
  MembershipHelper.generateUuidToken = generateUuidToken;
})(MembershipHelper || (MembershipHelper = {}));