import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { waitForDOMLoaded } from '@core/frontend-shared/utils/dom';
import { loadConfigForFrontend } from '@core/frontend-shared/config';


loadConfigForFrontend().then(()=>{
	return waitForDOMLoaded().then(() => {
		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch(err => console.error(err));
	});
})

