import { getStyle, toNode, toNodes, toWindow } from "./util";
export function getOffset(element, offset = false) {
  // eslint-disable-next-line prefer-const
  let {
    offsetTop,
    offsetLeft,
    offsetHeight,
    offsetWidth
  } = element;
  if (offset) {
    [offsetTop, offsetLeft] = offsetPosition(element);
  }
  return {
    top: offsetTop,
    left: offsetLeft,
    bottom: offsetTop + offsetHeight,
    right: offsetLeft + offsetWidth
  };
}
export function isVisible(elements) {
  return toNodes(elements).some(element => {
    return element.offsetWidth || element.offsetHeight || element.getClientRects().length;
  });
}
export function offsetPosition(element) {
  const offset = [0, 0];
  element = toNode(element);
  do {
    offset[0] += element.offsetTop;
    offset[1] += element.offsetLeft;
    if (getStyle(element, 'position') === 'fixed') {
      const win = toWindow(element);
      offset[0] += win.pageYOffset;
      offset[1] += win.pageXOffset;
      return offset;
    }
  } while (element = element.offsetParent);
  return offset;
}