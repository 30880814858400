import { DynamicFieldInstruction } from './shared';
export class InputTextInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      width: null
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      width: 'width'
    };
    this.attributeMap = {};
    this.setConfig(config);
  }
  load() {
    return import( /* webpackChunkName: "ui-input-text" */
    /* webpackMode: "lazy" */
    '@common/ui/frontend-shared/input-text').then(bundle => {
      return {
        component: bundle.InputTextComponent,
        module: bundle.UiInputTextModule
      };
    });
  }
}