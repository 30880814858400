import { validate } from 'class-validator';
import { from } from 'rxjs';
export const CLASS_VALIDATOR_GLOBAL_CONFIG = {
  always: true,
  strictGroups: true,
  skipMissingProperties: false
};
export function validateModel(model) {
  return from(validate(model, {
    ...CLASS_VALIDATOR_GLOBAL_CONFIG
  }));
}