import { Config } from './config';
// no shared injectable
// provided by CoreConfigModules / using provider / provider with factory
export class ConfigService {
  get(name, defaultValue) {
    if (!IS_PRODUCTION) {
      const deprecated = ['platform', 'isProduction'];
      if (deprecated.includes(name)) throw new Error('Error: ConfigService.get("' + name + '") has been deprecated and must not be used anymore.');
    }
    return Config.get(name, defaultValue);
  }
  getStaticConfig() {
    return Config;
  }
  getPlatform() {
    return this.platformOverride || PLATFORM;
  }
  isPlatform(name) {
    return this.getPlatform() === name;
  }
  /**
   * @param protocol - false to omit protocol, true for default, string for custom protocol.
   */
  buildPlatformUrl(name, protocol = '', pathToAppend = undefined) {
    if (protocol === true) protocol = 'https:';
    let host;
    if (name === 'backend') {
      host = this.get(`app.apiHost`);
    } else {
      host = this.get(`app.${name}Host`);
    }
    if (pathToAppend) {
      if (!pathToAppend.startsWith('/')) pathToAppend = '/' + pathToAppend;
      return protocol + '//' + host + pathToAppend;
    } else {
      return protocol + '//' + host;
    }
  }
  setOverride(name, value) {
    if (name === 'platform') this.platformOverride = value;else Config.setOverride(name, value);
  }
  clearOverride(name) {
    Config.clearOverride(name);
  }
}