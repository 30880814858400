import { subscriptionCount } from '@core/shared/utils';
import { BehaviorSubject, Subject } from 'rxjs';
/**
 * a 1:1 replacement for primeng ConfirmationService.
 * Does not inherit it because of private props and modified implementation.
 * Custom version includes tracking of how many components are subscribing to this service.
 * If multiple components subscribe to this (which must not happen, because then multiple dialogs will toggle at once),
 * it will log a trace on where the subscription originates from.
 */
export class ConfirmDialogService {
  constructor() {
    this.requireConfirmationSource = new Subject();
    this.acceptConfirmationSource = new Subject();
    this.confirmationSubscriberCount = new BehaviorSubject(0);
    this._requireConfirmation$ = this.requireConfirmationSource.asObservable().pipe(subscriptionCount(this.confirmationSubscriberCount));
    this.accept = this.acceptConfirmationSource.asObservable();
  }
  /**
   * ConfirmDialog Ctor will immediately subscribe to confirmationService.requireConfirmation$.
   * Same for ConfirmPopup (which is not used by us at all).
   * These are the only situations where requireConirmation$ is accessed.
   * So we can safely use it to check subscriber count and warn if multiple components try to subscribe.
   *
   */
  get requireConfirmation$() {
    if (!IS_PRODUCTION) {
      if (this.confirmationSubscriberCount.getValue() > 0) {
        console.warn('A ui-confirmDialog tries to subscribe to a ConfirmDialogService that is already in use by another ConfirmDialog! Did you forget to provide a new ConfirmDIalogService close to the template creating the ui-confirmDialog?');
        console.trace();
      }
    }
    return this._requireConfirmation$;
  }
  confirm(confirmation) {
    if (!IS_PRODUCTION) {
      if (this.confirmationSubscriberCount.getValue() === 0) {
        console.warn('Tried to spawn a ui-confirmDialog, but ConfirmDialogService has no subscribers! Did you provide a new ConfirmDialog without adding an associated ui-confirmDialog in a close template?');
        console.trace();
      }
    }
    this.requireConfirmationSource.next(confirmation);
    return this;
  }
  close() {
    this.requireConfirmationSource.next(null);
    return this;
  }
  onAccept() {
    this.acceptConfirmationSource.next(null);
  }
}