import { extendsConstructor, isConstructor, truncateText } from '../../utils';
import * as deepmerge from 'deepmerge';
import { customMetadataStorage } from '../custom-metadata/storage';
import { ItemModel } from '../model/item.model';
// not exported as considered private. Use provided functions to get metadata.
const ITEM_MODEL_INFO_KEY = 'ITEM_MODEL_INFO_KEY';
const defaultOptions = {
  texts: {
    item: 'Item',
    items: 'Items',
    batch: 'Stapelverarbeitung',
    viewItem: 'Detailansicht',
    newItem: (item, info) => `${info.texts.item} neu erstellen`,
    cloneItem: (item, info) => `Kopie aus Daten von "${truncateText(item.name || item)}" erstellen`,
    editItem: (item, info) => `${info.texts.item} "${truncateText(item.name || item)}" bearbeiten`,
    noId: (item, info) => `${info.texts.item} existiert nicht (keine ID vorhanden)`,
    itemNotExists: (item, info) => `${info.texts.item} mit ID ${item.id} existiert nicht!`,
    confirmDeleteItem: (item, info) => `Möchten Sie wirklich "${truncateText(item.name || item)}" löschen?`
  }
};
export function ItemModelDefinition(info) {
  return function ItemModelOptionsDecorator(ItemClass) {
    if (!extendsConstructor(ItemClass, ItemModel)) throw Error('ItemInfo decorator must only be used on classes extending ItemModel!');
    customMetadataStorage.registerItemModel(ItemClass);
    customMetadataStorage.addMetadata({
      metaType: ITEM_MODEL_INFO_KEY,
      target: ItemClass,
      propertyName: null,
      options: info
    });
  };
}
export function getItemModelDefinition(ItemClass) {
  const metas = customMetadataStorage.findMetadataOfType(ITEM_MODEL_INFO_KEY, ItemClass);
  if (metas.length) return deepmerge(defaultOptions, metas[0].options);else return deepmerge({}, defaultOptions);
}
export function getItemModelText(name, model) {
  let info;
  if (model instanceof ItemModel) {
    info = getItemModelDefinition(model.constructor);
  } else if (isConstructor(model) && extendsConstructor(model, ItemModel)) {
    info = getItemModelDefinition(model);
  } else {
    // throw new Error('model is no instance or constructor of ItemModel')
    info = defaultOptions;
  }
  const text = info.texts[name];
  if (typeof text === 'function') {
    return text(model, info);
  }
  return text;
}