import { MembershipHelper, MembershipPlugin, MembershipReportHelper } from '@common/membership/shared';
import { objectWithout, getDotProp } from '@core/shared/utils';
import { of } from 'rxjs';
/** plugin to support viewing memberships of legacy "payment" type */
export class PaymentMembershipPlugin extends MembershipPlugin {
  getFormDataModel() {
    throw new Error('Method not implemented.');
  }
  static getInfo() {
    return {
      id: 'payment',
      name: 'Selbstzahler (legacy)',
      description: "Registrierung (Selbstzahler)",
      paymentRequired: true
    };
  }
  calculateValidUntil(existingMembershipValidity) {
    return MembershipHelper.calculateValidUntil(180, existingMembershipValidity);
  }
  getLabelForField(fieldId) {
    switch (fieldId) {
      case 'reason':
        return 'Grund der Anmeldung';
      case 'topic':
        return 'Hauptthema';
      case 'careDegree':
        return 'Pflegegrad vorhanden';
      case 'username':
        return 'Benutzername';
      case 'email':
        return 'E-Mail-Adresse';
      case 'firstName':
        return 'Vorname';
      case 'lastName':
        return 'Nachname';
      case 'password':
        return 'Passwort';
      case 'password2':
        return 'Passwort wiederholen';
      case 'insuranceMembershipId':
        return 'Versichertennummer';
      case 'birthdate':
        return 'Geburtsdatum';
      case 'address':
        return 'Straße und Hausnr.';
      case 'zipcode':
        return 'Postleitzahl';
      case 'city':
        return 'Ort';
      case 'privateInsuranceMembershipId':
        return 'Privat-Versichertennr.';
    }
    return '<' + fieldId + '>';
  }
  generateSummaryData(data, withAccountData = false) {
    const fields = this.getRegistrationDataFields();
    const invoiceRows = fields.invoice.map(field => {
      return {
        label: this.getLabelForField(field),
        value: this.getDisplayValue(field, data['invoice'][field])
      };
    });
    const invoiceSummary = {
      id: 'invoice',
      title: 'Rechnungsangaben',
      rows: invoiceRows
    };
    const summary = [invoiceSummary];
    if (withAccountData) {
      const accountRows = fields.account.map(field => {
        return {
          label: this.getLabelForField(field),
          value: this.getDisplayValue(field, data['account'][field])
        };
      });
      const accountSummary = {
        id: 'account',
        title: 'Ihr Account',
        rows: accountRows
      };
      summary.push(accountSummary);
    }
    return of(summary);
  }
  generateReportData(memberships, detailed) {
    const reports = [];
    const info = this.getStaticPluginInfo();
    reports.push({
      name: info.name,
      id: '0',
      membershipType: info.id,
      entries: memberships
    });
    return reports;
  }
  getReportColumnHeaders() {
    const fields = MembershipReportHelper.getRegistrationDataFieldsAsDotProps(this.getRegistrationDataFields(false));
    return fields;
  }
  getReportColumnValues(membership, data) {
    const fields = MembershipReportHelper.getRegistrationDataFieldsAsDotProps(this.getRegistrationDataFields(false));
    return fields.map(fieldName => {
      return getDotProp(data, fieldName) || '-';
    });
  }
  getRegistrationDataFields(includeAccount = true) {
    const fields = {
      invoice: ['firstName', 'lastName', 'address', 'zipcode', 'city', 'privateInsuranceMembershipId']
    };
    if (includeAccount) fields['account'] = ['username', 'email', 'firstName', 'lastName', 'password'];
    return fields;
  }
  preparePersistedRegistrationData(data) {
    return {
      reason: data.reason,
      insuranceInfo: data.insuranceInfo,
      account: data.account ? objectWithout(data.account, ['password', 'password2']) : undefined
    };
  }
}