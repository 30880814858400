import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { MediaAssetProviderCloudinary } from '@common/cloudinary/shared';
import { AppRequestService } from '@core/frontend-shared';
import { CloudinarySelectFromHostComponent } from './select-from-host.component';
import { CloudinaryTransformationsComponent } from './transformations.component';
export class MediaAssetProviderCloudinaryFrontend extends MediaAssetProviderCloudinary {
  setInjector(injector) {
    super.setInjector(injector);
    this.api = injector.get(AppRequestService);
  }
  getUploadPathSuggestions() {
    return _asyncToGenerator(function* () {
      return Promise.resolve(['/', '/meinpflegeforum', '/meinpflegeforum/certificates', '/meinpflegeforum/courses']);
    })();
  }
  selectFromHost_getComponent() {
    return CloudinarySelectFromHostComponent;
  }
  transformationManager_getComponent() {
    return CloudinaryTransformationsComponent;
  }
}