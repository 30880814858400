// NOTE: sensitive to barrel imports! Use deep imports!
import { getDiff } from '../utils';
/**
 * Needs injection factory providing platform specific AccessServiceProvider!
 * Use FrontendRestraintsFactory / BackendRestraintsFactory to create instances!
 */
/**
 * TODO: #86c0agb3p - add helpers to validate against role and abilities
 */
export class ItemRestraints {
  get access() {
    const tmp = this.accessProvider.get();
    if (!tmp) console.error('could not get AccessService', tmp, this.accessProvider);
    return tmp;
  }
  constructor(accessProvider, executorProvider) {
    this.accessProvider = accessProvider;
    this.executorProvider = executorProvider;
    if (!accessProvider) throw new Error('ItemRestraints created without passing accessProvider');
  }
  canViewItem(item) {
    return true;
  }
  canCreateItem(item) {
    return true;
  }
  canUpdateItem(item, oldItem) {
    return true;
  }
  canToggleItem(item, oldItem) {
    return true;
  }
  canRemoveItem(item) {
    return true;
  }
  // ########  HELPER METHODS  ################################################################
  getDiff(newItem, oldItem) {
    return getDiff(oldItem, newItem);
  }
  // requireAllPassed(checks:Array<RestraintsResult|(()=>RestraintsResult)>) {
  // 	let check;
  // 	for(let i=0; i<checks.length; i++) {
  // 		check = check[i];
  // 		if(typeof check === 'function') {
  // 			check = check();
  // 		}
  // 		if(check !== true) {
  // 			return check;
  // 		}
  // 	}
  // 	return true;
  // }
  // NOTE: Legacy, replaced by requireRole
  // requirePermission(permission:string|string[],then?:()=>RestraintsResult,reject?:()=>RestraintsResult) {
  // 	const passed = this.hasPermission(permission);
  // 	if(!passed) {
  // 		if(reject) return reject();
  // 		return "Sie haben nicht die Berechtigung, diesen Vorgang auszuführen.";
  // 	} else {
  // 		if(then) return then();
  // 		return true;
  // 	}
  // }
  hasPermission(permission) {
    if (!this.access) return false;
    return Array.isArray(permission) ? this.access.hasAllPermissions(permission) : this.access.hasAnyPermission([permission]);
  }
  requireRole(role, then, reject) {
    const passed = this.hasRole(role);
    if (!passed) {
      if (reject) return reject();
      return "Sie haben nicht die Berechtigung, diesen Vorgang auszuführen.";
    } else {
      if (then) return then();
      return true;
    }
  }
  hasRole(role) {
    if (!this.access) return false;
    return this.access.hasRole(role);
  }
  currentUserIsOwner(item) {
    if (!this.access) throw new Error("Prüfung des Besitzers fehlgeschlagen: AccessService nicht verfügbar");
    // ownerId===null is allowed, so check against undefined!
    if (typeof item.ownerId === 'undefined') throw new Error("Prüfung des Besitzers fehlgeschlagen: Item hat keine Angabe für ownerId");
    const userId = this.access.getAccessData('userId', null, 'null');
    if (!this.access.isLoggedIn || !userId) return false;
    if (userId === item.ownerId) return true;
    return false;
  }
  isExecutedBySystem() {
    return this.executorProvider.get() === 'system';
  }
}
export class AdminOnlyItemRestraints extends ItemRestraints {
  canCreateItem(item) {
    return this.requireRole('ADMINISTRATOR');
  }
  canViewItem(item) {
    return this.requireRole('ADMINISTRATOR');
  }
  canToggleItem(item) {
    return this.requireRole('ADMINISTRATOR');
  }
  canUpdateItem(item, oldItem) {
    return this.requireRole('ADMINISTRATOR');
  }
  canRemoveItem(item) {
    return this.requireRole('ADMINISTRATOR');
  }
}
export class ReadonlyItemRestraints extends ItemRestraints {
  canToggleItem(item) {
    return 'Not toggleable';
  }
  canUpdateItem(item, oldItem) {
    return 'Not updateable';
  }
  canRemoveItem(item) {
    return 'Not removable';
  }
}