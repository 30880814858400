import { SupportedFileTypes } from './file-types';
function isElement(node) {
  return !!(node && (node.nodeName || node.prop && node.attr && node.find));
}
export class FileLikeObject {
  constructor(fileOrInput) {
    this.rawFile = fileOrInput;
    const isInput = isElement(fileOrInput);
    const fakePathOrObject = isInput ? fileOrInput.value : fileOrInput;
    const postfix = typeof fakePathOrObject === 'string' ? 'FakePath' : 'Object';
    const method = '_createFrom' + postfix;
    this[method](fakePathOrObject);
  }
  _createFromFakePath(path) {
    this.lastModifiedDate = void 0;
    this.size = void 0;
    this.type = 'like/' + path.slice(path.lastIndexOf('.') + 1).toLowerCase();
    this.name = path.slice(path.lastIndexOf('/') + path.lastIndexOf('\\') + 2);
  }
  _createFromObject(object) {
    this.size = object.size;
    this.type = object.type;
    this.name = object.name;
  }
}
export var FileTypeDetectionHelper;
(function (FileTypeDetectionHelper) {
  function extractFileTypeInfo(fileInfo) {
    let mime, extension;
    if (typeof fileInfo === 'object') {
      // analyze the possibly partial fileInfo object and gather as much information as possible.
      if (fileInfo.extension) {
        extension = fileInfo.extension;
      } else if (fileInfo.filename) {
        extension = fileInfo.filename.split('.').pop();
      }
      if (fileInfo.mimetype) {
        // application/octet-stream can mean everything, omit it and prefer matching by file extension.
        if (fileInfo.mimetype !== 'application/octet-stream') {
          mime = fileInfo.mimetype;
        }
      }
    } else {
      // extract file extension from filename
      extension = fileInfo.split('.').pop();
    }
    if (!mime && !extension) throw new Error('Could not detect file type of unknown fileInfo structure ' + fileInfo);
    return {
      mime,
      extension
    };
  }
  FileTypeDetectionHelper.extractFileTypeInfo = extractFileTypeInfo;
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function detectFileTypeGroup(file) {
    const info = extractFileTypeInfo(file);
    if (!info.extension && !info.mime) throw new Error("detectFileTypeGroup failed: input data did not provide enough information to check against.");
    if (info.mime) {
      const mimeCheck = matchFileTypeByMime(info.mime);
      if (mimeCheck) return mimeCheck;
    }
    if (info.extension) {
      const extCheck = matchFileTypeByExtension(info.extension);
      if (extCheck) return extCheck;
    }
    return false;
  }
  FileTypeDetectionHelper.detectFileTypeGroup = detectFileTypeGroup;
  // we only do strict matching of known mimes now.
  // as we only care about supported, well known types, it does not make sense to try to match as much as possible.
  function matchFileTypeByMime(input) {
    const mime = typeof input === 'string' ? input : input.type;
    const matchedType = Object.keys(SupportedFileTypes).find(category => {
      const mimes = SupportedFileTypes[category].mimes;
      return mimes.some(comparator => {
        return mime === comparator;
      });
    });
    return matchedType || null;
  }
  FileTypeDetectionHelper.matchFileTypeByMime = matchFileTypeByMime;
  function matchFileTypeByExtension(input) {
    const name = typeof input === 'string' ? input : input.name;
    let extension;
    if (name.indexOf('.') > -1) {
      const nameSegments = name.split('.');
      extension = nameSegments[nameSegments.length - 1].toLowerCase();
    } else {
      extension = name.toLowerCase();
    }
    const matchedType = Object.keys(SupportedFileTypes).find(category => {
      const extensions = SupportedFileTypes[category].extensions;
      return extensions.some(ext => {
        return extension === ext;
      });
    });
    return matchedType || null;
  }
  FileTypeDetectionHelper.matchFileTypeByExtension = matchFileTypeByExtension;
})(FileTypeDetectionHelper || (FileTypeDetectionHelper = {}));