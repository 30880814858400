import * as merge from 'deepmerge';
import { isEqualDeep } from '../object-deep-equal';
import { pipe } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
/**
 * Customized RXJS pipe operator adding support for comparing complex objects.
 * Note: use of makeImmutable may be required if observable does not emit immutable value.
 * Useful to check e.g. for changes within a FormGroup/FormArray.
 */
export const distinctUntilChangedDeep = options => pipe(map(v => {
  if (options?.makeImmutable) {
    if (Array.isArray(v)) {
      const v2 = merge([], v);
      if (options?.debug) console.log('make unique:', v, v2);
      return v2;
    } else if (typeof v === 'object') {
      return merge({}, v);
    }
  }
  return v;
}), distinctUntilChanged((prev, curr) => {
  if (options?.debug) console.log('distinctUIntilChanged compare:', prev, curr, 'equal:', isEqualDeep(prev, curr));
  return isEqualDeep(prev, curr);
}));