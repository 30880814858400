export function waitForDOMLoaded() {
  return new Promise(res => {
    if (/complete|interactive|loaded/.test(document.readyState)) {
      res(true);
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        res(true);
      });
    }
  });
}