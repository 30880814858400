// export const FileTypes = {
// 	video:{
// 		extensions:['avi','mov','mp4','mpeg','webm','wmv',],
// 		mime:['video/x-msvideo','video/quicktime','video/mp4','video/mpeg','video/webm','video/x-ms-wmv']
// 	},
// 	image:{
// 		extensions:['gif','webp','jpg','jpeg','png','svg','tif','psd','bmp','tiff'],
// 		mime:['image/gif','image/webp','image/jpeg','image/png','image/svg+xml']
// 	},
// 	document:{
// 		extensions:['pdf','doc','docx',],
// 		mime:['application/pdf','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.ms-word.document.macroEnabled.12',]
// 	},
// 	archive:{
// 		extensions:['zip','rar','7z','gzip','tar'],
// 		mime:['application/x-gtar','application/x-gcompress','application/compress','application/x-tar','application/x-rar-compressed','application/x-zip-compressed','application/zip-compressed','application/x-7z-compressed','application/gzip','application/x-bzip2']
// 	},
// 	spreadsheet:{
// 		extensions:['xsl','xml','xslx','ods'],
// 		mime:['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel.sheet.macroEnabled.12', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12']
// 	},
// 	audio:{
// 		extensions:['mp3', 'wav', 'm4a']
// 	}
// }
export const SupportedFileTypes = {
  video: {
    extensions: ['avi', 'mov', 'mp4', 'mpeg', 'webm', 'wmv'],
    mimes: ['video/x-msvideo', 'video/quicktime', 'video/mp4', 'video/mpeg', 'video/webm', 'video/x-ms-wmv']
  },
  image: {
    extensions: ['gif', 'webp', 'jpg', 'jpeg', 'png', 'svg'],
    mimes: ['image/gif', 'image/webp', 'image/jpeg', 'image/png', 'image/svg+xml']
  },
  document: {
    extensions: ['pdf', 'doc', 'docx'],
    mimes: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  },
  any: {
    extensions: [],
    mimes: []
  }
};
SupportedFileTypes.any = {
  mimes: [...SupportedFileTypes.video.mimes, ...SupportedFileTypes.image.mimes, ...SupportedFileTypes.document.mimes],
  extensions: [...SupportedFileTypes.video.extensions, ...SupportedFileTypes.image.extensions, ...SupportedFileTypes.document.extensions]
};