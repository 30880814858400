import { getClassName } from '@core/shared/class-name';
/**
 * Important: Membership Plugins are designed to have pure methods only.
 * They are used in backend where the same plugin instance may handle different requests!
 * Only rely on method arguments!
 */
export class MembershipPlugin {
  constructor(injector) {
    this.injector = injector;
  }
  getStaticPluginInfo() {
    const info = this.constructor.getInfo;
    if (!info) throw new Error('MembershipPlugin error: could not find static getInfo() on class ' + getClassName(this));
    return info();
  }
  getDisplayValue(fieldId, value) {
    if (typeof value === 'boolean') return value ? 'Ja' : 'Nein';
    if (fieldId.startsWith('password')) return '*****';
    return value;
  }
  // must be implemented if requiresPayment=true. Will be called in checkout step to determine cart summary + pricing.
  getCart() {
    throw new Error('This MembershipPlugin uses payment system but its getCart() method has not been implemented!');
    return [];
  }
  // returns human readable representation of database-persisted membershipTypeCategorization field.
  getCategorizationLabel(categoryValue) {
    return categoryValue || '(Keine)';
  }
}