export const privateInsurances = [{
  name: "Allianz Private Krankenversicherung",
  id: "allianz"
}, {
  name: "Alte Oldenburger Krankenversicherung",
  id: "alte-oldenburger"
}, {
  name: "ARAG Krankenversicherung",
  id: "arag"
}, {
  name: "AXA Krankenversicherung",
  id: "axa"
}, {
  name: "Barmenia Krankenversicherung",
  id: "barmenia"
}, {
  name: "Concordia Krankenversicherung",
  id: "concordia"
}, {
  name: "Continentale Krankenversicherung",
  id: "continentale"
}, {
  name: "Debeka Krankenversicherung",
  id: "debeka"
}, {
  name: "DEVK Krankenversicherung",
  id: "devk"
}, {
  name: "Die Bayerische - BBL",
  id: "bayerische-bbl"
}, {
  name: "DKV Deutsche Krankenversicherung",
  id: "dvk"
}, {
  name: "ENVIVAS Krankenversicherung",
  id: "envivas"
}, {
  name: "ERGO Direkt Krankenversicherung",
  id: "ergo-direkt"
}, {
  name: "Generali Krankenversicherung",
  id: "generali"
}, {
  name: "Gothaer Krankenversicherung",
  id: "gothaer"
}, {
  name: "HALLESCHE Krankenversicherung",
  id: "hallesche"
}, {
  name: "HanseMerkur Krankenversicherung",
  id: "hansemerkur"
}, {
  name: "HUK-Coburg-Krankenversicherung",
  id: "huk-coburg"
}, {
  name: "Inter Krankenversicherung",
  id: "inter"
}, {
  name: "LKH Landeskrankenhilfe",
  id: "lkh"
}, {
  name: "LVM Krankenversicherungs",
  id: "lvm"
}, {
  name: "Mecklenburgische Krankenversicherung",
  id: "mecklenburgische"
}, {
  name: "Münchener Verein Krankenversicherung",
  id: "muenchener-verein"
}, {
  name: "Nürnberger Krankenversicherung",
  id: "nuernburger"
}, {
  name: "R+V Krankenversicherung",
  id: "r-v"
}, {
  name: "Signal Krankenversicherung",
  id: "signal"
}, {
  name: "Süddeutsche Krankenversicherung",
  id: "sueddeutsche"
}, {
  name: "UKV - Union Krankenversicherung",
  id: "ukv"
}, {
  name: "Universa Krankenversicherung",
  id: "universa"
}, {
  name: "Versicherungskammer Bayern",
  id: "versicherungskammer-bayern"
}, {
  name: "vigo Krankenversicherung",
  id: "vigo"
}, {
  name: "Württembergische Krankenversicherung",
  id: "wuerttembergische"
}];