export function getAllParentElements(el, limit = 30) {
  const parents = [];
  while (el?.parentElement) {
    parents.push(el.parentElement);
    el = el.parentElement;
    limit--;
    if (limit === 0) {
      return parents;
    }
  }
  return parents;
}