import { ChangeDetectorRef, Component, HostBinding, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AccessState } from '@core/frontend-shared';
import { LayoutService } from '@common/layout/frontend-shared';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { fadeAnimation } from './animations';
import { MainMenuService } from './mainmenu.service';
import { ConfigService } from '@core/shared/config';
import { PageState } from '@common/core/frontend-shared';
import { isPlatformServer } from '@angular/common';
import { TrackingService } from '@common/tracking/frontend/tracking-consent/tracking.service';
import { FeatureMenuItem } from '@core/frontend-shared/feature';

@Component({
	selector: 'pflegetheke-frontend-app',
	templateUrl: './app.component.html',
	styles: [
		':host { display:block; height:100%; background:var(--backgroundColor); }',
		':host(.wait-for-consent-setting) layout-contentbars-wrapper { filter:blur(2px); }',
		'layout-contentbar-top .right {display:flex;align-items:center; position:absolute;}',
	],
	animations:[fadeAnimation],
})
export class AppComponent {
	
	mainMenu$:Observable<FeatureMenuItem[]>

	@HostBinding('class.wait-for-consent-setting')
	waitingForConsentDecision = false
	
	@Select(AccessState.isAdmin) isAdmin$: Observable<boolean>;
	@Select(AccessState.loggedIn) loggedIn$: Observable<boolean>;
	@Select(AccessState.isReady) accessIsReady$: Observable<boolean>;
	@Select(PageState.title) pageTitle$: Observable<string>;

	isSSR:boolean;

	constructor(
		public layout:LayoutService, 
		protected menu:MainMenuService,
		protected router:Router,
		protected tracking:TrackingService,
		protected cd:ChangeDetectorRef,
		config:ConfigService,
		@Inject(PLATFORM_ID) private ngPlatformId,
	) { 
		this.isSSR = isPlatformServer(ngPlatformId);
		if(!this.isSSR) {
			tracking.consentState.subscribe(state=>{
				const waitingForConsentDecision = state === null;
				this.waitingForConsentDecision = waitingForConsentDecision;
				this.cd.markForCheck();
			});
		}
		this.mainMenu$ = menu.mainMenu$;
	}

}
