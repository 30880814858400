export function simpleCrudQueryCreateCrudService(factory, config) {
  const crudService = factory.createCrudService(config.model);
  return applySimpleCrudQueryToCrudService(crudService, config);
}
export function applySimpleCrudQueryToCrudService(crud, config) {
  crud.useCrudEndpoint(config.useCrudEndpoint);
  applySimpleCrudQuery(crud, config);
  return crud;
}
export function applySimpleCrudQuery(crudService, input) {
  crudService.query.setLimit(typeof input.limit === 'number' ? input.limit : 0);
  if (input.fields) crudService.query.setFields(input.fields);
  if (input.filter) crudService.query.setFilter(input.filter);
  if (input.sort) crudService.query.setSortBy(input.sort);
  if (input.queryParams) {
    for (const paramName of Object.keys(input.queryParams)) {
      crudService.query.setCustomParam(paramName, input.queryParams[paramName]);
    }
  }
}