import { ModelFactory } from '../model';
import { getBaseModelFromEditorModel, getEditorModel, getEditorModelDefinitionName, getEditorModelInfo, getExposedProperties, isEditorModel } from './editor-definition.lib';
import { getEditorDefinitions } from './editor-definition.decorator';
/**
 * Based on test discoveries (see plain-subobject.spec.ts, class-tranformer.spec.ts),
 * explicitly configurated excludeAll strategy has been removed.
 *
 * Instead, the base model class uses @Exclude on class level.
 * This will strip all unexposed props from the model object.
 * But it will not strip subobjects that do not use decorators/not extend Model class.
 *
 * Due to that, the custom "nestedStrategy" is not needed anymore.
 */
export class EditorModelFactory extends ModelFactory {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  constructor(PassedModel, classTransformService) {
    super(PassedModel, classTransformService);
    this.classTransformService = classTransformService;
    if (isEditorModel(PassedModel)) {
      // when working with nested forms, a EditorModel constructor may be passed to the factory. React accordingly.
      this.editorDefinitionName = getEditorModelDefinitionName(PassedModel);
      this.Model = PassedModel;
      this.OriginalModel = getBaseModelFromEditorModel(PassedModel, true);
    } else {
      if (!getEditorDefinitions(PassedModel).length) {
        console.warn(PassedModel);
        throw new Error('The specified Model has no EditorDefinitions!');
      }
      this.OriginalModel = this.Model = PassedModel;
    }
  }
  setEditorDefinitionName(id) {
    this.editorDefinitionName = id;
    this.clearCache();
    this.prepareModel();
  }
  setGroups(groups) {
    this.groups = groups;
  }
  newInstance() {
    return this.fromData({});
  }
  fromData(data) {
    this.assertSelectedDefinition();
    return this.classTransformService.plainToInstance(this.Model, data, {
      groups: this.getGroups() /*strategy:'excludeAll',nestedStrategy:'exposeAll'*/
    });
  }
  clone(item) {
    this.assertSelectedDefinition();
    const data = {
      ...item,
      id: null
    };
    return this.classTransformService.plainToInstance(this.Model, data, {
      groups: this.getGroups() /*strategy:'excludeAll',nestedStrategy:'exposeAll'*/
    });
  }
  patch(item, optionalData = {}) {
    this.assertSelectedDefinition();
    return this.classTransformService.plainToInstance(this.Model, {
      ...item,
      ...optionalData
    }, {
      groups: this.getGroups() /*strategy:'excludeAll',nestedStrategy:'exposeAll'*/
    });
  }
  getEditorModelInfo() {
    if (!this.OriginalModel) throw new Error('getEditorModelInfo called before OriginalModel has been set');
    if (!this.editorDefinitionName) throw new Error('getEditorModelInfo called before editorDefinitionName has been set');
    if (this._editorModelCache) return this._editorModelCache;
    this._editorModelCache = getEditorModelInfo(this.OriginalModel, this.editorDefinitionName);
    if (this._editorModelCache.editModeDependentProps.length) throw new Error('FormService error: Model ' + this.Model + ' uses editModeDependentProps. This feature is not yet supported!');
    return this._editorModelCache;
  }
  getOriginalModel() {
    return this.OriginalModel;
  }
  getAllExposedProperties() {
    // readonly props are exposed too so it is simple.
    const allExposed = getExposedProperties(this.Model);
    return allExposed;
  }
  getGroups() {
    return this.groups;
  }
  prepareModel() {
    this.Model = getEditorModel(this.OriginalModel, this.editorDefinitionName);
  }
  assertSelectedDefinition() {
    if (!this.editorDefinitionName || !this.Model) throw new Error('EditorModelFactory requires EditorDefinition name to be set in order to work correctly! Configurated name: ' + this.editorDefinitionName + ' Model: ' + this.Model);
  }
  clearCache() {
    this._editorModelCache = undefined;
  }
}