// for use with arbitrary URLs or window.location.search
export function parseUrlParams(url) {
  const paramsString = url.includes('?') ? url.substring(url.indexOf('?') + 1) : url;
  const paramsList = paramsString.split('&');
  const paramsMap = paramsList.reduce((map, paramString) => {
    let [key, val] = paramString.split('=');
    if (key.endsWith('[]')) {
      key = key.substring(0, key.length - 2);
      if (!map[key]) map[key] = [val];else if (!Array.isArray(map[key])) throw new Error('Invalid URL params: param "' + key + '" is used with and without array markup in parallel!');else map[key].push(val);
    } else {
      map[key] = val;
    }
    return map;
  }, {});
  return paramsMap;
}