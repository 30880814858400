import { customMetadataStorage } from '../custom-metadata/storage';
import { DEFAULT_EDITOR_DEFINITION_ID } from '../editor-definition';
// prefer to use functions provided below to access metadata.
export const EDITOR_DEFINITION_KEY = '__EDITOR_DEFINITION__';
export function EditorDefinition(p1, p2, p3) {
  let name = DEFAULT_EDITOR_DEFINITION_ID,
    data,
    options = {};
  if (typeof p1 === 'string') {
    name = p1;
    data = p2;
    if (p3) options = p3;
  } else {
    data = p1;
    if (p2) options = p2;
  }
  const completedOptions = {
    disableWhitelist: false,
    allowAllExposed: false,
    useAppRequest: false,
    useCrudEndpoint: undefined,
    ...options
  };
  return function EditorDefinitionDecorator(classConstructor) {
    customMetadataStorage.addMetadata({
      metaType: EDITOR_DEFINITION_KEY,
      target: classConstructor,
      propertyName: null,
      options: {
        name,
        data,
        options: completedOptions
      }
    });
  };
}
export function getEditorDefinitions(ModelClass) {
  const meta = customMetadataStorage.findMetadataOfType(EDITOR_DEFINITION_KEY, ModelClass);
  if (!meta.length) return [];
  return meta.map(entry => entry.options);
}