import { customMetadataStorage } from "../custom-metadata/storage";
import { defaultMetadataStorage } from "class-transformer-global-storage";
import { createChildModel, createValidClassName } from "../model";
import { editorDefinitionWhitelistedProps, EDITOR_MODELS_CACHE_KEY } from "./interfaces";
import * as merge from 'deepmerge';
import { arrayUnique } from "../../utils";
import { ClassTransformService } from '../class-transform';
import { getEditorDefinitions } from './editor-definition.decorator';
// not exported as considered private. Use provided functions to get metadata.
const EDITOR_MODEL_DEFINITION_NAME_KEY = '__EDITOR_MODEL_DEFINITION_NAME__';
// ########  Finding and preparing EditorDefinition  #####################################################################################
/**
 * get all definitions of a certain type. (There may be several ones due to ancestors)
 */
export function getEditorDefinitionsByName(ModelClass, definitionName) {
  const definitions = getEditorDefinitions(ModelClass);
  return definitions.filter(entry => entry.name === definitionName);
}
/**
 * get a flattened editorDefinition, including resolved inheritance
 */
export function getEditorDefinition(ModelClass, definitionName) {
  const definitions = getEditorDefinitionsByName(ModelClass, definitionName);
  if (!definitions?.length) throw new Error('No EditorDefinition of type ' + definitionName + ' for Model ' + ModelClass.name + ' has been found!');
  const definition = mergeDefinitions(definitions);
  if (definition.options.inherit) {
    if (definitions.length > 1) {
      // console.log('debug data:',definitions,definition)
      throw Error('EditorDefinition cannot inherit parent class definition and specify inheritance of another definition at the same time!');
    }
    const inherited = getEditorDefinition(ModelClass, definition.options.inherit);
    return mergeDefinitions([inherited, definition]);
  }
  return definition;
}
function mergeDefinitions(definitions) {
  if (definitions.length === 1) return definitions[0];
  const definition = {
    ...definitions[definitions.length - 1]
  };
  definition.options = merge.all(definitions.map(def => def.options));
  // check data
  const expectArrayData = Array.isArray(definitions[0].data);
  // console.log('expectArrayData?',expectArrayData,definitions.map(d=>d.data))
  definitions.forEach(def => {
    if (expectArrayData !== Array.isArray(def.data)) throw Error('Error while merging editor definitions: definitions must not use different data structures!');
  });
  // merge data
  definition.data = merge.all(definitions.map(def => def.data));
  if (Array.isArray(definition.data)) definition.data = arrayUnique(definition.data);
  return definition;
}
export function getModelExposedMeta(ModelClass) {
  const meta = ClassTransformService.getMetadataStorage().getExposedMetadatas(ModelClass);
  return meta;
}
export function getModelExcludedMeta(ModelClass) {
  const meta = ClassTransformService.getMetadataStorage().getExcludedMetadatas(ModelClass);
  return meta;
}
export function getSubModelMeta(ModelClass, propertyName) {
  const meta = ClassTransformService.getMetadataStorage().findTypeMetadata(ModelClass, propertyName);
  if (!meta || !meta.typeFunction) throw new Error('Could not find required Submodel Type metadata for property "' + propertyName + '" of model ' + ModelClass.name);
  return meta;
}
export function loadSubModel(ModelClass, propertyName) {
  const metaType = getSubModelMeta(ModelClass, propertyName);
  const Submodel = metaType.typeFunction();
  return Submodel;
}
// ########  Creation of Editor Models  #####################################################################################
export function getEditorModel(BaseModelClass, definitionName) {
  return getEditorModelInfo(BaseModelClass, definitionName).modelConstructor;
}
export function getExposedProperties(ModelClass) {
  const allExposed = defaultMetadataStorage.getExposedMetadatas(ModelClass);
  return allExposed.map(meta => meta.propertyName);
}
export function getEditorModelInfo(BaseModelClass, definitionName) {
  assertValidEditorModelRequestData(BaseModelClass, definitionName);
  const childModelName = getDerivedModelName(BaseModelClass, definitionName);
  const cache = customMetadataStorage.findMetadataOfType(EDITOR_MODELS_CACHE_KEY, BaseModelClass);
  const match = cache.find(meta => meta.options.name === childModelName);
  if (match) return match.options;
  // no cache available, build the editor model
  const {
    definition,
    ChildModelClass,
    nestedDefinitions,
    readonlyProps,
    nestedModelProps,
    editModeDependentProps
  } = getEditorModelNoCache(BaseModelClass, definitionName);
  const data = {
    name: childModelName,
    modelConstructor: ChildModelClass,
    definition,
    nestedDefinitions,
    readonlyProps,
    nestedModelProps,
    editModeDependentProps
  };
  // add to cache
  customMetadataStorage.addMetadata({
    metaType: EDITOR_MODELS_CACHE_KEY,
    target: BaseModelClass,
    propertyName: null,
    options: data
  });
  return data;
}
export function getEditorModelNoCache(BaseModelClass, definitionName) {
  assertValidEditorModelRequestData(BaseModelClass, definitionName);
  const childModelName = getDerivedModelName(BaseModelClass, definitionName);
  const definition = getEditorDefinition(BaseModelClass, definitionName);
  const ChildModelClass = createChildModel(BaseModelClass, childModelName);
  const nestedDefinitions = collectNestedModels(definition);
  const nestedEditorModels = createNestedEditorModels(BaseModelClass, nestedDefinitions);
  generateMetadataForChildModel(BaseModelClass, ChildModelClass, definition, nestedEditorModels);
  customMetadataStorage.addMetadata({
    metaType: EDITOR_MODEL_DEFINITION_NAME_KEY,
    target: ChildModelClass,
    options: {
      name: definitionName
    }
  });
  const analyzation = analyzeEditorDefinition_inner(ChildModelClass, definition);
  return {
    definition,
    ChildModelClass,
    nestedDefinitions,
    nestedEditorModels,
    ...analyzation
  };
}
function assertValidEditorModelRequestData(BaseModelClass, definitionName) {
  if (!BaseModelClass?.name) {
    throw new Error('Invalid EditorModel requested: passed BaseModelClass is no class or has no name.');
  }
  if (!definitionName || typeof definitionName !== 'string') {
    throw new Error('Invalid EditorModel requested: definitionName ' + definitionName + ' is not a valid name.');
  }
}
// ########  Tools working with created EditorModels  #####################################################################################
// helper to distinguish between main Model and derived editor models
export function isEditorModel(EditorModelClass) {
  const classDefinitionNameMeta = customMetadataStorage.findMetadataOfType(EDITOR_MODEL_DEFINITION_NAME_KEY, EditorModelClass);
  return classDefinitionNameMeta.length > 0;
}
export function getEditorModelDefinitionName(EditorModelClass) {
  const classDefinitionNameMeta = customMetadataStorage.findMetadataOfType(EDITOR_MODEL_DEFINITION_NAME_KEY, EditorModelClass);
  if (!classDefinitionNameMeta.length) throw Error('given Constructor is no EditorModel! ' + EditorModelClass);
  return classDefinitionNameMeta[0].options.name;
}
export function getBaseModelFromEditorModel(EditorModelClass, skipCheck = false) {
  if (!skipCheck && !isEditorModel(EditorModelClass)) return EditorModelClass;
  // not EditorModelClass.prototype.constructor! prototype points to parent, but constructor is a circular reference to self!
  return Object.getPrototypeOf(EditorModelClass);
}
function collectNestedModels(def) {
  const nestedEditorInfo = new Map();
  if (Array.isArray(def.data)) return nestedEditorInfo;
  for (const key in def.data) {
    if (typeof def.data[key] !== 'object') continue;
    const propConfig = def.data[key];
    if (propConfig.loadDefinition) nestedEditorInfo.set(key, propConfig.loadDefinition);
  }
  return nestedEditorInfo;
}
function createNestedEditorModels(ParentModelClass, nestedDefinitions) {
  const nestedModels = new Map();
  if (nestedDefinitions.size === 0) return nestedModels;
  nestedDefinitions.forEach((defName, propName) => {
    const NestedParentModel = loadSubModel(ParentModelClass, propName);
    nestedModels.set(propName, getEditorModel(NestedParentModel, defName));
  });
  return nestedModels;
}
function getDerivedModelName(BaseModelClass, definitionName) {
  let name = BaseModelClass.name;
  name += '__' + createValidClassName(definitionName);
  // if(this.editorDefinitionIncludesModes) name+=''
  return name;
}
export function generateMetadataForChildModel(BaseClass, ChildClass, definition, nestedEditorModels) {
  const metadataStorage = ClassTransformService.getMetadataStorage();
  const baseClassExposedMetadata = metadataStorage.getExposedMetadatas(BaseClass);
  // if allowAllExposed, all exposed props will stay exposed.
  // otherwise, expose/exclude metadata must be re-built for child model.
  const createCustomExposeMap = definition.options?.allowAllExposed !== true;
  if (createCustomExposeMap) {
    const addExposed = [];
    const addExcluded = [];
    const addExposedByEditGroups = [];
    if (Array.isArray(definition.data)) {
      // definition.data is an array of property names.
      // all listed properties shall be exposed, others are to be excluded.
      for (const meta of baseClassExposedMetadata) {
        if (definition.data.includes(meta.propertyName)) {
          // property found inside EditorDefinition, add to exposed
          addExposed.push(meta.propertyName);
          continue;
        }
        // check if property is in whitelist.
        if (!definition.options.disableWhitelist && editorDefinitionWhitelistedProps.includes(meta.propertyName)) {
          addExposed.push(meta.propertyName);
          continue;
        }
        addExcluded.push(meta.propertyName);
      }
    } else {
      // definition.data is an object map containing further configuration for each property.
      for (const meta of baseClassExposedMetadata) {
        const info = definition.data[meta.propertyName];
        if (info === false) {
          // explicitly exclude current property
          addExcluded.push(meta.propertyName);
          continue;
        }
        if (info === true || info === 'readonly') {
          // explicitly expose current property
          addExposed.push(meta.propertyName);
          continue;
        }
        // check if property is in whitelist.
        if (!definition.options.disableWhitelist && editorDefinitionWhitelistedProps.includes(meta.propertyName)) {
          addExposed.push(meta.propertyName);
          continue;
        }
        // property is not within EditorDefinition. exclude it.
        if (typeof info === 'undefined') {
          addExcluded.push(meta.propertyName);
          continue;
        }
        // last possibility: property has a configuration object
        const propConfig = info;
        if (propConfig.loadDefinition) {
          addExposed.push(meta.propertyName);
        } else if (propConfig.editModes) {
          addExposedByEditGroups.push({
            name: meta.propertyName,
            groups: propConfig.editModes
          });
        }
      }
    }
    // no need to process addExposed.
    // all properties of the base Model have @Expose() decorator already.
    // apply excluded properties.
    for (const propertyName of addExcluded) {
      metadataStorage.addExcludeMetadata({
        target: ChildClass,
        propertyName,
        options: {}
      });
    }
    // if exposal should be applied by editGroup, a new exposeMetadata must be added.
    // the default, parent class property expose metadata will be ignored then.
    for (const info of addExposedByEditGroups) {
      // dont add exclude meta. It would always take priority over expose meta!
      // metadataStorage.addExcludeMetadata({})
      const groups = [];
      Object.keys(info.groups).map(editMode => {
        if (info.groups[editMode] !== false) groups.push(editMode);
      });
      metadataStorage.addExposeMetadata({
        target: ChildClass,
        propertyName: info.name,
        options: {
          groups
        }
      });
    }
  }
  // const newExposed = metadataStorage.getExposedMetadatas(childClass) as ExposeMetadata[];
  // const newExcluded = metadataStorage.getExcludedMetadatas(childClass) as ExposeMetadata[];
  // we need to manipulate prop type metadata of the EditorModel because 
  // transformer / dfb must not use metadata of the standard nested model but instead use nested EditorModel.
  // define customized TypeMetadata inheriting reflectedType, but specifying the nested EditorModel as type.
  if (nestedEditorModels.size) {
    nestedEditorModels.forEach((NestedModelClass, propertyName) => {
      const baseMeta = metadataStorage.findTypeMetadata(BaseClass, propertyName);
      // console.log('>>>>>>',baseMeta)
      metadataStorage.addTypeMetadata({
        // TODO: reflectedType info for array identification?
        target: ChildClass,
        reflectedType: baseMeta.reflectedType,
        propertyName,
        typeFunction: () => NestedModelClass,
        options: {} // missing options object can make ngxDFB error
      });
    });
  }
}
// ########  Scanning Definition for features  #####################################################################################
export function analyzeEditorDefinition(ModelClass, definitionName) {
  let defMeta;
  if (definitionName) {
    // assume ModelClass is a parent
    defMeta = getEditorDefinition(ModelClass, definitionName);
  } else {
    // assume ModelClass is a derived EditorModel
    const editorDefNameMeta = customMetadataStorage.findMetadataOfType(EDITOR_MODEL_DEFINITION_NAME_KEY, ModelClass);
    if (!editorDefNameMeta.length) throw new Error('Seems like the passed ModelClass is no derived EditorModel! If using a normal model, specify the definitionName parameter!');
    definitionName = editorDefNameMeta[editorDefNameMeta.length - 1].options.name;
    defMeta = getEditorDefinition(ModelClass.prototype.constructor, definitionName); // TODO: correct?
  }
  return analyzeEditorDefinition_inner(ModelClass, defMeta);
}
function analyzeEditorDefinition_inner(ModelClass, definition) {
  const info = {
    readonlyProps: [],
    nestedModelProps: [],
    editModeDependentProps: []
  };
  function scanDefinition(ModelClass2, def, basePath = '') {
    const whitelistedReadonlyProps = [];
    if (!definition.options.disableWhitelist) {
      const allExposed = ClassTransformService.getMetadataStorage().getExposedMetadatas(ModelClass2);
      editorDefinitionWhitelistedProps.forEach(name => {
        const hasExposeMeta = allExposed.find(entry => entry.propertyName === name);
        if (hasExposeMeta) {
          whitelistedReadonlyProps.push(name);
        }
      });
    }
    // if data is array, there cannot be anything special in it
    if (!Array.isArray(def)) {
      for (const prop in def) {
        if (def[prop] === false) continue;
        if (def[prop] === true) {
          // if a whitelisted-as-readonly prop is explicitly set to be editable, correct this now
          const index = whitelistedReadonlyProps.indexOf(prop);
          if (index > -1) whitelistedReadonlyProps.splice(index, 1);
          continue;
        }
        if (def[prop] === 'readonly') {
          info.readonlyProps.push(basePath + prop);
        } else {
          const propConfig = def[prop];
          if (propConfig.editModes) {
            info.editModeDependentProps.push(basePath + prop);
            // editModes info may include immutable status too
            for (const mode in propConfig.editModes) {
              if (propConfig.editModes[mode] === 'readonly') {
                info.readonlyProps.push(basePath + prop);
                break;
              }
            }
          }
          if (propConfig.loadDefinition) {
            // load and analyze nested model
            info.nestedModelProps.push(basePath + prop);
            const SubModelClass = loadSubModel(ModelClass2, prop);
            const subModelDef = getEditorDefinition(SubModelClass, propConfig.loadDefinition);
            scanDefinition(SubModelClass, subModelDef.data, basePath + prop + '.');
          }
        }
      }
    }
    info.readonlyProps.push(...whitelistedReadonlyProps);
  }
  scanDefinition(ModelClass, definition.data);
  return info;
}