import { customMetadataStorage } from '../model';
// not exported as considered private. Use provided functions to get metadata.
const VIRTUAL_PROPERTIES_MODEL_TOKEN = '__VIRTUAL_PROPERTIES_MODEL_TOKEN__';
export function VirtualPropertiesModel(config) {
  return target => {
    // decorator only sets metadata.
    // type assignment is handled by VirtualPropertiesRegistry as soon as FeatureModules are being constructed.
    customMetadataStorage.addMetadata({
      metaType: VIRTUAL_PROPERTIES_MODEL_TOKEN,
      target: target,
      // propertyName,
      options: config
    });
  };
}
export function getModelsWithVirtualProperties() {
  return customMetadataStorage.findModelsByMetadataType(VIRTUAL_PROPERTIES_MODEL_TOKEN);
}
export function getModelsWithVirtualProperties$() {
  return customMetadataStorage.findModelsByMetadataType$(VIRTUAL_PROPERTIES_MODEL_TOKEN);
}