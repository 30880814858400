import { ValidateBy, buildMessage } from 'class-validator';
const IS_MATCHING = "IS_MATCHING";
export function isMatching(value, args) {
  const [relatedPropertyName] = args.constraints;
  const relatedValue = args.object[relatedPropertyName];
  return typeof value === "string" && typeof relatedValue === "string" && value === relatedValue;
}
export function IsMatching(property, validationOptions) {
  return ValidateBy({
    name: IS_MATCHING,
    constraints: [property],
    validator: {
      validate: (value, args) => isMatching(value, args),
      defaultMessage: buildMessage(eachPrefix => eachPrefix + "$property must be equal to $constraint1", validationOptions)
    }
  }, {
    message: args => {
      return `"${args.property}" und "${args.constraints[0]}" müssen identisch sein!`;
    },
    ...validationOptions
  });
}