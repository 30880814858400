export var InstructionHelper;
(function (InstructionHelper) {
  function prepareInstructionsList(list) {
    if (!list || !list.length) return [];
    // remove falsy Instructions (which are allowed for simplicity of using ternary operators when building isntruction list)
    const withoutFalsy = list.filter(entry => !!entry);
    // apply priority ordering
    withoutFalsy.sort((a, b) => {
      const aPrio = a.config.priority || 0;
      const bPrio = b.config.priority || 0;
      return bPrio - aPrio;
    });
    // not checking for any nested instructions here. 
    // it is expected to call this function again for child lists.
    return withoutFalsy;
  }
  InstructionHelper.prepareInstructionsList = prepareInstructionsList;
})(InstructionHelper || (InstructionHelper = {}));