import { createNgModule, EnvironmentInjector, reflectComponentType } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicComponentIOCheck } from './interfaces';
import { isEqualDeep } from '@core/shared/utils';
;
export class DynamicComponentHelper {
  /*******************************************************************************
   * Input/Output validation
   * helps to prevent bugs because of unknown Input/Output names
   * by checking if the specified keys are real @Input / @Output properties.
   *******************************************************************************/
  static validateInputs(componentInputs, customInputs, component) {
    // eslint-disable-next-line guard-for-in
    for (const key in customInputs) {
      const found = componentInputs.some(componentInput => componentInput.templateName === key);
      if (!found) throw new Error(`replaceableComponentDirective: Input ${key} is not known to component ${component.name}. 
				Check spelling and that the prop has @Input decorator.`);
    }
  }
  static validateOutputs(componentOutputs, customOutputs, component) {
    // componentOutputs.forEach((output) => {
    // 	if (!(componentInstance[output.propName] instanceof EventEmitter)) {
    // 		throw new Error(`Output ${ output.propName } must be a typeof EventEmitter`);
    // 	}
    // });
    // eslint-disable-next-line guard-for-in
    for (const key in customOutputs) {
      const found = componentOutputs.some(output => output.templateName === key);
      if (!found) throw new Error(`replaceableComponentDirective: Output ${key} is not known to component ${component.name}. 
				Check spelling and that the prop has @Output decorator.`);
      if (!(customOutputs[key] instanceof Function)) throw new Error(`replaceableComponentDirective: Output ${key} must be a function`);
    }
  }
  static validateCustomComponentBindings(componentInfo, config) {
    /**
     * Note: this does work fine in production too!
     * If an error occurs about missing I/Os, likely something is wrong with the component.
     * Note that type reflection will not work with @Injectable parent classes.
     * All parent classes specifying I/Os must be marked as @Directive or @Component!
     */
    const meta = reflectComponentType(componentInfo.component);
    DynamicComponentHelper.validateInputs(meta.inputs, config.inputs, componentInfo.component);
    DynamicComponentHelper.validateOutputs(meta.outputs, config.outputs, componentInfo.component);
  }
  static findValidIOs(componentInputs, customInputs) {
    const valid = {};
    // eslint-disable-next-line guard-for-in
    for (const key in customInputs) {
      const found = componentInputs.some(componentInput => componentInput.templateName === key);
      if (found) valid[key] = customInputs[key];
    }
    return valid;
  }
  static filterValidCustomComponentBindings(componentInfo, config) {
    const meta = reflectComponentType(componentInfo.component);
    return {
      ...config,
      inputs: DynamicComponentHelper.findValidIOs(meta.inputs, config.inputs),
      outputs: DynamicComponentHelper.findValidIOs(meta.outputs, config.outputs)
    };
  }
  static getComponentInfoFromInput(config) {
    return typeof config.component === 'function' ? {
      component: config.component,
      module: null
    } : config.component;
  }
  static makeCustomComponent(config, injector, vcr, prevComponentRef) {
    vcr.clear();
    prevComponentRef?.destroy();
    const IOCheck = config.IOCheck || DynamicComponentIOCheck.Validate;
    const componentInfo = DynamicComponentHelper.getComponentInfoFromInput(config);
    if (IOCheck === DynamicComponentIOCheck.Validate) {
      DynamicComponentHelper.validateCustomComponentBindings(componentInfo, config);
    } else if (IOCheck === DynamicComponentIOCheck.FilterUnknown) {
      config = DynamicComponentHelper.filterValidCustomComponentBindings(componentInfo, config);
    }
    const componentRef = DynamicComponentHelper.createCustomComponent(componentInfo, injector, vcr);
    const destroyBindings = DynamicComponentHelper.bindCustomComponent(componentRef, config);
    return {
      componentRef,
      destroyBindings
    };
  }
  static createCustomComponent(componentInfo, injector, vcr) {
    const Component = componentInfo.component;
    // could use custom injector to provide extras, currently not requireds
    // const injector = Injector.create({
    // 	providers: [
    // 		// { provide: 'REPLACEABLE_DATA', useValue: this.providedData }
    // 	],
    // 	parent: this.injector,
    // });
    // create a new NgModuleRef from componentInfo as child of this component's injector (=injector of parent module)
    const ngModuleRef = componentInfo.module ? createNgModule(componentInfo.module, injector) : undefined;
    // only ngModuleRef or environmentInjector must be defined!
    const environmentInjector = ngModuleRef ? undefined : injector.get(EnvironmentInjector);
    const componentRef = vcr.createComponent(Component, {
      ngModuleRef,
      injector,
      environmentInjector
    });
    return componentRef;
  }
  static bindCustomComponent(componentRef, config, destroyBindingEmitter) {
    destroyBindingEmitter = DynamicComponentHelper.createComponentOutputSubscribers(componentRef, config.outputs, destroyBindingEmitter);
    DynamicComponentHelper.updateComponentInputValues(componentRef, this.getComponentInfoFromInput(config), config.inputs);
    // atm needed to trigger onInit/afterViewInit on standalones correctly
    componentRef.changeDetectorRef.markForCheck();
    return destroyBindingEmitter;
  }
  /*********************************
   * Manage Input/Output bindings
   *********************************/
  // protected createProvidedDataAccessors() {
  // 	this.providedData = { outputs: {}, ...this.config, inputs: {} };
  // 	if (!this.config.inputs) return;
  // 	const accessors = {} as any;
  // 	// eslint-disable-next-line guard-for-in
  // 	for(const key in this.config.inputs) {
  // 		accessors[key] = {
  // 			enumerable: true,
  // 			configurable: true,
  // 			get: () => this.config.inputs[key]?.value
  // 		};
  // 		// if(this.config.inputs[key]?.twoWay) {
  // 		// 	accessors[key].set = (newValue: any) => {
  // 		// 		this.config.inputs[key].value = newValue;
  // 		// 		this.config.outputs[`${key}Change`](newValue);
  // 		// 	};
  // 		// }
  // 	};
  // 	Object.defineProperties(this.providedData.inputs, accessors);
  // }
  static updateComponentInputValues(ref, componentInfo, inputs, options = {}) {
    if (!ref || !inputs) return;
    const component = ref.instance;
    // WARNING - this does not support directive bindings! Will only let COMPONENT bindings pass!
    // TODO: should be cached. may be slowing down to execute reflection on every input binding change.
    if (options.stripUnknownInputs) {
      const meta = reflectComponentType(componentInfo.component);
      inputs = DynamicComponentHelper.findValidIOs(meta.inputs, inputs);
    }
    for (const inputName in inputs) {
      if (!isEqualDeep(component[inputName], inputs[inputName])) {
        ref.setInput(inputName, inputs[inputName]);
      }
    }
  }
  static updateComponentAttributes(ref, inputs) {
    const rootEl = ref.location.nativeElement;
    if (!rootEl || !inputs) return;
    for (const inputName in inputs) {
      if (Object.hasOwnProperty.call(inputs, inputName)) {
        if (inputName === 'class') {
          const classString = inputs[inputName];
          rootEl.classList.add(...classString.split(" "));
        } else {
          rootEl.setAttribute(inputName, inputs[inputName]);
        }
      }
    }
  }
  // TODO: implementation once had a outputBindingSubscriptions map for preventing duplicate subscriptions. Need to reintroduce?
  static createComponentOutputSubscribers(ref, outputs, destroyBindingEmitter) {
    if (!ref || !outputs) return;
    const component = ref.instance;
    if (!destroyBindingEmitter) destroyBindingEmitter = new Subject();
    if (outputs) {
      // eslint-disable-next-line guard-for-in
      for (const key in outputs) {
        if (!(component[key] instanceof Observable)) throw new Error('createComponentOutputSubscribers: Tried to bind to output "' + key + '" which is not a valid output emitter!');
        component[key].pipe(takeUntil(destroyBindingEmitter)).subscribe(value => {
          outputs[key](value);
        });
      }
    }
    return destroyBindingEmitter;
  }
}