import { CrudRequestType, extractDataFromApiResponse } from '@core/shared/request';
import { getItemModelText } from '@core/shared/model';
import { concat, throwError } from 'rxjs';
import { map, share, take, tap, toArray } from 'rxjs/operators';
import { BaseCrudService } from './base-crud.service';
// not injected, use CrudServiceFactory to create!
export class CrudService extends BaseCrudService {
  constructor(http, modelFactoryProvider, clientCache, state) {
    super(modelFactoryProvider, clientCache, state);
    this.http = http;
    this.modelFactoryProvider = modelFactoryProvider;
    this.clientCache = clientCache;
    this.state = state;
  }
  readMany(opts = {}) {
    return this.readManyWithRangeInfo(opts).pipe(map(data => data.items));
  }
  readManyWithRangeInfo(opts = {}) {
    let qb = this.query;
    if (opts.range) {
      qb = qb.clone();
      if (typeof opts.range.limit === 'number') qb.setLimit(opts.range.limit);
      if (typeof opts.range.offset === 'number') qb.setOffset(opts.range.offset);
      if (typeof opts.range.page === 'number') qb.setPage(opts.range.page);
    }
    const queryParams = qb.buildFilterQuery();
    const responseContainsRangeInfo = typeof qb.queryObject.page === 'number' || typeof qb.queryObject.limit === 'number';
    const path = this.generateApiPath() + (queryParams ? '?' + queryParams : '');
    const headers = this.getHeaders(CrudRequestType.ReadMany, opts);
    const req = this.http.get(path, {
      headers
    });
    const mappedReq = req.pipe(map(r => {
      let items;
      let rangeInfo = null;
      const requestData = extractDataFromApiResponse(r, 'CrudService::ReadMany ' + this.ItemFactory.Model.name);
      if (!responseContainsRangeInfo || Array.isArray(requestData)) {
        items = requestData;
      } else {
        items = requestData.data;
        rangeInfo = {
          count: requestData.count,
          total: requestData.total,
          page: requestData.page,
          pageCount: requestData.pageCount
        };
      }
      items = items?.length ? items.map(data => this.ItemFactory.fromData(data)) : [];
      return {
        items,
        rangeInfo
      };
    }), take(1));
    return this.bindRequest(mappedReq, false, CrudRequestType.ReadMany);
  }
  readOne(id) {
    this.assertInitialized();
    // simple solution to allow working with URL aliases like 12-item or 52:article
    const queryParams = this.query.buildItemQuery();
    if (typeof id === 'string') id = parseInt(id, 10);
    let path = this.generateApiPath({
      id
    }, ':id');
    if (queryParams) path += '?' + queryParams;
    const headers = this.getHeaders(CrudRequestType.ReadOne);
    const req = this.http.get(path, {
      headers
    });
    const mappedReq = req.pipe(share(), map(response => {
      return this.ItemFactory.fromData(extractDataFromApiResponse(response, 'ItemService::readOne ' + this.ItemFactory.Model.name));
    }));
    return this.bindRequest(mappedReq, false, CrudRequestType.ReadOne, {
      ids: [id]
    });
  }
  readSome(ids, opts = {}) {
    this.assertInitialized();
    const query = this.query.clone();
    query.reset();
    query.setFilter({
      field: 'id',
      operator: '$in',
      value: ids
    });
    const queryParams = query.buildItemQuery();
    const path = this.generateApiPath() + (queryParams ? '?' + queryParams : '');
    const headers = this.getHeaders(CrudRequestType.ReadMany);
    const req = this.http.get(path, {
      headers
    });
    const mappedReq = req.pipe(share(), map(response => {
      return this.ItemFactory.fromData(extractDataFromApiResponse(response, 'ItemService::readSome ' + this.ItemFactory.Model.name));
    }));
    return this.bindRequest(mappedReq, true, CrudRequestType.ReadMany, {
      ids
    });
  }
  save(item) {
    return typeof item.id !== 'undefined' && item.id > 0 ? this.update(item) : this.create(item);
  }
  create(item, hot = true) {
    this.assertInitialized();
    item = this.processItemData(item);
    if (typeof item.id !== 'undefined') return throwError(() => new Error('Item already has an ID, it cannot be created!'));
    const path = this.generateApiPath(item);
    const headers = this.getHeaders(CrudRequestType.CreateOne);
    let req = this.http.post(path, item, {
      headers
    });
    req = req.pipe(tap(() => this.clearItemClientCache()), share(), map(response => {
      return extractDataFromApiResponse(response, 'CrudService::create ' + this.ItemFactory.Model.name);
    }));
    return this.bindRequest(req, hot, CrudRequestType.CreateOne);
  }
  createMany(items, hot = true) {
    this.assertInitialized();
    items = items.map(item => this.processItemData(item));
    // TODO: Cannot use bulk endpoint due to https://github.com/nestjsx/crud/issues/597
    // const req = this.http.post(
    // 	this.generateApiPath(items[0],'bulk'),
    // 	{bulk:items},
    // 	{ headers:this.getHeaders(CrudRequestType.CreateMany) }
    // ).pipe(share())
    //     .pipe(map((response:IApiResponse<any>):any => {
    //     	return extractDataFromApiResponse(response);
    // 	}))
    // As temporary solution, send one by one.
    const reqs = items.map(item => {
      const path = this.generateApiPath(item);
      const headers = this.getHeaders(CrudRequestType.CreateOne);
      const r = this.http.post(path, item, {
        headers
      });
      return r.pipe(
      //  concat requires finished observables so use take(1)!
      take(1),
      // .pipe(share())
      map(response => {
        return extractDataFromApiResponse(response, 'CrudService::createMany ' + this.ItemFactory.Model.name);
      }));
    });
    let req = concat(...reqs).pipe(tap(() => this.clearItemClientCache()), toArray(), share());
    return this.bindRequest(req, hot, CrudRequestType.CreateMany);
  }
  update(item, hot = true) {
    this.assertInitialized();
    if (!item.id) return throwError(() => new Error(getItemModelText('noId', item)));
    // TODO: This might lead to problems.
    // update does not force a full item as this might lead to overwritten data on partial updates.
    // this however does also mean that no pathParams will be applied then and no validation/stripping happens.
    if (item instanceof this.ItemFactory.Model) item = this.processItemData(item);
    const path = this.generateApiPath(item, ':id');
    const headers = this.getHeaders(CrudRequestType.Update);
    let req = this.http.patch(path, item, {
      headers
    });
    req = req.pipe(tap(() => this.clearItemClientCache()), share(), map(response => {
      return extractDataFromApiResponse(response, 'CrudService::update ' + this.ItemFactory.Model.name);
    }));
    return this.bindRequest(req, hot, CrudRequestType.Update, {
      ids: [item.id]
    });
  }
  updateOrdering(items, hot = true) {
    this.assertInitialized();
    const orderedIds = items.map(item => {
      return typeof item === 'number' ? item : item.id;
    });
    const path = this.generateApiPath(null, 'ordering');
    const headers = this.getHeaders(CrudRequestType.Update);
    let req = this.http.patch(path, orderedIds, {
      headers
    });
    req = req.pipe(tap(() => this.clearItemClientCache()), share(), map(response => {
      return extractDataFromApiResponse(response, 'CrudService::updateOrdering ' + this.ItemFactory.Model.name);
    }));
    return this.bindRequest(req, hot, CrudRequestType.Update, {
      ids: orderedIds
    });
  }
  delete(item, hot = true) {
    this.assertInitialized();
    if (!item.id) return throwError(() => new Error(getItemModelText('noId', item)));
    item = this.processItemData(item);
    const path = this.generateApiPath(item, ':id');
    const headers = this.getHeaders(CrudRequestType.Delete);
    let req = this.http.delete(path, {
      headers
    });
    req = req.pipe(tap(() => this.clearItemClientCache()), share(), map(response => {
      return response.error === false && response.status === 200;
    }));
    return this.bindRequest(req, hot, CrudRequestType.Delete, {
      ids: [item.id]
    });
  }
  createNew(itemToClone) {
    this.assertInitialized();
    if (itemToClone) {
      return this.ItemFactory.clone(itemToClone);
    } else {
      return this.ItemFactory.newInstance();
    }
  }
  toggleState(item, hot = true) {
    this.assertInitialized();
    if (typeof item.enabled !== 'boolean') {
      return throwError(() => new Error("Fehler: Dieses Item hat keinen Aktivierungsstatus."));
    }
    const patch = {
      id: item.id,
      enabled: !item.enabled
    };
    if (this.apiPathParams.length) {
      // if any apiPathParams are required, add these to the patch too to ensure that generateApiPath can work correctly.
      this.apiPathParams.forEach(name => {
        if (typeof item[name] !== 'undefined') {
          patch[name] = item[name];
        }
      });
    }
    return this.update(patch, hot);
  }
}