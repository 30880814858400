import { BaseFeature, FeatureType } from '@core/shared/feature';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
export class ChangelogBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-common-changelog-feature","featureId":"common-changelog"},
      icons: [faHistory],
      abilityActions: false,
      plugins: [{
        forPlatform: 'admin',
        type: FeatureType.Permanent,
        pluginClassName: 'ChangelogFrontendAdminFeature'
      }]
    };
  }
  getSharedDefinition() {
    return {};
  }
}