import { BehaviorSubject } from 'rxjs';
import { DEFAULT_PERMISSIONS } from './constants';
import { getClassName } from '../class-name';
/**
 * TODO: #86c0agb3p - becomes obsolete with fully static roles
 */
export class PermissionsRegistry {
  constructor(registry, config) {
    this.registry = registry;
    this.config = config;
    this.registry$ = new BehaviorSubject(null);
    this.registry$.next(DEFAULT_PERMISSIONS.map(permDef => {
      return {
        ...permDef,
        __origin: 'defaults'
      };
    }));
    const configuratedPermissions = config.get('access.permissions', null);
    if (Array.isArray(configuratedPermissions) && configuratedPermissions.length) {
      this.addPermissions(...configuratedPermissions.map(p => {
        return {
          name: p,
          type: 'role',
          description: 'configurated permission ' + p
        };
      }));
    }
    this.connectToFeatureRegistry();
  }
  addPermissions(...permissions) {
    const merged = [...this.registry$.getValue(), ...permissions];
    this.registry$.next(merged);
  }
  getPermissions() {
    return [...this.registry$.getValue()];
  }
  getPermissions$() {
    return this.registry$.asObservable();
  }
  connectToFeatureRegistry() {
    this.registry.getBaseRegistry().subscribe(baseFeatures => {
      baseFeatures.forEach(baseFeature => {
        const def = baseFeature.getSharedDefinitionCached();
        if (def.permissionDefinitions) {
          const permissions = def.permissionDefinitions.map(permDef => {
            return {
              ...permDef,
              __origin: 'feature:' + getClassName(baseFeature)
            };
          });
          this.addPermissions(...permissions);
        }
      });
    });
  }
}