import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessState } from '@core/frontend-shared';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AutoUnsubscribe, takeWhileAlive } from '@core/frontend-shared/utils';
import { CommonModule } from '@angular/common';
import { UiInputSelectButtonModule } from '@common/ui/frontend-shared/input-selectbutton';
import { MessagesFrontendModule } from '@common/messages/frontend';
import { LayoutFrontpageModule } from '@pflegetheke/ui/frontend-shared/layout-frontpage';
import { UiRequiredModule } from '@pflegetheke/ui/frontend-shared';
import { FeedbackFrontendModule } from '@common/feedback/frontend';
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { UiInputDropdownModule } from '@common/ui/frontend-shared/input-dropdown';
import { TabView, UiTabViewModule } from '@common/ui/frontend-shared/tabview';
import { MembershipFrontendSharedModule } from '@common/membership/frontend-shared';

@Component({
	selector: 'pflegetheke-login',
	styleUrls:['./login.component.scss'],
	template: `
	<pflegetheke-layout-frontpage theme="green" [showBottomButtons]="false">
		<!-- <a class="top-menu-item" routerLink="/privacy" >Datenschutz</a>
		<a class="top-menu-item" routerLink="/imprint" >Impressum</a> -->
		<feedback-button class="top-menu-item"  buttonClass=""></feedback-button>	

		<div class="content-left">
			<!-- <h2>Die <b>einfache Lösung</b><br> für Ihr Team.</h2> -->
		</div>
		<div class="content-right">
			<div>
			<ui-tabView [(activeIndex)]="activeTabIndex" #tabView>
				<ui-tabPanel header="Login" name="login">
					<ng-container *replaceableComponent="{id:'user-login-form',inputs:{resetPasswordEnabled:true}}"><user-login-form></user-login-form></ng-container>

					<p class="ui-margin-large ui-text-center">Noch kein Account vorhanden? <a (click)="onClickRegisterNow()">Jetzt registrieren</a></p>
				</ui-tabPanel>
				<ui-tabPanel header="Registrierung" name="registration">
					<membership-registration-wrapper [inline]="true"></membership-registration-wrapper>
				</ui-tabPanel>
			</ui-tabView>
			</div>
		</div>
		<ui-card size="large">
		</ui-card>
	</pflegetheke-layout-frontpage>
	`,
	standalone:true,
	imports:[
		CommonModule,
		UiInputSelectButtonModule,
		MessagesFrontendModule,
		LayoutFrontpageModule,
		UiRequiredModule,
		LayoutFrontpageModule,
		FeedbackFrontendModule,
		UiInputDropdownModule,
		CoreFrontendSharedModule,
		UiTabViewModule,
		MembershipFrontendSharedModule,
	]
})
@AutoUnsubscribe()
export class AppLoginComponent {

	activeTabIndex = 0;

	@Select(AccessState.loggedIn) loggedIn$:Observable<boolean>;
	@ViewChild('tabView') tabView!: TabView;

	constructor(
		router:Router,
		private route:ActivatedRoute,
		private cd:ChangeDetectorRef,
	) {
		
		// navigate to home if user is already logged in
		this.loggedIn$.pipe(takeWhileAlive(this)).subscribe(loggedIn=>{
			if(loggedIn) router.navigate(['/'])
		})
	}

	ngAfterViewInit() {
		const params = this.route.snapshot.queryParams;
		if(params['tab']) {
			const selectedIndex = this.tabView.findTabIndexByName(params['tab'])	
			if (selectedIndex !== null) this.activeTabIndex = selectedIndex;
			// for some reason, markForCheck is not enough here to fix ExpressionHasBeenChanged error
			this.cd.detectChanges();
		}
	}

	onClickRegisterNow() {
		this.activeTabIndex = 1;
	}

}
