import { buildMessage, matches, ValidateBy } from "class-validator";
const IS_SAFE_PASSWORD = "IS_SAFE_PASSWORD";
export function isSafePassword(value) {
  return matches(value, /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/);
}
export function IsSafePassword(validationOptions) {
  return ValidateBy({
    name: IS_SAFE_PASSWORD,
    constraints: [/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/, ''],
    validator: {
      validate: (value, args) => isSafePassword(value),
      defaultMessage: buildMessage((eachPrefix, args) => 'Ihr Passwort muss mindestens 6 Zeichen - Ziffern, Groß- und Kleinbuchstaben - enthalten', validationOptions)
    }
  }, {
    message: 'Ihr Passwort muss mindestens 6 Zeichen - Ziffern, Groß- und Kleinbuchstaben - enthalten',
    ...validationOptions
  });
}