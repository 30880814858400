export const optionsInputMap = {
  filter: 'filter',
  options: 'options',
  // following commented props will be passed to directives, they must NOT appear in component input assignment list!
  // optionsDynamic:'InputOptionsDynamic',
  // optionsGroupedBy:'InputOptionsGroupedBy',
  // optionValue is special: It must be set on directive as well as on component, so include it in map
  optionValue: 'optionValue'
};
export const optionsInputDefaults = {
  options: null,
  filter: false,
  optionsDynamic: null,
  optionValue: null,
  optionsGroupedBy: null
};