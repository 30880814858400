// a function trying to handle any possible case of error data.
import { HttpErrorResponse } from '@angular/common/http';
import { AppMessage } from '../messages';
// aims to return a simple stringified error message.
export function convertFrontendErrorToString(error) {
  let stringifiedError;
  // note: state cannot contain classes, so we must strip the server error to plain data!
  if (error instanceof AppMessage) {
    error.setHandled(true);
    error = error.getError();
  }
  if (error instanceof HttpErrorResponse) {
    // unwrap the server error from httpErrorResponse
    stringifiedError = 'Fehler ' + error.status + ': ' + error.error.message;
  } else if (typeof error === 'object') {
    stringifiedError = error.message || error.toString();
  } else {
    stringifiedError = '' + error;
  }
  return stringifiedError;
}