import { validateCondition, validateFields, validateJoin, validateNumeric, validateSort } from '@nestjsx/crud-request/lib/request-query.validator';
import { hasValue, isArrayFull, isNil, isObject, isString, isUndefined } from '@nestjsx/util';
import { stringify } from 'qs';
import { CrudQueryBuilderDefaultOptions } from './crud-query-builder.interfaces';
import * as deepmerge from 'deepmerge';
import { CrudSearchQueryBuilder } from './crud-search.builder';
/**
 * CrudQueryBuilder is responsible for building queries that can be understood by our CRUD endpoints.
 * endpoints follow the params standard designed in @nestjsx/crud.
 * The builder is partially based on RequestQueryBuilder found in @nestjsx/crud-request but has undergone many changes+improvements since.
 */
export class CrudQueryBuilder {
  get options() {
    return CrudQueryBuilderDefaultOptions;
  }
  constructor() {
    this.paramNames = {};
    this.queryObject = {};
    Object.keys(this.options.paramNamesMap).forEach(key => {
      const name = this.options.paramNamesMap[key];
      this.paramNames[key] = isString(name) ? name : name[0];
    });
    this.reset();
  }
  setFields(fields) {
    if (fields === null) return this.unsetParam('fields');
    if (isArrayFull(fields)) {
      validateFields(fields);
      this.queryObject[this.paramNames.fields] = fields.join(this.options.delimStr);
    }
    return this;
  }
  setSearch(s) {
    if (s instanceof CrudSearchQueryBuilder) {
      s = s.getSearchQuery();
    }
    if (s === null) {
      return this.unsetParam('search');
    } else if (!isNil(s) && isObject(s)) {
      this.queryObject[this.paramNames.search] = JSON.stringify(s);
    }
    return this;
  }
  setFilter(f, customOperators) {
    if (f === null) return this.unsetParam('filter');
    this.setCondition(f, 'filter', customOperators);
    return this;
  }
  setOr(f, customOperators) {
    if (f === null) return this.unsetParam('or');
    this.setCondition(f, 'or', customOperators);
    return this;
  }
  setCustomParam(name, value) {
    if (name === null) {
      this.customParams = new Map();
      return this;
    } else {
      if (value === null) this.customParams.delete(name);else this.customParams.set(name, value);
      return this;
    }
  }
  setJoin(j) {
    if (j === null) return this.unsetParam('join');
    if (!isNil(j)) {
      const param = this.checkQueryObjectParam('join', []);
      this.queryObject[param] = [...this.queryObject[param], ...(Array.isArray(j) && !isString(j[0]) ? j.map(o => this.addJoin(o)) : [this.addJoin(j)])];
    }
    return this;
  }
  setSortBy(s) {
    if (s === null) return this.unsetParam('sort');
    if (!isNil(s)) {
      const param = this.checkQueryObjectParam('sort', []);
      this.queryObject[param] = [...this.queryObject[param], ...(Array.isArray(s) && !isString(s[0]) ? s.map(o => this.addSortBy(o)) : [this.addSortBy(s)])];
    }
    return this;
  }
  setCache(value) {
    const converted = typeof value === 'boolean' ? value ? 1 : 0 : null;
    this.setNumeric(converted, 'cache');
    return this;
  }
  setLimit(n) {
    this.setNumeric(n, 'limit');
    return this;
  }
  setOffset(n) {
    this.setNumeric(n, 'offset');
    return this;
  }
  setPage(n) {
    this.setNumeric(n, 'page');
    return this;
  }
  setIncludeDeleted(n) {
    this.setNumeric(n ? 1 : 0, 'includeDeleted');
    return this;
  }
  // create full query, use with READ endpoint
  buildFilterQuery(encode = true) {
    // if search is active, filter + or must not be active!
    if (this.queryObject[this.paramNames.search]) {
      this.queryObject[this.paramNames.filter] = undefined;
      this.queryObject[this.paramNames.or] = undefined;
    }
    const queryData = this.createQueryObjectWithCustomParams(this.queryObject);
    return stringify(queryData, {
      encode,
      encodeValuesOnly: true
    });
  }
  // use when fetching a single item.
  // must use READONE endpoint e.g. /users/<id>
  buildItemQuery(encode = true) {
    // create queryData containing only join, fields and customParams
    const queryData = this.createQueryObjectWithCustomParams({
      join: this.queryObject[this.paramNames.join],
      fields: this.queryObject[this.paramNames.fields]
    });
    return stringify(queryData, {
      encode,
      encodeValuesOnly: true
    });
  }
  reset() {
    this.queryObject = {};
    this.customParams = new Map();
  }
  clone() {
    const clone = new CrudQueryBuilder();
    clone.queryObject = deepmerge({}, this.queryObject);
    this.customParams.forEach((value, key) => {
      clone.setCustomParam(key, value);
    });
    return clone;
  }
  getFilters() {
    return this.queryObject[this.paramNames['filters']];
  }
  getOr() {
    return this.queryObject[this.paramNames['or']];
  }
  getRange() {
    return {
      limit: this.queryObject[this.paramNames['limit']] || null,
      offset: this.queryObject[this.paramNames['offset']] || null,
      page: this.queryObject[this.paramNames['page']] || null
    };
  }
  unsetParam(name) {
    delete this.queryObject[this.paramNames[name]];
    return this;
  }
  setCondition(f, cond, customOperators) {
    if (!isNil(f)) {
      const param = this.checkQueryObjectParam(cond, []);
      const queryFilterArray = Array.isArray(f) && !isString(f[0]) ? f : [f];
      this.queryObject[param] = [...this.queryObject[param], ...queryFilterArray.map(o => this.buildCondition(o, cond, customOperators))];
    }
  }
  setNumeric(n, cond) {
    if (!isNil(n)) {
      validateNumeric(n, cond);
      this.queryObject[this.paramNames[cond]] = n;
    } else {
      this.unsetParam(cond);
    }
  }
  buildCondition(f, cond = 'search', customOperators) {
    const filter = Array.isArray(f) ? {
      field: f[0],
      operator: f[1],
      value: f[2]
    } : f;
    validateCondition(filter, cond, customOperators);
    const delim = this.options.delim;
    return filter.field + delim + filter.operator + (hasValue(filter.value) ? delim + filter.value : '');
  }
  addJoin(j) {
    const join = Array.isArray(j) ? {
      field: j[0],
      select: j[1]
    } : j;
    validateJoin(join);
    const d = this.options.delim;
    const ds = this.options.delimStr;
    return join.field + (isArrayFull(join.select) ? d + join.select.join(ds) : '');
  }
  addSortBy(s) {
    const sort = Array.isArray(s) ? {
      field: s[0],
      order: s[1]
    } : s;
    validateSort(sort);
    const ds = this.options.delimStr;
    return sort.field + ds + sort.order;
  }
  checkQueryObjectParam(cond, defaults) {
    const param = this.paramNames[cond];
    if (isNil(this.queryObject[param]) && !isUndefined(defaults)) {
      this.queryObject[param] = defaults;
    }
    return param;
  }
  createQueryObjectWithCustomParams(queryObject) {
    const map = deepmerge({}, queryObject);
    this.customParams.forEach((value, name) => {
      map[name] = value;
    });
    return map;
  }
  // for testing purposes only!
  getExtraParams() {
    return this.customParams;
  }
  getParamNames() {
    return this.paramNames;
  }
}