import { Subject, takeUntil, timer } from 'rxjs';
export class StopWatchObservable {
  constructor(updateInterval) {
    this.updateInterval = updateInterval;
    this.timeElapsed = 0;
    this.update = new Subject();
    this.clearSubscription = new Subject();
  }
  start() {
    timer(this.updateInterval, this.updateInterval).pipe(takeUntil(this.clearSubscription)).subscribe(() => {
      this.timeElapsed += this.updateInterval;
      this.update.next(this.timeElapsed);
    });
  }
  pause() {
    // pausing just works identically to destroy
    this.destroy();
  }
  reset() {
    this.timeElapsed = 0;
    this.start();
  }
  destroy() {
    this.clearSubscription.next();
  }
}