import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { loadScript } from "@paypal/paypal-js";
let ppCache;
// note: currently only supports a single paypalClientId to be used in parallel. 
// custom cache to prevent ppJs to be loaded multiple times, which would throw an error.
export function loadPaypalJs(_x) {
  return _loadPaypalJs.apply(this, arguments);
}
function _loadPaypalJs() {
  _loadPaypalJs = _asyncToGenerator(function* (paypalClientId) {
    if (ppCache) return ppCache;
    ppCache = yield loadScript({
      "client-id": paypalClientId,
      "currency": "EUR",
      "locale": "de_DE"
    });
    return ppCache;
  });
  return _loadPaypalJs.apply(this, arguments);
}