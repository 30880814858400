const partialIsResourceType = partial => {
  return ['image', 'video', 'raw'].indexOf(partial) > -1;
};
const partialIsDeliveryType = partial => {
  return ['upload', 'private', 'authenticated', 'fetch', 'facebook', 'twitter', 'twitter_name', 'instagram', 'gravatar', 'youtube', 'hulu', 'vimeo', 'animoto', 'worldstarhiphop', 'dailymotion', 'multi', 'text'].indexOf(partial) > -1;
};
const partialIsTransformation = partial => {
  return ['w_', 'h_', 'c_', 'g_', 'z_', 'x_', 'y_', 'q_', 'r_', 'a_', 'e_', 'o_', 'b_', 't_'].indexOf(partial.substring(0, 2)) > -1 || ['bo_', 'ar_'].indexOf(partial.substring(0, 3)) > -1 || ['dpr_'].indexOf(partial.substring(0, 4)) > -1;
};
const partialIsVersion = partial => {
  return /^v[\d]+$/gm.test(partial);
};
/**
 * https://res.cloudinary.com/<cloud_name>/<resourceType>/<deliveryType>/<transformations>/<version>/<publicId>.<format>
 * cloud_name required
 * resourceType optional
 * deliveryType optional
 * transformations optional
 * version optional
 * publicId required
 * format optional
 */
export class CloudinaryUrlParser {
  constructor(config) {
    this.config = config;
    this.allowedHostNames = config.get('cloudinary.hostNames', '').split(',');
  }
  parse(url, decode = true) {
    // try {
    if (!(url instanceof URL)) {
      try {
        url = new URL(url);
      } catch (err) {
        throw new Error('Es wurde keine gültige URL erkannt. Eingabe: "' + url + '"');
      }
    }
    if (!this.allowedHostNames.includes(url.hostname)) {
      if (!IS_PRODUCTION) console.warn('Configurate valid cloudinary domains at key cloudinary.hostNames.');
      throw new Error('Die angegebene URL nutzt eine ungültige Domain.');
    }
    // cloudinary resource URLs can be vastly different. the only thing we can be sure of is it contains at least four segments.
    const explodedPath = url.pathname.split('/');
    if (explodedPath.length < 4) throw new Error('Die angegebene URL ist keine gültige Cloudinary-Resourcen-URL.'); // invalid URL
    explodedPath.shift();
    let partial;
    const data = {
      cloudName: explodedPath.shift()
    };
    partial = explodedPath.shift();
    if (partialIsResourceType(partial)) {
      data.resourceType = partial;
      partial = explodedPath.shift();
    }
    if (partialIsDeliveryType(partial)) {
      data.deliveryType = partial;
      partial = explodedPath.shift();
    }
    if (partialIsTransformation(partial)) {
      data.transformations = partial;
      partial = explodedPath.shift();
    }
    if (partialIsVersion(partial)) {
      data.version = partial;
      partial = explodedPath.shift();
    }
    explodedPath.unshift(partial);
    let publicId = explodedPath.join("/");
    // remove file extension from format
    const dotIndex = publicId.lastIndexOf('.');
    if (dotIndex > publicId.lastIndexOf('/')) {
      data.format = publicId.substring(dotIndex + 1);
      publicId = publicId.substring(0, dotIndex);
    }
    data.publicId = publicId;
    // re-adding file extension to publicId is required for files of type raw
    if (data.resourceType === 'raw') {
      data.publicId += '.' + data.format;
    }
    if (decode) {
      try {
        data.cloudName = decodeURI(data.cloudName);
        data.publicId = decodeURI(data.publicId);
      } catch (err) {
        console.error('CloudinaryUrlParser: failed to URI-decode cloudName and/or publicId.', data.cloudName, data.publicId);
      }
    }
    return data;
    // } catch(err) {
    // 	console.error(err)
    // 	return false;
    // }
  }
}