import { extendsConstructor } from '../../utils';
import { customMetadataStorage } from '../custom-metadata/storage';
import { ItemModel } from '../model/item.model';
import * as deepmerge from 'deepmerge';
// not exported as considered private. Use provided functions to get metadata.
const CRUD_ENDPOINTS_KEY = 'CRUD_ENDPOINTS_KEY';
export function isCrudClientCacheEnabled(config, endpointId, reqType) {
  if (!config.endpoints?.[endpointId]) return false;
  const epConfig = config.endpoints[endpointId];
  if (typeof epConfig === 'boolean') return epConfig;
  return epConfig.includes(reqType);
}
export function CrudEndpoints(endpoints, options = {}) {
  return function CrudEndpointsDecorator(ItemClass) {
    if (!extendsConstructor(ItemClass, ItemModel)) throw Error('ItemInfo decorator must only be used on classes extending ItemModel!');
    if (!IS_PRODUCTION) {
      Object.keys(endpoints).forEach(name => {
        if (!endpoints[name].startsWith('/')) {
          console.error(`endpoint paths defined in @CrudEndpoints must start with "/"! Must be fixed for ${name}:"${endpoints[name]}" on class ${ItemClass.name}`);
        }
      });
    }
    customMetadataStorage.addMetadata({
      metaType: CRUD_ENDPOINTS_KEY,
      target: ItemClass,
      propertyName: null,
      options: {
        map: endpoints,
        options
      }
    });
  };
}
function getCrudEndpointsMetadata(ItemClass) {
  const metas = customMetadataStorage.findMetadataOfType(CRUD_ENDPOINTS_KEY, ItemClass);
  if (metas.length === 1) return metas[0].options;else if (metas.length > 1) {
    // do we want to support this? One CrudEndpoints decorator per model should be sufficient.
    // throw new Error('Detected multiple @CrudEndpoints metadata sets for '+ItemClass.name+' - this is unsupported, please merge into one decorator.')
    return deepmerge.all(metas.map(meta => meta.options));
  } else return null;
}
export function getCrudEndpoint(ItemClass, name) {
  const meta = getCrudEndpointsMetadata(ItemClass);
  if (typeof meta.map[name] !== 'string') throw new Error('Unknown Crud endpoint "' + name + '" for Item ' + ItemClass + '. Check @CrudEndpoints for correct entries!');
  const path = meta.map[name];
  const options = meta.options;
  return {
    path,
    options
  };
}