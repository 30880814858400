import { defaultMetadataStorage, instanceToPlain, plainToInstance } from "class-transformer-global-storage";
import { CLASS_TRANSFORMER_GLOBAL_CONFIG } from '../class-transformer';
import { customMetadataStorage } from '../custom-metadata';
import { Model } from '../model/model.class';
import { extendsConstructor } from '../../utils';
/**
 * ClassTransformService can be injected!
 */
export class ClassTransformExecutor {
  static getMetadataStorage() {
    return defaultMetadataStorage;
  }
  static getCustomMetadataStorage() {
    return customMetadataStorage;
  }
  static createOptionsFor(cls, object, type, customOptions = {}, dependencies) {
    const isModelTransformation = type === 'toPlain' && object instanceof Model || type === 'toInstance' && extendsConstructor(cls, Model);
    if (isModelTransformation) {
      // use strict options if the class is part of our Model system
      return {
        ...CLASS_TRANSFORMER_GLOBAL_CONFIG,
        dependencies,
        ...customOptions
      };
    } else {
      return {
        dependencies,
        ...customOptions
      };
    }
  }
  static instanceToPlain(object, deps, options) {
    const fullOptions = this.createOptionsFor(object.constructor, object, 'toPlain', options, deps);
    return instanceToPlain(object, fullOptions);
  }
  static plainToInstance(cls, plain, deps, options) {
    const fullOptions = this.createOptionsFor(cls, plain, 'toInstance', options, deps);
    return plainToInstance(cls, plain, fullOptions);
  }
}