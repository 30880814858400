import { ModelId } from '../model-decorators';
export function createValidClassName(name) {
  // strip all special chars / replace by _
  const replace = /[^a-zA-Z0-9_]+/;
  name = name.replace(replace, '_');
  name = name[0].toUpperCase() + name.slice(1);
  return name;
}
export function createChildModel(ParentClass, childName) {
  // this function is pretty special & tricky. 
  // goal is to create a child class on demand, but to keep a good console logging name / exception naming at the same time.
  // if we'd do a simple class [childName] extends ParentClass, logging it to console would state "class [childName] extends ParentClass" -- not helpful.
  // There are several possibilities to solve this, but the best solution I could come up with is a combination of ES5 + ES6 style.
  // Further ideas here:
  // https://stackoverflow.com/questions/9479046/is-there-any-non-eval-way-to-create-a-function-with-a-runtime-determined-name/9479081#9479081
  // createValidClassName will make sure these are simple non-malicious strings + contain only chars allowed in class names
  const parentName = createValidClassName(ParentClass.name);
  childName = createValidClassName(childName);
  // create and execute a dynamic function with eval'd body.
  // pass in ParentClass constructor + correct name of parent and child class accordingly
  const ChildClass = new Function(parentName, "return class " + childName + " extends " + parentName + " {};")(ParentClass);
  ModelId(childName)(ChildClass);
  return ChildClass;
  // other ideas
  // const helper = {
  // 	[childName]: class extends ParentClass {}
  // };
  // const obj = {
  // 	[childName]() {
  // 	  throw new Error();
  // 	}
  // };
  // Object.setPrototypeOf(c,Object.create(ParentClass.prototype));
  // console.log(Object.create(ParentClass.prototype),c)
  // Object.defineProperty(obj[childName],'name',{value:childName});
}