import { ValidateBy } from 'class-validator';
import { MaxLength as MaxLengthBase } from 'class-validator';
import { MinLength as MinLengthBase } from 'class-validator';
const messageMin = 'Dieses Feld muss mindestens $constraint1 Zeichen beinhalten.';
const messageMax = 'Dieses Feld darf maximal $constraint1 Zeichen beinhalten.';
export function MaxLength(max, validationOptions) {
  return MaxLengthBase(max, {
    message: messageMax,
    ...validationOptions
  });
}
export function MinLength(max, validationOptions) {
  return MinLengthBase(max, {
    message: messageMin,
    ...validationOptions
  });
}
export function MinLengthStripHtml(min, validationOptions) {
  return ValidateBy({
    name: 'MIN_LENGTH_STRIPHTML',
    constraints: [min],
    validator: {
      validate: (value, args) => {
        return minLengthStripped(value, args.constraints[0]);
      },
      defaultMessage: () => messageMin
    }
  }, {
    message: messageMin,
    ...validationOptions
  });
}
export function minLengthStripped(value, min) {
  if (typeof value !== 'string') return false;
  if (value.length < min) return false;
  return stripHTML(value).length >= min;
}
export function MaxLengthStripHtml(max, validationOptions) {
  return ValidateBy({
    name: 'MAX_LENGTH_STRIPHTML',
    constraints: [max],
    validator: {
      validate: (value, args) => {
        return maxLengthStripped(value, args.constraints[0]);
      },
      defaultMessage: () => messageMax
    }
  }, {
    message: messageMax,
    ...validationOptions
  });
}
export function maxLengthStripped(value, max) {
  if (typeof value !== 'string') return false;
  if (value.length < max) return true;
  return stripHTML(value).length <= max;
}
function stripHTML(value) {
  return value.replace(/<[^>]*>/g, '').trim();
}