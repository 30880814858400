import { BehaviorSubject } from 'rxjs';
// can be used as pipe
export function convertToSubject(initialValue = null) {
  return observable => {
    const subject = new BehaviorSubject(initialValue);
    observable.subscribe({
      complete: () => subject.complete(),
      error: x => subject.error(x),
      next: x => subject.next(x)
    });
    return subject;
  };
}
// identical to convertToSubject, but explicitly returns a BehaviorSubject so that next/getValue are available
// must be used as wrapper to allow for correct typing - convertToBehaviorSubject(null)(source)
export function convertToBehaviorSubject(initialValue = null) {
  return observable => {
    return convertToSubject(initialValue)(observable);
  };
}