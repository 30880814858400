import * as equal from 'fast-deep-equal';
import { Model } from '../model/model/model.class';
/**
 * fast-deep-equal has one issue that can be a problem:
 * when comparing two instances of the same class, it will try to do a full, deep comparison.
 * Especially when using (undetectable) object Proxies, this can end up in fatal CallStack exceeded errors.
 * For that reason, isEqualDeep will only run full comparison for plain objects and Model instances!
 * See also #86bwquuxj
 */
export function isEqualDeep(a, b) {
  const aIsClassInstance = typeof a === 'object' && a !== null && a.constructor !== Object && !(a instanceof Model);
  const bIsClassInstance = typeof b === 'object' && b !== null && b.constructor !== Object && !(a instanceof Model);
  if (aIsClassInstance && bIsClassInstance) {
    return a === b;
  }
  return equal(a, b);
}