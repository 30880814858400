import { ClassTransformService } from '../class-transform';
export function hasClassTransformerMetadata(classCtor) {
  /**
   * scanning a class for class-transformer metadata is quite complicated.
   * there is no api to check for existing metadata.
   * most types of metadata can only be checked on a per-property basis.
   */
  if (typeof classCtor !== 'function') throw new Error('hasClassTransformerMetadata: argument has invalid type, only class constructor is allowed!');
  const metadataStorage = ClassTransformService.getMetadataStorage();
  // if a class has props that are decorated with @Expose/@Exclude we're lucky
  if (metadataStorage.getExposedMetadatas(classCtor).length) return true;
  if (metadataStorage.getExcludedMetadatas(classCtor).length) return true;
  // nothing found by now -- we need to get a bit hacky then
  // check if storage for type or transform metadata has any entries for given classCtor
  const unlockedStorage = metadataStorage;
  const typeMetadataMap = unlockedStorage._typeMetadatas.get(classCtor);
  if (typeMetadataMap?.size) return true;
  const transformMetadataMap = unlockedStorage._transformMetadatas.get(classCtor);
  if (transformMetadataMap?.size) return true;
  return false;
}