import 'reflect-metadata';
;
export const ROUTE_INPUT_KEY = Symbol('ROUTE_INPUT_KEY');
export function RouteInput(type, name, settings = {}) {
  return function RouteInputDecorator(instance, property) {
    const ctor = instance.constructor;
    const meta = getRouteInputMetadata(ctor);
    if (property.endsWith('$') && typeof settings.observable !== 'boolean') console.warn('@RouteInput was applied to property "' + property + '" that ends with $, but observable setting is not set!');
    meta.push({
      type,
      property,
      name: name || property,
      settings: settings || {}
    });
    Reflect.defineMetadata(ROUTE_INPUT_KEY, meta, ctor);
    // console.log('define RouteInputMetadata',ctor,meta)
  };
}
export function getRouteInputMetadata(ctor) {
  // console.log('getRouteInputMetadata',Reflect.getMetadata(ROUTE_INPUT_KEY,ctor))
  return Reflect.getMetadata(ROUTE_INPUT_KEY, ctor) || [];
}