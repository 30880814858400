import { BaseFeature } from '@core/shared/feature';
import { MEMBERSHIP_PLUGINS_TOKEN } from '@common/membership/shared';
export class MembershipPluginsLegacyBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-pflegetheke-membership-plugins-legacy-feature","featureId":"pflegetheke-membership-plugins-legacy"},
      icons: [],
      plugins: [],
      abilityActions: false,
      providers: [{
        forPlatform: 'frontend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PaymentFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'frontend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'InsuranceFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'frontend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PrivateInsuranceFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'admin',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PaymentFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'admin',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'InsuranceFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'admin',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PrivateInsuranceFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'backend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PaymentMembershipPlugin',
        importFrom: 'shared'
      }, {
        forPlatform: 'backend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'InsuranceMembershipPlugin',
        importFrom: 'shared'
      }, {
        forPlatform: 'backend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PrivateInsuranceMembershipPlugin',
        importFrom: 'shared'
      }]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {}
      }
    };
  }
}