import { MembershipHelper, MembershipPlugin } from '@common/membership/shared';
import { of } from 'rxjs';
import { privateInsurances } from './insurances';
import { MembershipRegistrationDataPrivateInsuranceSimpleModel } from './models';
export class PrivateInsuranceSimpleMembershipPlugin extends MembershipPlugin {
  static getInfo() {
    return {
      id: 'private-insurance-simple',
      name: 'Private Krankenversicherung',
      description: "Registrierung für Privatversicherte",
      paymentRequired: false
    };
  }
  calculateValidUntil(existingMembershipValidity) {
    return MembershipHelper.calculateValidUntil(180, existingMembershipValidity);
  }
  getLabelForField(fieldId) {
    switch (fieldId) {
      case 'email':
        return 'E-Mail-Adresse';
      case 'phone':
        return 'Telefonnummer (optional)';
      case 'firstName':
        return 'Vorname';
      case 'lastName':
        return 'Nachname';
      // case 'password': return 'Passwort';
      case 'insuranceId':
        return 'Name Ihrer Versicherung';
      case 'insuranceMembershipId':
        return 'Versichertennummer';
      case 'allow3rdPartyContact':
        return 'Zustimmung zur Kontaktaufnahme ';
      case 'address':
        return 'Straße und Hausnr.';
      case 'zipcode':
        return 'Postleitzahl';
      case 'city':
        return 'Stadt';
    }
    return '<' + fieldId + '>';
  }
  getDisplayValue(fieldId, value) {
    if (fieldId === 'insuranceId') return this.getInsuranceById(value)?.name || 'Unbekannt (' + value + ')';
    return super.getDisplayValue(fieldId, value);
  }
  getInsuranceById(id) {
    return privateInsurances.find(i => i.id === id);
  }
  getFormDataModel() {
    return MembershipRegistrationDataPrivateInsuranceSimpleModel;
  }
  getFormDataFields(withAccountData = false) {
    const rows = ['insuranceId', 'insuranceMembershipId', 'address', 'zipcode', 'city'];
    if (withAccountData) {
      rows.push('firstName', 'lastName', 'email', 'phone', 'allow3rdPartyContact');
    }
    return rows;
  }
  getCategorizationLabel(categoryValue) {
    if (!categoryValue) return '-';
    const insurance = this.getInsuranceById(categoryValue);
    return insurance?.name || '???';
  }
  generateSummaryData(data, withAccountData = false) {
    const fieldIds = this.getFormDataFields(withAccountData);
    const rows = fieldIds.map(field => {
      return {
        label: this.getLabelForField(field),
        value: this.getDisplayValue(field, data[field])
      };
    });
    return of([{
      id: 'default',
      title: 'Ihre Angaben',
      rows
    }]);
  }
  generateReportData(memberships, detailed) {
    const reports = [];
    const info = this.getStaticPluginInfo();
    const membershipsGroupedByCategory = new Map();
    memberships.forEach(membership => {
      const existing = membershipsGroupedByCategory.get(membership.membershipTypeCategorization) || [];
      const newList = [...existing, membership];
      membershipsGroupedByCategory.set(membership.membershipTypeCategorization, newList);
    });
    membershipsGroupedByCategory.forEach((entries, categoryName) => {
      const name = categoryName ? this.getInsuranceById(categoryName)?.name || categoryName : 'Unbekannt';
      const id = categoryName || 'unknown';
      reports.push({
        name,
        id,
        membershipType: info.id,
        entries
      });
    });
    return reports;
  }
  getRegistrationDataFields() {
    return ['insuranceId', 'insuranceMembershipId', 'address', 'zipcode', 'city'];
  }
  getReportColumnHeaders() {
    return this.getRegistrationDataFields().map(field => this.getLabelForField(field));
  }
  getReportColumnValues(membership, data) {
    return this.getRegistrationDataFields().map(field => {
      return this.getDisplayValue(field, data[field]) || '-';
    });
  }
  preparePersistedRegistrationData(data) {
    return data;
  }
}