export class FeaturePlugin {
  constructor() {
    this.pluginName = null;
  }
  getFeatureDefinition() {
    return {};
  }
  getFeatureDefinitionCached() {
    // if(this.__featureFunctionalityCache) return this.__featureFunctionalityCache;
    // this.__featureFunctionalityCache = this.getFeatureDefinition();
    return this.__def;
  }
  // // lifecycle events
  onFeatureInit() {}
  onFeatureDestroy() {}
}