import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
export const SupportedHashAlgorithms = ['sha-1', 'sha-256', 'sha-512'];
export function hashString(_x, _x2) {
  return _hashString.apply(this, arguments);
}
function _hashString() {
  _hashString = _asyncToGenerator(function* (algo, data) {
    const encoder = new TextEncoder();
    const arrayBuffer = encoder.encode(data);
    return hashData(algo, arrayBuffer);
  });
  return _hashString.apply(this, arguments);
}
export function hashData(_x3, _x4) {
  return _hashData.apply(this, arguments);
}
function _hashData() {
  _hashData = _asyncToGenerator(function* (algo, arrayBuffer) {
    return new Promise((res, rej) => {
      crypto.subtle.digest(algo, arrayBuffer).then(hash => {
        let hex = "";
        let padding = "";
        const hashAsArray = new Uint8Array(hash);
        for (let i = 0; i < hashAsArray.byteLength; i++) {
          const byte = hashAsArray[i];
          padding = byte < 16 ? "0" : "";
          hex += padding + byte.toString(16);
        }
        res(hex);
      });
    });
  });
  return _hashData.apply(this, arguments);
}