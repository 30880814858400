export const CrudQueryBuilderDefaultOptions = {
  delim: '||',
  delimStr: ',',
  paramNamesMap: {
    fields: ['fields', 'select'],
    search: 's',
    filter: 'filter',
    or: 'or',
    join: 'join',
    sort: 'sort',
    limit: ['limit', 'per_page'],
    offset: 'offset',
    page: 'page',
    cache: 'cache',
    includeDeleted: 'include_deleted'
  }
};