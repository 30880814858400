import { switchMap } from 'rxjs';
import { I18nActions, I18nState } from './i18n.state';
export class NgxTranslateAdapterLoader {
  constructor(store) {
    this.store = store;
  }
  getTranslation(lang) {
    // lang argument will be ignored because i18nState acts as master and loader/ngx-translate as slave.
    return this.store.dispatch(new I18nActions.LoadLanguageData(false)).pipe(switchMap(() => {
      // console.log('state at moment of selection',this.store.snapshot)
      return this.store.selectOnce(I18nState.selectedLanguageData);
    }));
  }
}