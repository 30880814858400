// deep imports to fix circular deps in jest
import { customMetadataStorage } from '../model/custom-metadata';
import { ItemModel } from '../model/model';
// not exported as considered private. Use provided functions to get metadata.
const USE_RESTRAINTS_KEY = 'USE_RESTRAINTS_KEY';
export function UseRestraints(ClassCtor) {
  return function ItemModelOptionsDecorator(ItemClass) {
    if (!(ItemClass.prototype instanceof ItemModel)) throw Error('ItemInfo decorator must only be used on classes extending ItemModel!');
    customMetadataStorage.addMetadata({
      metaType: USE_RESTRAINTS_KEY,
      target: ItemClass,
      propertyName: null,
      options: {
        ctor: ClassCtor
      }
    });
  };
}
export function getRestraintsForModel(ItemClass) {
  const metas = customMetadataStorage.findMetadataOfType(USE_RESTRAINTS_KEY, ItemClass);
  if (metas.length) return metas[0].options.ctor;else throw new Error('Could not find a Restraints class for Model ' + ItemClass);
}