import { CondOperator } from '@nestjsx/crud-request/lib/types';
/**
 * A utility class abstracting construction of difficult-to-get-right SCondition structures
 */
export class CrudSearchQueryBuilder {
  constructor() {
    this.instructions = [];
  }
  addInstruction(type, data) {
    if (type === '$and') {
      const normalized = Array.isArray(data) ? data : [data];
      this.instructions.push(...normalized);
    } else {
      const normalized = Array.isArray(data) ? data : [data];
      const subStruct = {
        [type]: normalized
      };
      this.instructions.push(subStruct);
    }
    return this;
  }
  fromQueryFilter(type, filter) {
    const filterUsesValue = !(filter.operator === CondOperator.NOT_NULL || filter.operator === CondOperator.IS_NULL);
    const value = filterUsesValue ? filter.value : 1;
    const normalized = {
      [filter.field]: {
        [filter.operator]: value
      }
    };
    this.addInstruction(type, normalized);
    return this;
  }
  addSubSearch(type, data) {
    this.addInstruction(type, data.getSearchInstructions());
    return this;
  }
  createSubSearch(type, handler) {
    const subSearchBuilder = new CrudSearchQueryBuilder();
    handler(subSearchBuilder);
    this.addInstruction(type, subSearchBuilder.getSearchInstructions());
    return this;
  }
  getSearchInstructions() {
    return [...this.instructions];
  }
  getSearchQuery() {
    if (this.instructions.length > 0) {
      return {
        $and: [...this.instructions]
      };
    } else {
      return null;
    }
  }
}