/** This base class should not be used directly.
 * both server and client platforms feature an injectable "ProtocolService" offering a simple, public API. */
export class ProtocolRegistry {
  constructor() {
    // ---------- END OF OLD IMPLEMENTATION ---------------------------------------
    this.registry = new Map();
    this.defaultLoggers = [];
  }
  register(service) {
    if (this.registry.has(service.name)) {
      throw new Error('Protocol Registry error: A protocol with name "' + service.name + '" has already be registered. The new, same-named protocol will not function properly.');
    }
    this.registry.set(service.name, service);
  }
  get(name) {
    if (this.registry.has(name)) return this.registry.get(name);else return null;
  }
  getAll() {
    return Array.from(this.registry.values());
  }
  registerDefaultLogger(Ctor) {
    this.defaultLoggers.push(Ctor);
  }
  getDefaultLoggers() {
    return [...this.defaultLoggers];
  }
  findAllLoggersOfType(Ctor) {
    const instances = [];
    Array.from(this.registry.values()).forEach(protocol => {
      const match = protocol.loggers.find(logger => logger instanceof Ctor);
      if (match) instances.push(match);
    });
    return instances;
  }
}