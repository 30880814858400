export function implementsIApiResponse(data) {
  if (typeof data !== 'object' || data === null) return false;
  return typeof data.error === 'boolean' && typeof data.status === 'number';
}
export function extractDataFromApiResponse(res, requestInfo) {
  if (!implementsIApiResponse(res)) {
    const reqInfo = requestInfo ? ' ' + requestInfo : '';
    throw new Error('extractDataFromApiResponse received a response that is no IApiResponse: ' + JSON.stringify(res) + reqInfo);
  }
  return res.data;
}
export function setDataInApiResponse(res, data) {
  res.data = data;
}