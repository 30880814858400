import { FileTypeDetectionHelper, MediaAssetType, supportedFileTypeToMediaAssetType } from '@common/media/shared';
export var CloudinaryHelper;
(function (CloudinaryHelper) {
  function toCloudinaryResourceType(maybeType) {
    if (["raw", "image", "video", "auto"].includes(maybeType)) return maybeType;
    if (maybeType === 'document') return 'raw';
    return 'auto';
  }
  CloudinaryHelper.toCloudinaryResourceType = toCloudinaryResourceType;
  function toMediaAssetType(maybeType) {
    switch (maybeType) {
      case 'image':
        return MediaAssetType.image;
      case 'video':
        return MediaAssetType.video;
      case 'raw':
      case 'auto':
        return MediaAssetType.raw;
    }
  }
  CloudinaryHelper.toMediaAssetType = toMediaAssetType;
  function calculateMediaAssetType(assetInfo) {
    const fileTypeGroup = FileTypeDetectionHelper.detectFileTypeGroup({
      extension: assetInfo.providerData?.originalExtension,
      filename: assetInfo.directUrl
    });
    return fileTypeGroup ? supportedFileTypeToMediaAssetType(fileTypeGroup) : false;
  }
  CloudinaryHelper.calculateMediaAssetType = calculateMediaAssetType;
})(CloudinaryHelper || (CloudinaryHelper = {}));