import { BehaviorSubject, map } from 'rxjs';
import { convertToBehaviorSubject } from '@core/shared/utils';
export class MembershipPluginsService {
  constructor(config, injector, plugins) {
    this.config = config;
    this.injector = injector;
    this.allPlugins$ = new BehaviorSubject([]);
    this.activateablePlugins$ = convertToBehaviorSubject(null)(this.allPlugins$.pipe(map(allPlugins => {
      let activateablePlugins = allPlugins.filter(p => this.activateablePluginIds.includes(p.getInfo().id));
      const enablePayment = this.config.get('membership.enablePayment', 0);
      if (!enablePayment) {
        activateablePlugins = activateablePlugins.filter(ctor => ctor.getInfo().paymentRequired === false);
      }
      // console.log('all / activateablePlugins: ',allPlugins, this.activateablePluginIds,enablePayment,activateablePlugins)
      return activateablePlugins;
    })));
    this.cache = new Map();
    this.activateablePluginIds = this.config.get('membership.activateablePluginIds', []);
    if (this.activateablePluginIds?.length === 0) {
      throw new Error('MembershipSystem configuration missing: membership.activateablePluginIds config must be set!');
    }
    this.allPlugins$.next(plugins);
  }
  getPlugin(id, includeDisabled = false) {
    const plugins = includeDisabled ? this.allPlugins$.getValue() : this.activateablePlugins$.getValue();
    return plugins.find(ctor => ctor.getInfo().id === id);
  }
  getPluginInstance(id, includeDisabled = false) {
    if (this.cache.has(id)) return this.cache.get(id);
    const plugin = this.getPlugin(id, includeDisabled);
    if (!plugin) throw new Error('unknown plugin ' + id);
    const instance = new plugin(this.injector);
    this.cache.set(id, instance);
    return instance;
  }
  getPluginInfo(id) {
    const pluginCtor = this.getPlugin(id, true);
    return pluginCtor ? pluginCtor.getInfo() : null;
  }
  getPluginsList(includeDisabled = false) {
    const source = includeDisabled ? this.allPlugins$ : this.activateablePlugins$;
    // console.log('getpluginslist',includeDisabled,source.getValue())
    return source.getValue().map(ctor => {
      return ctor.getInfo();
    });
  }
}