import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessState } from '@core/frontend-shared';
import { Store } from '@ngxs/store';


@Component({
	selector:'app-management-dynamic-redirect',
	template:`<ui-content-centered>
		<span>Bitte wählen Sie links im Menü Ihr Ziel.</span>
	</ui-content-centered>`,
})
export class AppManagementDynamicRedirectComponent {

	
	constructor(
		// private authService: AuthService, 
		private router: Router,
		private route: ActivatedRoute,
		private store: Store,
	) {

		const isManager = this.store.selectSnapshot(AccessState.accessData('isManager'))
		const isDocent = this.store.selectSnapshot(AccessState.hasPermission('DOCENT'))
		// console.log('ManagementDefaultRouteGuard check isManager',isManager)

		if (isManager) {
			this.router.navigate(['./manager'],{relativeTo:this.route})
		} else if (isDocent) {
			this.router.navigate(['./docent'],{relativeTo:this.route})
		}

	}

}