import { AccessUserPluginFrontendDataAccessors } from '@common/users/frontend-shared';
const MEMBERSHIP_USER_PLUGIN_ID = 'membership';
export const AccessMembershipPluginDataAccessors = {
  membership: (getPluginData, info) => {
    return getPluginData()?.membership || null;
  },
  hasExpiredMembership: (getPluginData, info) => {
    return getPluginData()?.hasExpiredMembership;
  },
  membershipIsValid: (getPluginData, info) => {
    return getPluginData()?.membershipIsValid;
  },
  membershipDaysLeft: (getPluginData, info) => {
    return getPluginData()?.membershipDaysLeft;
  },
  membershipPaymentPending: (getPluginData, info) => {
    return getPluginData()?.membershipPaymentPending;
  }
};
export class AccessMembershipFrontendPlugin {
  constructor() {
    this.pluginId = MEMBERSHIP_USER_PLUGIN_ID;
    this.dataAccessors = {
      ...AccessUserPluginFrontendDataAccessors,
      ...AccessMembershipPluginDataAccessors
    };
  }
}