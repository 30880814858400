import { getMediaAssetsMetadataForModel, getModelsWithMediaAssetsMetadata } from './media-assets.decorator';
import { isPlainObject } from '@core/shared/utils';
export class MediaAssetsHelper {
  static findModelsWithMediaAssetsMetadata() {
    return getModelsWithMediaAssetsMetadata();
  }
  // support for model instances was removed cause there are some situations where the model/entity could be a plain object
  static getConfig(model) {
    if (!model) throw new Error('Data passed to MediaAssetsHelper is falsy. Check before passing it in!');
    if (isPlainObject(model)) {
      throw new Error('MediaAssetsHelper.getConfig received plain object, only classes or instances are supported! ' + JSON.stringify(model));
    }
    const meta = getMediaAssetsMetadataForModel(model);
    if (!meta.length) throw new Error('Model ' + model + ' has no MediaAsset metadata assigned!');
    const config = meta[0].options;
    return config;
  }
  /**
   * it is allowed to access an item's MediaAssetSlotsMap directly.
   * If it is unknown where the map is stored, this API will find the map via metadata.
   */
  static getAllAssetMappings(model, modelCtor) {
    const config = this.getConfig(modelCtor);
    return model[config.targetProperty] || {};
  }
  static slotMapToMappingList(map) {
    const list = [];
    Object.entries(map).forEach(([slotName, mapping]) => {
      if (!mapping) return;
      mapping.consumerMediaSlot = slotName;
      list.push(mapping);
    });
    return list;
  }
  /**
   * NOTE: getAssetBySlot/getAssetMappingBySlot can only work on Model instances! Passing plain data will fail.
   */
  static getAssetBySlot(model, slotName) {
    return this.getAssetMappingBySlot(model, slotName)?.asset || null;
  }
  static getAssetMappingBySlot(model, slotName) {
    const assets = this.getAllAssetMappings(model, model.constructor);
    return assets[slotName] || null;
  }
}