import 'reflect-metadata';
import { ReplaceableComponentsRegistry } from './replaceable-components.registry';
import { getComponentMetaSelector } from '../../utils';
export const REPLACEABLE_COMPONENT_ID = Symbol('REPLACEABLE_COMPONENT_ID');
export function ReplaceableComponent(id) {
  return function ReplaceableComponentDecorator(ctor) {
    // console.info('ReplaceableComponent Metadata assignment started for '+ctor.name,id)
    if (!id) {
      const selector = getComponentMetaSelector(ctor);
      if (!selector) {
        console.warn('@ReplaceableComponent found no @Component metadata for ' + ctor.name + '.' + 'Make sure to put @ReplaceableComponent before @Component so that it executes after it!');
      } else {
        // console.info('ReplaceableComponent Metadata applied to '+ctor.name+' ('+selector+')')
      }
      id = selector;
    }
    const meta = id;
    Reflect.defineMetadata(REPLACEABLE_COMPONENT_ID, meta, ctor);
    ReplaceableComponentsRegistry.add(id, ctor);
  };
}
export function getReplaceableComponentId(component) {
  if (typeof component !== 'function' && typeof component !== 'object') throw new Error('specified arg is no component');
  const ctor = typeof component === 'function' ? component : component.constructor;
  return Reflect.getMetadata(REPLACEABLE_COMPONENT_ID, ctor) || null;
  // isConstructor
}