/** ReplaceableComponentsRegistry needs to be accessible to decorators.
 * Thus it is not possible to make it part of injection system.
 * But this is no problem as it is compatible with multiple app executions.
 * In worst case, the same component replacement will be defined again, overwriting the previous entry.
 */
export class ReplaceableComponentsRegistry {
  static {
    this.registry = new Map();
  }
  static add(id, component) {
    this.registry.set(id, component);
  }
  static get(id) {
    return this.registry.get(id);
  }
}