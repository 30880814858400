import { arrayUnique } from '../utils';
import { BehaviorSubject, map } from 'rxjs';
// located in own file which is not exported from index, so it is "internal"
export class FeatureProvidersRegistry {
  constructor(providers) {
    this.registry$ = new BehaviorSubject(null);
    this.setup(providers);
  }
  getProviders(token) {
    const providerMap = this.registry$.getValue();
    if (!providerMap) throw new Error('synchronous getProviders() was called before providers have been set!');
    return providerMap.get(token);
  }
  getProviders$(token) {
    return this.registry$.pipe(map(registry => registry.get(token)));
  }
  // only use this method from within core library.
  // features must add FeatureProviders within their feature configuration.
  // when used, ensure that a provider is only added once.
  // one safe way to handle this is to store the data as const in module level.
  // then the registry can compare it and filter out duplicates.
  addProvider(token, provided, skipDuplicates = true) {
    const reg = this.registry$.getValue();
    const list = reg.get(token) || [];
    if (skipDuplicates && list.includes(provided)) return;
    list.push(provided);
    reg.set(token, list);
    this.registry$.next(reg);
  }
  setup(providers) {
    const providersMap = new Map();
    providers.forEach(p => {
      const list = providersMap.get(p.token) || [];
      list.push(p.value);
      providersMap.set(p.token, list);
    });
    providersMap.forEach((list, token) => {
      providersMap.set(token, arrayUnique(list));
    });
    this.registry$.next(providersMap);
  }
}