import { PaymentMembershipPlugin } from '@pflegetheke/membership-plugins-legacy/shared';
/** plugin only for viewing support of memberships of legacy "payment" type */
export class PaymentFrontendMembershipPlugin extends PaymentMembershipPlugin {
  getRegistrationComponent() {
    throw new Error('Not implemented, only viewing support for existing legacy memberships!');
    return null;
  }
  getSummaryComponent() {
    throw new Error('Not implemented, only viewing support for existing legacy memberships!');
    return null;
  }
  getAccountData(data) {
    throw new Error('Not implemented, only viewing support for existing legacy memberships!');
    return null;
  }
  getDataToPersist(data) {
    throw new Error('Not implemented, only viewing support for existing legacy memberships!');
    return null;
  }
  getCategorization(data) {
    return null;
  }
}