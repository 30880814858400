import { RegistrationReason } from './reason';
import { of } from 'rxjs';
import { objectWithout, getDotProp } from '@core/shared/utils';
import { MembershipHelper, MembershipPlugin, MembershipReportHelper } from '@common/membership/shared';
import { insurances } from '@pflegetheke/membership-plugins-insurance/shared';
/** plugin to support viewing memberships of legacy "insurance" type */
export class InsuranceMembershipPlugin extends MembershipPlugin {
  static getInfo() {
    return {
      id: 'insurance',
      // "insurance" is legacy membership type, must use different ID!
      name: 'Versicherung (legacy)',
      paymentRequired: false
    };
  }
  calculateValidUntil(existingMembershipValidity) {
    return MembershipHelper.calculateValidUntil(180, existingMembershipValidity);
  }
  getLabelForField(fieldId) {
    switch (fieldId) {
      case 'reason':
        return 'Grund der Anmeldung';
      case 'topic':
        return 'Hauptthema';
      case 'careDegree':
        return 'Pflegegrad vorhanden';
      case 'username':
        return 'Benutzername';
      case 'email':
        return 'E-Mail-Adresse';
      case 'phone':
        return 'Telefonnummer (optional)';
      case 'firstName':
        return 'Vorname';
      case 'lastName':
        return 'Nachname';
      case 'password':
        return 'Passwort';
      case 'password2':
        return 'Passwort wiederholen';
      case 'insuranceId':
        return 'Name Ihrer Versicherung';
      case 'insuranceMembershipId':
        return 'Versichertennummer';
      case 'allow3rdPartyContact':
        return 'Zustimmung zur Kontaktaufnahme ';
      case 'birthdate':
        return 'Geburtsdatum';
      case 'address':
        return 'Straße und Hausnr.';
      case 'zipcode':
        return 'Postleitzahl';
      case 'city':
        return 'Ort';
      case 'privateInsuranceMembershipId':
        return 'Privat-Versichertennr.';
    }
    return '<' + fieldId + '>';
  }
  getDisplayValue(fieldId, value) {
    if (fieldId === 'insuranceId') return this.getInsuranceById(value)?.name || 'Unbekannt (' + value + ')';
    if (fieldId === 'reason') return this.readableRegistrationReason(value);
    if (fieldId === 'topic') return value + '(raw ID)';
    return super.getDisplayValue(fieldId, value);
  }
  readableRegistrationReason(type) {
    switch (type) {
      case RegistrationReason.careDegree:
        return 'Pflege einer Person mit Pflegegrad';
      case RegistrationReason.careDegreePending:
        return 'Pflege einer Person (ohne Pflegegrad)';
      case RegistrationReason.practiceVolunteerWork:
        return 'Vorbereitung auf ehrenamtliche/professionelle Tätigkeit';
      case RegistrationReason.generalAndPrevention:
        return 'Allgemeines Interesse und Präventionsmaßnahmen';
    }
  }
  getInsuranceById(id) {
    // throw new Error('reimplement in insurance plugins')
    return insurances.find(i => i.id === id);
  }
  getFormDataModel() {
    throw new Error('Method not implemented.');
  }
  getFormDataFields(withAccountData = false) {
    const rows = ['insuranceId', 'insuranceMembershipId'];
    if (withAccountData) {
      rows.push('firstName', 'lastName', 'email', 'phone', 'allow3rdPartyContact');
    }
    return rows;
  }
  getCategorizationLabel(categoryValue) {
    if (!categoryValue) return '-';
    const insurance = this.getInsuranceById(categoryValue);
    return insurance?.name || '???';
  }
  generateSummaryData(data, withAccountData = false) {
    const summary = [];
    const fields = this.getRegistrationDataFields();
    const reasonRows = fields.reason.map(field => {
      return {
        label: this.getLabelForField(field),
        value: this.getDisplayValue(field, data['reason'][field])
      };
    });
    summary.push({
      id: 'reason',
      title: 'Grund der Anmeldung',
      rows: reasonRows
    });
    const insuranceInfoRows = fields.insuranceInfo.map(field => {
      return {
        label: this.getLabelForField(field),
        value: this.getDisplayValue(field, data['insuranceInfo'][field])
      };
    });
    // Note: Insurance info only lives in MembershipItem. Cant display it here.
    summary.push({
      id: 'insuranceInfo',
      title: 'Daten für die Versicherung',
      rows: insuranceInfoRows
    });
    if (withAccountData) {
      const accountRows = fields.account.map(field => {
        return {
          label: this.getLabelForField(field),
          value: this.getDisplayValue(field, data['account'][field])
        };
      });
      const accountSummary = {
        id: 'account',
        title: 'Ihr Account',
        rows: accountRows
      };
      summary.push(accountSummary);
    }
    return of(summary);
  }
  generateReportData(memberships, detailed) {
    const reports = [];
    const info = this.getStaticPluginInfo();
    const membershipsGroupedByCategory = new Map();
    memberships.forEach(membership => {
      const existing = membershipsGroupedByCategory.get(membership.membershipTypeCategorization) || [];
      const newList = [...existing, membership];
      membershipsGroupedByCategory.set(membership.membershipTypeCategorization, newList);
    });
    membershipsGroupedByCategory.forEach((entries, categoryName) => {
      const name = categoryName ? this.getInsuranceById(categoryName)?.name || categoryName : 'Unbekannt';
      const id = categoryName || 'unknown';
      reports.push({
        name,
        id,
        membershipType: info.id,
        entries
      });
    });
    return reports;
  }
  getReportColumnHeaders() {
    const fields = MembershipReportHelper.getRegistrationDataFieldsAsDotProps(this.getRegistrationDataFields(false));
    return fields;
  }
  getReportColumnValues(membership, data) {
    const fields = MembershipReportHelper.getRegistrationDataFieldsAsDotProps(this.getRegistrationDataFields(false));
    return fields.map(fieldName => {
      return getDotProp(data, fieldName) || '-';
    });
  }
  getRegistrationDataFields(includeAccount = true) {
    const fields = {
      reason: ['topic', 'careDegree'],
      insuranceInfo: ['insuranceMembershipId', 'firstName', 'lastName', 'birthdate', 'address', 'zipcode']
    };
    if (includeAccount) fields['account'] = ['username', 'email', 'firstName', 'lastName', 'password'];
    return fields;
  }
  preparePersistedRegistrationData(data) {
    return {
      reason: data.reason,
      insuranceInfo: data.insuranceInfo,
      account: data.account ? objectWithout(data.account, ['password', 'password2']) : undefined
    };
  }
}