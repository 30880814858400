import { ProtocolLogger } from '@core/shared/protocol';
import { convertToSubject } from '@core/shared/utils';
import { map, merge, scan, Subject } from 'rxjs';
/** A logger which exposes its data on dev console (@common/console, not dev tools!) */
export class DevConsoleProtocolLogger extends ProtocolLogger {
  constructor(protocol) {
    super(protocol);
    this.next$ = new Subject();
    this.clear$ = new Subject();
    this.fullLog$ = merge(this.next$.pipe(map(log => {
      return {
        cmd: 'log',
        log
      };
    })), this.clear$.pipe(map(() => {
      return {
        cmd: 'clear'
      };
    }))).pipe(scan((aggrLog, command) => {
      if (command.cmd === 'clear') return [];
      if (command.cmd === 'log') {
        const entry = command['log'];
        if (entry.group) {
          const existingGroup = aggrLog.find(groupEntry => groupEntry.group === entry.group);
          if (existingGroup) {
            existingGroup.entries.push(entry);
          } else {
            aggrLog.push({
              group: entry.group,
              date: entry.date,
              message: entry.message,
              entries: []
            });
          }
        } else {
          aggrLog.push({
            date: entry.date,
            data: entry.data,
            message: entry.message
          });
        }
        return aggrLog;
      }
    }, []), convertToSubject([]));
    // this.next$.subscribe(data=>{
    // 	console.log('protocol emitted',data)
    // })
  }
  log(entry) {
    this.next$.next(entry);
  }
  clearLog() {
    this.clear$.next(true);
  }
}