// a minimal utility class allowing to handle a number of subscriptions / unsubscribing.
export class SubscriptionManager {
  constructor() {
    this.list = [];
  }
  add(...subscr) {
    this.list.push(...subscr);
  }
  remove(subscr, unbind = false) {
    const index = this.list.findIndex(entry => entry === subscr);
    this.list.splice(index, 1);
    if (unbind) this.unbind(subscr);
  }
  clear() {
    this.list.forEach(subscr => {
      this.unbind(subscr);
    });
    this.list = [];
  }
  unbind(subscr) {
    if (typeof subscr === 'function') {
      subscr();
    } else {
      subscr?.unsubscribe();
    }
  }
}