import { EditorModelFactory } from './editor-definition';
import { ModelFactory } from './model/model.factory';
export class ModelFactoryProvider {
  constructor(classTransformService) {
    this.classTransformService = classTransformService;
  }
  createModelFactory(ModelConstructor) {
    return new ModelFactory(ModelConstructor, this.classTransformService);
  }
  createEditorModelFactory(ModelConstructor) {
    return new EditorModelFactory(ModelConstructor, this.classTransformService);
  }
}
export function modelFactoryProviderFactory(classTransform) {
  return new ModelFactoryProvider(classTransform);
}