import { pipe, ReplaySubject } from 'rxjs';
import { share } from 'rxjs/operators';
/**
 * since publishReplay, refcount is deprecated, share + ReplaySubject can be used to get same behavior.
 * this pipe adds default configuration so that it behaves the same as old publishReplay, refcount.
 */
export const shareAsReplaySubject = (bufferSize = 1, config = {}) => pipe(share({
  resetOnError: false,
  resetOnComplete: false,
  resetOnRefCountZero: false,
  ...config,
  // at this position, config allows to override resetOnX flags.
  connector: () => new ReplaySubject(bufferSize)
}));