export class BaseInstruction {
  constructor() {
    this.directiveLoaderPlugins = [];
    this.isInput = true;
    this.useFieldWrapper = true;
  }
  setConfig(config) {
    this.config = {
      ...this.getDefaults(),
      ...config
    };
  }
  // override these to compose maps in child classes
  getInputMap() {
    return this.inputMap;
  }
  getAttributeMap() {
    return this.attributeMap;
  }
  getDefaults() {
    return {};
  }
  // TODO: Support for attributes like class
  applyDirectives(comp, injector, initialFormValue) {
    try {
      this.directiveLoaderPlugins.forEach(plugin => {
        plugin(comp, injector, this.config, initialFormValue);
      });
    } catch (err) {
      console.error(err);
    }
  }
  addDirectivesPlugin(plugin) {
    this.directiveLoaderPlugins.push(plugin);
  }
  // use inputMap + merged config to build the data map to be passed to the component.
  createComponentInputsMap(formGroup, formService) {
    const tmp = this.createDataMap(formGroup, formService || null, "inputMap");
    return tmp;
  }
  createComponentAttributesMap(formGroup, formService) {
    const tmp = this.createDataMap(formGroup, formService || null, "attributeMap");
    return tmp;
  }
  addDynamicInputValues(map, sourceMapName, formGroup, formService) {
    return map;
  }
  createDataMap(formGroup, formService, sourceMapName) {
    const sourceMapFactory = sourceMapName === 'attributeMap' ? this.getAttributeMap : this.getInputMap;
    const sourceMap = sourceMapFactory.call(this);
    if (Object.keys(sourceMap).length === 0) return {};
    const map = {};
    for (const propName in sourceMap) {
      if ({}.hasOwnProperty.call(sourceMap, propName)) {
        const valueDataSource = sourceMap[propName];
        const value = typeof valueDataSource === 'string' ? this.config[valueDataSource] : valueDataSource(this.config, formGroup);
        map[propName] = value;
      }
    }
    return this.addDynamicInputValues(map, sourceMapName, formGroup, formService);
  }
}