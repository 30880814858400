export function objectWithout(obj, keysToRemove) {
  const filteredObj = Object.keys(obj).reduce((newData, currKey) => {
    if (keysToRemove.includes(currKey)) {
      return newData;
    } else {
      newData[currKey] = obj[currKey];
      return newData;
    }
  }, {});
  return filteredObj;
}