import { IsString as IsStringBase, NotEquals } from 'class-validator';
import { IsAlpha as IsAlphaBase } from 'class-validator';
import { IsAlphanumeric as IsAlphanumericBase } from 'class-validator';
import { IsNumber as IsNumberBase } from 'class-validator';
import { IsUppercase as IsUppercaseBase } from 'class-validator';
import { IsEmail as IsEmailBase } from 'class-validator';
import { IsDefined as IsDefinedBase } from 'class-validator';
import { IsIn as IsInBase } from 'class-validator';
import { ValidateIf as ValidateIfBase } from 'class-validator';
import { IsOptional as IsOptionalBase } from 'class-validator';
import { IsBoolean as IsBooleanBase } from 'class-validator';
import { IsObject as IsObjectBase } from 'class-validator';
import { ArrayNotEmpty as ArrayNotEmptyBase } from 'class-validator';
import { Matches as MatchesBase } from 'class-validator';
import { IsNotEmpty as IsNotEmptyBase } from 'class-validator';
export { ValidateNested } from 'class-validator';
export function Matches(pattern, validationOptions) {
  return MatchesBase(pattern, {
    message: '$property muss den RegExp-Test $constraint1 bestehen',
    ...validationOptions
  });
}
export function ArrayNotEmpty(validationOptions) {
  return ArrayNotEmptyBase({
    message: 'Diese Liste (Array) darf nicht leer sein!',
    ...validationOptions
  });
}
export function IsBoolean(validationOptions) {
  return IsBooleanBase({
    message: 'Dieses Feld muss einen boolschen Wert haben.',
    ...validationOptions
  });
}
export function IsAlpha(locale, validationOptions) {
  return IsAlphaBase(locale, {
    message: 'Dieses Feld darf nur einfache Buchstaben enthalten (a-zA-Z).',
    ...validationOptions
  });
}
export function IsNumber(options, validationOptions) {
  return IsNumberBase(options, {
    message: '$property muss eine Nummer sein.',
    ...validationOptions
  });
}
export function IsString(validationOptions) {
  return IsStringBase({
    message: '$property muss ein Text sein.',
    ...validationOptions
  });
}
export function IsObject(validationOptions) {
  return IsObjectBase({
    message: 'Dieses Feld muss ein Objekt sein.',
    ...validationOptions
  });
}
export function IsAlphanumeric(locale, validationOptions) {
  return IsAlphanumericBase(locale, {
    message: '$property darf nur einfache Buchstaben und Nummern enthalten.',
    ...validationOptions
  });
}
export function IsEmail(options, validationOptions) {
  return IsEmailBase(options, {
    message: 'Dies ist keine gültige E-Mail-Adresse!',
    ...validationOptions
  });
}
// disallows null, undefined and empty string
export function IsNotEmpty(validationOptions) {
  return IsNotEmptyBase({
    message: 'Dieses Feld darf nicht leer sein.',
    ...validationOptions
  });
}
// disallows null and undefined, ignores skipMissingProperties flag
export function IsDefined(validationOptions) {
  return IsDefinedBase({
    message: 'Dieses Feld darf nicht leer sein.',
    ...validationOptions
  });
}
// allows a field to be undefined, but not explicitly set to null.
// useful for allowing e.g. relations to be unspecified during PATCH, but disallows unsetting the relation.
export function IsNotNull(validationOptions) {
  return NotEquals(null, {
    message: 'Dieses Feld darf nicht leer sein.',
    ...validationOptions
  });
}
export function IsIn(values, validationOptions) {
  return IsInBase(values, {
    message: 'Dieses Feld muss einer dieser Werte sein: $constraint1',
    ...validationOptions
  });
}
export function IsOptional(validationOptions) {
  return IsOptionalBase({
    ...validationOptions
  });
}
export function IsUppercase(validationOptions) {
  return IsUppercaseBase({
    message: 'Es sind nur Großbuchstaben erlaubt!',
    ...validationOptions
  });
}
// make sure the field is not marked as optional / allowEmpty. 
// this would result in conditional validation not being run if the field is empty.
export function ValidateIf(condition, validationOptions) {
  return ValidateIfBase(condition, {
    message: '',
    ...validationOptions
  });
}
/*
    case ValidationTypes.IS_DEFINED:
        return eachPrefix + "Dieses Feld darf nicht undefiniert sein.";
    case ValidationTypes.EQUALS:
        return eachPrefix + "Dieses Feld muss identisch zu $constraint1 sein.";
    case ValidationTypes.NOT_EQUALS:
        return eachPrefix + "Dieses Feld und $constraint1 dürfen nicht übereinstimmen.";

*/