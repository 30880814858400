import { buildMessage, matches, ValidateBy } from "class-validator";
const IS_VALID_USERNAME = 'IS_VALID_USERNAME';
export function isValidUsername(value) {
  return typeof value === 'string' && matches(value, /^[\w\d-_@.]+$/);
}
export const isValidUsernameErrorMessage = 'Der Benutzername darf nur Buchstaben (a-z), Ziffern und die Zeichen - _ @ . enthalten!';
export function IsValidUsername(validationOptions) {
  return ValidateBy({
    name: IS_VALID_USERNAME,
    constraints: [/^[\w\d-_@.]+$/, ''],
    validator: {
      validate: (value, args) => isValidUsername(value),
      defaultMessage: buildMessage((eachPrefix, args) => isValidUsernameErrorMessage, validationOptions)
    }
  }, {
    message: isValidUsernameErrorMessage,
    ...validationOptions
  });
}