import { isActiveRouterLinkFactory } from './router.utils';
export class RouterLinkHelper {
  // findActiveMenuItems allows to programmatically check if a MenuItem is active or in active path.
  // currently ignores child items.
  // should be reevaluated on every router NavigationEnd event!
  static findActiveMenuItems(router, baseRoute, items) {
    if (!items || !items.length) {
      return {
        activeItems: [],
        itemsInActivePath: []
      };
    }
    const validItems = items.filter(item => {
      return !!item.routerLink;
    });
    const isActiveCheckFnExact = isActiveRouterLinkFactory(router, true);
    const isActiveCheckFn = isActiveRouterLinkFactory(router, false);
    const urlTreeOpts = baseRoute ? {
      relativeTo: baseRoute
    } : {};
    const state = validItems.map(item => {
      const urlTree = router.createUrlTree([item.routerLink], urlTreeOpts);
      const isInActivePath = isActiveCheckFn(urlTree);
      const isActive = isActiveCheckFnExact(urlTree);
      return {
        isInActivePath,
        isActive,
        item
      };
    });
    const activeItems = [];
    const itemsInActivePath = [];
    state.forEach(status => {
      if (status.isActive) activeItems.push(status.item);else if (status.isInActivePath) itemsInActivePath.push(status.item);
    });
    return {
      activeItems,
      itemsInActivePath
    };
  }
}