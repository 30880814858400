import { BaseFeature, FeatureType } from '@core/shared/feature';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
export class StatisticsBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-common-statistics-feature","featureId":"common-statistics"},
      icons: [faChartBar],
      abilityActions: false,
      plugins: [
      // {forPlatform:'frontend', type:FeatureType.Permanent, pluginClassName:'StatisticsFrontendAdminFeature'},
      {
        forPlatform: 'admin',
        type: FeatureType.Permanent,
        pluginClassName: 'StatisticsFrontendAdminFeature'
      }]
    };
  }
  getSharedDefinition() {
    return {};
  }
}