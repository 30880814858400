// not really useful cause since ivy, data structure is different in prod mode!
export function getMetaAnnotations(ctor) {
  // extract selector from prod build
  if (ctor['ɵcmp']?.selectors) return ctor['ɵcmp'].selectors[0];
  // extract selector in dev mode
  const ann = Reflect.getOwnPropertyDescriptor(ctor, '__annotations__');
  return ann?.value || null;
}
// TODO: update when https://github.com/angular/angular/pull/46685 is ready
export function getComponentMetaSelector(ctor) {
  // extract selector from prod build
  if (ctor['ɵcmp']?.selectors) {
    return ctor['ɵcmp'].selectors[0][0];
  }
  // extract selector in dev mode
  const ann = Reflect.getOwnPropertyDescriptor(ctor, '__annotations__');
  if (!ann) return null;
  return ann[0].selector;
}