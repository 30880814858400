import { ItemModel } from '@core/shared/model';
import { isEqualDeep } from '@core/shared/utils';
// obsolete for multiselect as it uses ObjectUtils.equals(item,val, DATAKEY)
// but required for orderlist which uses  ObjectUtils.findIndexInList(item, this.selection)
export const findIndexInList = (item, list) => {
  if (list) {
    // match items by comparing ID
    if (item instanceof ItemModel || typeof item === 'object' && typeof item.id === 'number') {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === item.id) return i;
      }
    } else if (typeof item === 'object') {
      // for non-indexed objects, do a deep match
      for (let i = 0; i < list.length; i++) {
        if (isEqualDeep(list[i], item)) return i;
      }
    } else {
      // strict equality check for everything else
      for (let i = 0; i < list.length; i++) {
        if (list[i] === item) return i;
      }
    }
  }
  return -1;
};
export function createPlaceholderEntryItem(placeholderLabel, optionLabelProp = null, optionValueProp = null) {
  if (!placeholderLabel) return null;
  const placeholderOpt = {};
  placeholderOpt[optionLabelProp || 'label'] = placeholderLabel;
  placeholderOpt[optionValueProp || 'value'] = null;
  placeholderOpt['isPlaceholder'] = true;
  return placeholderOpt;
}