import 'reflect-metadata';
export const PATH_PARAM_KEY = Symbol('__CRUD_PATH_PARAM__');
/**
 * A model property decorated with ItemPathParam will mark this property to be defined through ItemService/CrudService PathParams.
 * Example: A CrudService with apiUrl "/recipes/:recipeId/ingredients" is being used.
 * The ingredient item contains a recipeId property. Add a ItemPatParam decorator to allow ItemService/CrudService to set this prop automatically if a PathParam value has been set.
 */
export function ItemPathParam(config) {
  return function ItemPathParamDecorator(object, propertyName) {
    const meta = Reflect.getMetadata(PATH_PARAM_KEY, object) || {};
    meta[propertyName] = {
      name: propertyName,
      overwrite: true,
      ...(config || {})
    };
    Reflect.defineMetadata(PATH_PARAM_KEY, meta, object);
  };
}