import { BaseFeature } from '@core/shared/feature';
import { MEMBERSHIP_PLUGINS_TOKEN } from '@common/membership/shared';
export class MembershipPluginsInsuranceBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-pflegetheke-membership-plugins-insurance-feature","featureId":"pflegetheke-membership-plugins-insurance"},
      icons: [],
      plugins: [],
      abilityActions: false,
      providers: [{
        forPlatform: 'frontend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'InsuranceSimpleFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'admin',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'InsuranceSimpleFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'backend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'InsuranceSimpleMembershipPlugin',
        importFrom: 'shared'
      }, {
        forPlatform: 'frontend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PrivateInsuranceSimpleFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'admin',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PrivateInsuranceSimpleFrontendMembershipPlugin',
        importFrom: 'frontend-shared'
      }, {
        forPlatform: 'backend',
        token: MEMBERSHIP_PLUGINS_TOKEN,
        providerName: 'PrivateInsuranceSimpleMembershipPlugin',
        importFrom: 'shared'
      }]
    };
  }
  getSharedDefinition() {
    return {
      routerLinkResolvers: {
        frontend: {}
      }
    };
  }
}