import { ProtocolDistributor } from './protocol-distributor';
export class ProtocolBaseService {
  constructor(registry) {
    this.registry = registry;
  }
  get(name) {
    return this.registry.get(name);
  }
  create(info) {
    const loggers = [...this.registry.getDefaultLoggers(), ...(info.loggers || [])];
    const ctor = info.ctor || ProtocolDistributor;
    const protocol = new ctor(this.registry, info.name, loggers);
    if (info.displayName) protocol.setDisplayName(info.displayName);
    return protocol;
  }
  addDefaultLogger(Ctor) {
    this.registry.registerDefaultLogger(Ctor);
  }
}