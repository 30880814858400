import { extendsConstructor, isConstructor } from '../utils';
import { getBaseModelFromEditorModel } from './editor-definition';
import { getModelId } from './model-decorators';
import { Model } from './model/model.class';
/**
 * we allow models to be identified by constructor or constructor name.
 * To make it easier to identify them within core logic, passed in ModelIdInputs will always be converted to string-based ID (ModelId).
 *
 * one important thing to keep in mind about models:
 * they are not loaded eagerly. Most models are used by async chunks and will be fetched on demand.
 * So any model addon has to either work name-based or by first importing that model so that it is known.
 * (which would happen automatically as soon as it is imported to associate any extra data with it.)
 */
export var ModelHelper;
(function (ModelHelper) {
  /**
   * finds item model from an "unknown" source which may be an entity or editor model.
   */
  function getBaseModel(instanceOrCtor) {
    let ctor = isConstructor(instanceOrCtor) ? instanceOrCtor : instanceOrCtor.constructor;
    if (!extendsConstructor(ctor, Model, false)) throw new Error('Given constructor is no Model: ' + ctor);
    // solve main model from editor model
    ctor = getBaseModelFromEditorModel(ctor);
    // maybe we have an entity. As we are in shared package, we cannot check for typeorm Metadata.
    // We must use a trick instead - we know that ModelId decorator is mandatory for models, but does not exist on entity.
    // in special cases like VirtualPropertyEntity, there can even be entity child classes!
    const parentCtor = findParentWithModelId(ctor);
    if (!parentCtor) throw new Error('getItemModel failed. Ctor has no parent with modelId: ' + ctor);
    ctor = parentCtor;
    // now, ctor should be the ItemModel ctor. Validate...
    try {
      getModelId(ctor);
    } catch (err) {
      throw new Error('getItemModel failed. (Could not find a base class with @ModelId) ' + ctor);
    }
    return ctor;
  }
  ModelHelper.getBaseModel = getBaseModel;
  function findParentWithModelId(ctor, depth = 0) {
    try {
      getModelId(ctor);
      return ctor;
    } catch (err) {
      const parentCtor = Object.getPrototypeOf(ctor.prototype).constructor;
      if (!extendsConstructor(parentCtor, Model, false)) return false;
      if (depth === 5) throw new Error('findParentWithModelId: Depth limit exceeded.');
      return findParentWithModelId(parentCtor, depth + 1);
    }
  }
  ModelHelper.findParentWithModelId = findParentWithModelId;
  function getBaseModelName(instanceOrCtor) {
    const itemModelCtor = getBaseModel(instanceOrCtor);
    return getModelId(itemModelCtor);
  }
  ModelHelper.getBaseModelName = getBaseModelName;
})(ModelHelper || (ModelHelper = {}));