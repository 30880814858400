import { useContainer } from 'class-validator';
/**
 * NOTE: class-validator will use the container for ALL constraints, not just custom ones.
 * The fallback options are required to ensure fallback to internal container in case ng/nest container fails.
 */
export function connectClassValidatorContainer(injector, isNestJs = false) {
  useContainer({
    get: what => {
      if (isNestJs) return injector.get(what, {
        strict: false
      });else return injector.get(what);
    }
  }, {
    fallback: true,
    fallbackOnErrors: true
  });
}