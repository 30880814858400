import { DynamicFieldInstruction, applyOptionsDynamicDirectives, optionsInputDefaults, optionsInputMap } from './shared';
export class InputDropdownInstruction extends DynamicFieldInstruction {
  getDefaults() {
    return {
      ...optionsInputDefaults,
      showClear: true,
      dropdownPlaceholder: undefined
    };
  }
  constructor(config) {
    super();
    /** object map of input name -> source name / function */
    this.inputMap = {
      ...optionsInputMap,
      showClear: 'showClear',
      placeholder: 'dropdownPlaceholder'
    };
    this.attributeMap = {};
    this.setConfig(config);
    this.addDirectivesPlugin(applyOptionsDynamicDirectives);
  }
  load() {
    return import( /* webpackChunkName: "ui-input-dropdown" */
    /* webpackMode: "lazy" */
    '@common/ui/frontend-shared/input-dropdown').then(bundle => {
      return {
        component: bundle.InputDropdown,
        module: bundle.UiInputDropdownModule
      };
    });
  }
}