export const insurances = [
// { name: 'Test-KK', id: 'test-kk', supported: true },
// { name: 'Test-X', supported: true, id: 'test-x' },
{
  name: 'Barmer',
  supported: true,
  id: 'barmer'
}, {
  name: 'DAK Gesundheit',
  supported: true,
  id: 'dak-gesundheit'
}, {
  name: 'HEK Hanseatische Krankenkasse',
  supported: true,
  id: 'hek-hanseatische-krankenkasse'
}, {
  name: 'hkk Krankenkasse',
  supported: true,
  id: 'hkk-krankenkasse'
}, {
  name: 'KKH Kaufmännische Krankenkasse',
  supported: true,
  id: 'kkh-kaufmannische-krankenkasse'
}, {
  name: 'Knappschaft',
  supported: true,
  id: 'knappschaft'
}, {
  name: 'TK Techniker Krankenkasse',
  supported: true,
  id: 'tk-techniker-krankenkasse'
}, {
  name: 'BIG direkt gesund',
  supported: true,
  id: 'big-direkt-gesund'
}, {
  name: 'IKK Die Innovationskasse',
  supported: true,
  id: 'ikk-die-innovationskasse'
}, {
  name: 'IKK Brandenburg und Berlin',
  supported: true,
  id: 'ikk-brandenburg-und-berlin'
}, {
  name: 'IKK classic',
  supported: true,
  id: 'ikk-classic'
}, {
  name: 'IKK gesund plus',
  supported: true,
  id: 'ikk-gesund-plus'
}, {
  name: 'IKK Südwest',
  supported: true,
  id: 'ikk-sudwest'
}, {
  name: 'AOK Baden-Württemberg',
  supported: true,
  id: 'aok-baden-wurttemberg'
}, {
  name: 'AOK Bayern',
  supported: true,
  id: 'aok-bayern'
}, {
  name: 'AOK Bremen/Bremerhaven',
  supported: true,
  id: 'aok-bremen-bremerhaven'
}, {
  name: 'AOK Hessen',
  supported: true,
  id: 'aok-hessen'
}, {
  name: 'AOK Niedersachsen',
  supported: true,
  id: 'aok-niedersachsen'
}, {
  name: 'AOK Nordost',
  supported: true,
  id: 'aok-nordost'
}, {
  name: 'AOK Nordwest',
  supported: true,
  id: 'aok-nordwest'
}, {
  name: 'AOK PLUS',
  supported: true,
  id: 'aok-plus'
}, {
  name: 'AOK Rheinland-Pfalz/Saarland',
  supported: true,
  id: 'aok-rheinland-pfalz-saarland'
}, {
  name: 'AOK Rheinland/Hamburg',
  supported: true,
  id: 'aok-rheinland-hamburg'
}, {
  name: 'AOK Sachsen-Anhalt',
  supported: true,
  id: 'aok-sachsen-anhalt'
}, {
  name: 'Audi BKK',
  supported: true,
  id: 'audi-bkk'
}, {
  name: 'BAHN BKK',
  supported: true,
  id: 'bahn-bkk'
}, {
  name: 'Bergische Krankenkasse',
  supported: true,
  id: 'bergische-krankenkasse'
}, {
  name: 'Bertelsmann BKK',
  supported: true,
  id: 'bertelsmann-bkk'
}, {
  name: 'BKK Akzo Nobel Bayern',
  supported: true,
  id: 'bkk-akzo-nobel-bayern'
}, {
  name: 'BKK Diakonie',
  supported: true,
  id: 'bkk-diakonie'
}, {
  name: 'BKK DürkoppAdler',
  supported: true,
  id: 'bkk-durkoppadler'
}, {
  name: 'BKK Euregio',
  supported: true,
  id: 'bkk-euregio'
}, {
  name: 'BKK exklusiv',
  supported: true,
  id: 'bkk-exklusiv'
}, {
  name: 'BKK Faber-Castell & Partner',
  supported: true,
  id: 'bkk-faber-castell-and-partner'
}, {
  name: 'BKK firmus',
  supported: true,
  id: 'bkk-firmus'
}, {
  name: 'BKK Freudenberg',
  supported: true,
  id: 'bkk-freudenberg'
}, {
  name: 'BKK Gildemeister Seidensticker',
  supported: true,
  id: 'bkk-gildemeister-seidensticker'
}, {
  name: 'BKK Herkules',
  supported: true,
  id: 'bkk-herkules'
}, {
  name: 'BKK Linde',
  supported: true,
  id: 'bkk-linde'
}, {
  name: 'BKK melitta hmr',
  supported: true,
  id: 'bkk-melitta-hmr'
}, {
  name: 'BKK Pfaff',
  supported: true,
  id: 'bkk-pfaff'
}, {
  name: 'BKK Pfalz',
  supported: true,
  id: 'bkk-pfalz'
}, {
  name: 'BKK ProVita',
  supported: true,
  id: 'bkk-provita'
}, {
  name: 'BKK Public',
  supported: true,
  id: 'bkk-public'
}, {
  name: 'BKK SBH',
  supported: true,
  id: 'bkk-sbh'
}, {
  name: 'BKK Scheufelen',
  supported: true,
  id: 'bkk-scheufelen'
}, {
  name: 'BKK Technoform',
  supported: true,
  id: 'bkk-technoform'
}, {
  name: 'BKK Textilgruppe Hof',
  supported: true,
  id: 'bkk-textilgruppe-hof'
}, {
  name: 'BKK VBU',
  supported: true,
  id: 'bkk-vbu'
}, {
  name: 'BKK VDN',
  supported: true,
  id: 'bkk-vdn'
}, {
  name: 'BKK VerbundPlus',
  supported: true,
  id: 'bkk-verbundplus'
}, {
  name: 'BKK Werra-Meissner',
  supported: true,
  id: 'bkk-werra-meissner'
}, {
  name: 'BKK Wirtschaft & Finanzen',
  supported: true,
  id: 'bkk-wirtschaft-and-finanzen'
}, {
  name: 'BKK ZF & Partner',
  supported: true,
  id: 'bkk-zf-and-partner'
}, {
  name: 'BKK24',
  supported: true,
  id: 'bkk24'
}, {
  name: 'Bosch BKK',
  supported: true,
  id: 'bosch-bkk'
}, {
  name: 'Continentale BKK',
  supported: true,
  id: 'continentale-bkk'
}, {
  name: 'Debeka BKK',
  supported: true,
  id: 'debeka-bkk'
}, {
  name: 'energie BKK',
  supported: true,
  id: 'energie-bkk'
}, {
  name: 'Heimat Krankenkasse',
  supported: true,
  id: 'heimat-krankenkasse'
}, {
  name: 'mhplus Krankenkasse',
  supported: true,
  id: 'mhplus-krankenkasse'
}, {
  name: 'Mobil Krankenkasse',
  supported: true,
  id: 'mobil-krankenkasse'
}, {
  name: 'Novitas BKK',
  supported: true,
  id: 'novitas-bkk'
}, {
  name: 'Pronova BKK',
  supported: true,
  id: 'pronova-bkk'
}, {
  name: 'R+V Betriebskrankenkasse',
  supported: true,
  id: 'rv-betriebskrankenkasse'
}, {
  name: 'Salus BKK',
  supported: true,
  id: 'salus-bkk'
}, {
  name: 'SBK',
  supported: true,
  id: 'sbk'
}, {
  name: 'Securvita Krankenkasse',
  supported: true,
  id: 'securvita-krankenkasse'
}, {
  name: 'SKD BKK',
  supported: true,
  id: 'skd-bkk'
}, {
  name: 'TUI BKK',
  supported: true,
  id: 'tui-bkk'
}, {
  name: 'Viactiv Krankenkasse',
  supported: true,
  id: 'viactiv-krankenkasse'
}, {
  name: 'vivida BKK',
  supported: true,
  id: 'vivida-bkk'
}, {
  name: 'WMF BKK',
  supported: true,
  id: 'wmf-bkk'
}, {
  name: 'Landwirtschaftliche Krankenkasse - LKK',
  supported: true,
  id: 'landwirtschaftliche-krankenkasse-lkk'
}, {
  name: 'BKK B. Braun Aesculap',
  supported: true,
  id: 'bkk-b-braun-aesculap'
}, {
  name: 'BKK BPW Bergische Achsen KG',
  supported: true,
  id: 'bkk-bpw-bergische-achsen-kg'
}, {
  name: 'BKK Deutsche Bank AG',
  supported: true,
  id: 'bkk-deutsche-bank-ag'
}, {
  name: 'BKK evm',
  supported: true,
  id: 'bkk-evm'
}, {
  name: 'BKK EWE',
  supported: true,
  id: 'bkk-ewe'
}, {
  name: 'BKK Groz-Beckert',
  supported: true,
  id: 'bkk-groz-beckert'
}, {
  name: 'BKK Karl Mayer',
  supported: true,
  id: 'bkk-karl-mayer'
}, {
  name: 'BKK Mahle',
  supported: true,
  id: 'bkk-mahle'
}, {
  name: 'BKK Merck',
  supported: true,
  id: 'bkk-merck'
}, {
  name: 'BKK Miele',
  supported: true,
  id: 'bkk-miele'
}, {
  name: 'BKK MTU',
  supported: true,
  id: 'bkk-mtu'
}, {
  name: 'BKK PwC',
  supported: true,
  id: 'bkk-pwc'
}, {
  name: 'BKK Rieker Ricosta Weisser',
  supported: true,
  id: 'bkk-rieker-ricosta-weisser'
}, {
  name: 'BKK Salzgitter',
  supported: true,
  id: 'bkk-salzgitter'
}, {
  name: 'BKK Voralb Heller Index Leuze',
  supported: true,
  id: 'bkk-voralb-heller-index-leuze'
}, {
  name: 'BKK Würth',
  supported: true,
  id: 'bkk-wurth'
}, {
  name: 'BMW BKK',
  supported: true,
  id: 'bmw-bkk'
}, {
  name: 'Ernst & Young BKK',
  supported: true,
  id: 'ernst-and-young-bkk'
}, {
  name: 'Koenig & Bauer BKK',
  supported: true,
  id: 'koenig-and-bauer-bkk'
}, {
  name: 'Krones BKK',
  supported: true,
  id: 'krones-bkk'
}, {
  name: 'Mercedes-Benz BKK',
  supported: true,
  id: 'mercedes-benz-bkk'
}, {
  name: 'Südzucker BKK',
  supported: true,
  id: 'sudzucker-bkk'
},
// following insurances were marked as fusioned but not mentioned in the full list.
{
  name: 'actimonda',
  supported: true,
  id: 'actimonda'
}, {
  name: 'Metzinger BKK',
  supported: true,
  id: 'metzinger-bkk'
}, {
  name: 'BKK Mobil Oil',
  supported: true,
  id: 'bkk-mobil-oil'
}, {
  name: 'mhplus',
  supported: true,
  id: 'mhplus'
}];