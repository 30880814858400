/**
 * WARNING: Class names may be obfuscated in production builds.
 * Use @ClassName decorator to define a custom name that can be fetched in production using getClassName.
 * For models, the class name will be set via @ModelId decorator.
 */
const nameStorage = new Map();
export function ClassName(name) {
  return function (cls) {
    nameStorage.set(cls, name);
  };
}
export function getClassName(ctorOrInstance, metaOnly = false) {
  const ctor = typeof ctorOrInstance === 'function' ? ctorOrInstance : ctorOrInstance?.constructor;
  if (nameStorage.has(ctor)) return nameStorage.get(ctor);
  if (metaOnly) return undefined;
  return ctor?.name || undefined;
}