import { BehaviorSubject, map } from 'rxjs';
import { FeaturePlugin } from './feature-plugin.class';
import { checkForRequiredScope } from './feature.utils';
import { FeatureType } from './interfaces';
import { roleCheck } from '../access';
export class FeatureStatusService {
  constructor() {
    this.featureStatusMap$ = new BehaviorSubject(new Map());
  }
  get(info) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    if (this.featureStatusMap$.getValue().has(pluginInfo.name)) {
      return this.featureStatusMap$.getValue().get(pluginInfo.name);
    } else {
      // default FeatureStatus values
      return this.getDefaultStatus(info);
    }
  }
  get$(info) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    // TODO: introduce change detection filter?
    return this.featureStatusMap$.pipe(map(statusMap => {
      let status = statusMap.get(pluginInfo.name);
      if (!status) status = this.getDefaultStatus(pluginInfo);
      return status;
    }));
  }
  getAll() {
    return this.featureStatusMap$.getValue();
  }
  getAll$() {
    return this.featureStatusMap$.asObservable();
  }
  getDefaultStatus(info) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    return {
      enabled: pluginInfo.type === FeatureType.Permanent ? true : false,
      loaded: false,
      canActivate: false,
      activated: false
    };
  }
  // called by FeatureManagerService + locally
  updateFeatureStatus(info, patch) {
    const pluginInfo = info instanceof FeaturePlugin ? info.__info : info;
    const prev = this.get(pluginInfo);
    const newStatus = {
      ...prev,
      ...patch
    };
    const statusMap = this.featureStatusMap$.getValue();
    statusMap.set(pluginInfo.name, newStatus);
    this.featureStatusMap$.next(statusMap);
    return newStatus;
  }
  // called by FeatureManagerService
  calculateFeatureStatus(pluginInfo, featureAccessInfo, activatedOptInFeatures) {
    const newStatus = {};
    if (pluginInfo.type === FeatureType.Permanent) {
      newStatus.enabled = true;
    } else if (pluginInfo.type === FeatureType.Dynamic) {
      newStatus.enabled = (!pluginInfo.requiredScopes || checkForRequiredScope(pluginInfo, featureAccessInfo.currentScope)) && (!pluginInfo.requiredRole || roleCheck(pluginInfo.requiredRole, featureAccessInfo.role));
    } else if (pluginInfo.type === FeatureType.OptIn) {
      newStatus.canActivate = !pluginInfo.requiredRole || roleCheck(pluginInfo.requiredRole, featureAccessInfo.role);
      newStatus.activated = activatedOptInFeatures.includes(pluginInfo.name);
      newStatus.enabled = newStatus.canActivate && newStatus.activated;
    }
    return newStatus;
  }
  calculateFullStatus(pluginInfo, featureAccessInfo, activatedOptInFeatures) {
    const oldStatus = this.get(pluginInfo);
    const newStatus = {
      ...oldStatus,
      ...this.calculateFeatureStatus(pluginInfo, featureAccessInfo, activatedOptInFeatures)
    };
    return newStatus;
  }
  // called by FeatureRegistry
  setNewStatus(feature, featureAccessInfo, activatedOptInFeatures) {
    const newStatus = this.calculateFullStatus(feature.__info, featureAccessInfo, activatedOptInFeatures);
    this.updateFeatureStatus(feature, newStatus);
    return newStatus;
  }
}