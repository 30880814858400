import { DomHandler } from 'primeng/dom';
// TODO: this code does not respect offsetParents, the calculated position requires the element to be appended to body.
// Replace this by a more solid library? 
// https://github.com/elmsln/lrnwebcomponents/blob/master/elements/absolute-position-behavior/lib/absolute-position-state-manager.js
// https://github.com/shipshapecode/tether#readme
function justifyRight(element, target) {
  const elementDimensions = element.offsetParent ? {
    width: element.offsetWidth,
    height: element.offsetHeight
  } : DomHandler.getHiddenElementDimensions(element);
  const elementOuterWidth = elementDimensions.width;
  const targetOuterWidth = target.offsetWidth;
  const targetOffset = target.getBoundingClientRect();
  let top, left;
  let calculatedPosition;
  if (targetOffset.left + targetOuterWidth - elementOuterWidth < 0) {
    // part of element would be out of viewport on left side!
    left = 0;
    calculatedPosition = 'left';
  } else {
    // position on right side of target
    left = targetOffset.left + targetOffset.width - elementOuterWidth;
    calculatedPosition = 'right';
  }
  return {
    top,
    left,
    calculatedPosition,
    calculatedAlignment: 'end'
  };
}
// function justifyLeft(element: any, target: any):CalculatedOffsetInfo {
// 	let elementDimensions = element.offsetParent ? { width: element.offsetWidth, height: element.offsetHeight } : DomHandler.getHiddenElementDimensions(element);
// 	let elementOuterWidth = elementDimensions.width;
// 	let windowScrollLeft = DomHandler.getWindowScrollLeft();
// 	let targetOffset = target.getBoundingClientRect();
// 	let viewport = DomHandler.getViewport();
// 	let left;
// 	let calculatedPosition:'right'|'top'|'bottom'|'left';
// 	if (targetOffset.left + elementOuterWidth > viewport.width)
// 		// if elements right edge is outside of viewport, move it to the left as much as needed
// 		return justifyRight(element,target)
// 	else
// 		// position justified to the left edge of target
// 		left = targetOffset.left + windowScrollLeft;
// 	return {top:null, left, calculatedPosition, calculatedAlignment:'start'}
// }
// function justifyCenter(element: any, target: any):CalculatedOffsetInfo {
// 	let elementDimensions = element.offsetParent ? { width: element.offsetWidth, height: element.offsetHeight } : DomHandler.getHiddenElementDimensions(element);
// 	let elementOuterWidth = elementDimensions.width;
// 	let windowScrollLeft = DomHandler.getWindowScrollLeft();
// 	let targetOffset = target.getBoundingClientRect();
// 	let viewport = DomHandler.getViewport();
// 	let left;
// 	let calculatedPosition:'right'|'top'|'bottom'|'left';
// 	const startPosition = targetOffset.left + (targetOffset.width / 2) - (elementOuterWidth / 2);
// 	console.log('targetOffset',targetOffset,'elementOuterWidth',elementOuterWidth,'startPosition',startPosition)
// 	if (startPosition + elementOuterWidth > viewport.width)
// 		// element is out of screen on the right, justify right
// 		return justifyRight(element,target)
// 	else if (startPosition < 0)
// 		// element is out of screen on the left, justify left
// 		return justifyLeft(element,target)
// 	else
// 		// position justified to the left edge of target
// 		left = startPosition;
// 	return {top:null, left, calculatedPosition, calculatedAlignment:'center'}
// }
function positionTop(element, target) {
  const elementDimensions = element.offsetParent ? {
    width: element.offsetWidth,
    height: element.offsetHeight
  } : DomHandler.getHiddenElementDimensions(element);
  const elementOuterHeight = elementDimensions.height;
  const targetOffset = target.getBoundingClientRect();
  const windowScrollTop = DomHandler.getWindowScrollTop();
  const calculatedPosition = 'top';
  // NOTE: when preferredPosition==='top', no check will be done if the element is in viewport actually.
  // it will always be positioned to top. Improve as needed.
  const top = targetOffset.top + windowScrollTop - elementOuterHeight;
  element.style.transformOrigin = 'bottom';
  calculatedPosition;
  return {
    top,
    left: null,
    calculatedPosition
  };
}
function positionBottom(element, target) {
  const elementDimensions = element.offsetParent ? {
    width: element.offsetWidth,
    height: element.offsetHeight
  } : DomHandler.getHiddenElementDimensions(element);
  const elementOuterHeight = elementDimensions.height;
  const targetOuterHeight = target.offsetHeight;
  const targetOffset = target.getBoundingClientRect();
  const windowScrollTop = DomHandler.getWindowScrollTop();
  const calculatedPosition = 'bottom';
  const viewport = DomHandler.getViewport();
  // check if element does not fit below target
  if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) return positionTop(element, target);
  // position below target
  const top = targetOuterHeight + targetOffset.top + windowScrollTop;
  element.style.transformOrigin = 'top';
  calculatedPosition;
  return {
    top,
    left: null,
    calculatedPosition
  };
}
// export function calculateAbsolutePosition(element: any, target: any, margin=0, 
// 	preferredPosition:'alignRight'|'right'|'top'|'bottom'='bottom',
// 	preferredAlignment:'start'|'end'|'center'='start'
// ): CalculatedOffsetInfo {
// 	let windowScrollTop = DomHandler.getWindowScrollTop();
// 	let windowScrollLeft = DomHandler.getWindowScrollLeft();
// 	let info:CalculatedOffsetInfo;
// 	let alignment:CalculatedOffsetInfo;
// 	if(preferredPosition==='right') {
// 		// position left or right of target, justify vertically
// 		throw new Error('preferredPosition=right is not implemented yet')
// 	} else {
// 		// position above or below target , justify l/r
// 		if (preferredPosition==='top') {
// 			info = positionTop(element,target)
// 		} else {
// 			info = positionBottom(element,target)
// 		}
// 		if(preferredAlignment==='start') {
// 			alignment = justifyLeft(element,target)
// 		} else if(preferredAlignment==='center') {
// 			alignment = justifyCenter(element,target)
// 		} else {
// 			alignment = justifyRight(element,target)
// 		}
// 		info.left = alignment.left;
// 		info.calculatedAlignment = alignment.calculatedAlignment;
// 	}
// 	// TODO: unclear why this is hlepful
// 	if (info.top < 0) info.top = windowScrollTop;
// 	if (info.left < 0) info.left = windowScrollLeft;
// 	switch(info.calculatedPosition) {
// 		case 'bottom': info.top += margin; break;
// 		case 'top': info.top -= margin; break;
// 		case 'right': info.left += margin; break;
// 		case 'left': info.left -= margin; break;
// 	}
// 	return info
// }
export function absolutePosition__alignRight(element, target, margin = 0) {
  // const info = calculateAbsolutePosition(element,target,horzOffset,'alignRight')
  // let windowScrollTop = DomHandler.getWindowScrollTop();
  // let windowScrollLeft = DomHandler.getWindowScrollLeft();
  const info = positionBottom(element, target);
  const alignment = justifyRight(element, target);
  // if (preferredPosition==='top') {
  // 	info = positionTop(element,target)
  // } else {
  // info = 
  // }
  // if(preferredAlignment==='start') {
  // 	alignment = justifyLeft(element,target)
  // } else if(preferredAlignment==='center') {
  // 	alignment = justifyCenter(element,target)
  // } else {
  // alignment 
  // }
  info.left = alignment.left;
  info.calculatedAlignment = alignment.calculatedAlignment;
  switch (info.calculatedPosition) {
    case 'bottom':
      info.top += margin;
      break;
    case 'top':
      info.top -= margin;
      break;
    case 'right':
      info.left += margin;
      break;
    case 'left':
      info.left -= margin;
      break;
  }
  element.style.top = info.top + 'px';
  element.style.left = info.left + 'px';
}