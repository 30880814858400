import { InputOptionsDynamic } from '@common/ui/frontend-shared/input-options-dynamic';
import { InputOptionsGroupedBy } from '@common/ui/frontend-shared/input-options-grouped-by/input-options-grouped-by';
import { isConstructor } from '@core/shared/utils';
/**
 * Angular directives are composed into components based on how they are assigned in templates.
 * Other official solution is hostDirectives which will be hard-wired to a component in general.
 * ng-dynamic-component has experimental support for runtime-assignment of directives:
 * https://github.com/gund/ng-dynamic-component#directives-experimental
 * (which is only possible because directives are not much more than a simple class.)
 * (however lifecycle events can be tricky and are best avoided).
 * As we only need to create 2 well-known, simple directives on demand, we can simply instantiate them right here.
 */
export function applyOptionsDynamicDirectives(component, injector, config, initialFormValue) {
  let optsGroupedByRef;
  const {
    optionsGroupedBy,
    optionsDynamic,
    optionValue
  } = config;
  if (optionsGroupedBy && !optionsDynamic) {
    throw new Error('applyOptionsDynamicDirectives currently does not support use of optionsGroupedBy without optionsDynamic!');
  }
  // must be created first as it needs to be passed as optionsDynamic ctor arg!
  if (optionsGroupedBy) {
    optsGroupedByRef = new InputOptionsGroupedBy(component.instance);
    optsGroupedByRef.config = optionsGroupedBy;
  }
  if (optionsDynamic) {
    const optionsDynamicIsFactoryFn = typeof optionsDynamic === 'function' && !isConstructor(optionsDynamic);
    let solvedOptionsDynamic;
    if (optionsDynamicIsFactoryFn) {
      solvedOptionsDynamic = optionsDynamic(initialFormValue);
      // factories are allowed to return null indicating that the current data misses information for setting up optionsDynamic correctly.
      // example: endpoint requires companyId, but edited item is not / not yet assigned to a company.
      if (!solvedOptionsDynamic) return;
    } else {
      solvedOptionsDynamic = optionsDynamic;
    }
    const optsDynamic = new InputOptionsDynamic(injector, component.instance, optsGroupedByRef);
    optsDynamic.input = solvedOptionsDynamic;
    optsDynamic.optionValue = optionValue;
    if (optsGroupedByRef) {
      optsGroupedByRef.input = solvedOptionsDynamic;
    }
    optsDynamic.ngOnInit();
  }
}