export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}
export function isWindow(obj) {
  return isObject(obj) && obj === obj.window;
}
export function nodeType(obj) {
  return !isWindow(obj) && isObject(obj) && obj.nodeType;
}
export function isDocument(obj) {
  return nodeType(obj) === 9;
}
export function isNode(obj) {
  return nodeType(obj) >= 1;
}
export function isElement(obj) {
  return nodeType(obj) === 1;
}
export function toNode(element) {
  return toNodes(element)[0];
}
export function toNodes(element) {
  if (!element) return [];
  return isNode(element) ? [element] : Array.from(element).filter(isNode);
}
export function toWindow(element) {
  if (isWindow(element)) return element;
  element = toNode(element);
  return element ? (isDocument(element) ? element : element.ownerDocument).defaultView : window;
}
export function getStyle(element, property, pseudoElt) {
  return getStyles(element, pseudoElt)[property];
}
export function getStyles(element, pseudoElt) {
  return toWindow(element).getComputedStyle(element, pseudoElt);
}