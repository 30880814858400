import { Subject, filter, map, startWith } from 'rxjs';
/**
 *  customized metadata storage based on the metadataStorage from class-transformer.
 */
export class CustomMetadataStorage {
  constructor() {
    // -------------------------------------------------------------------------
    // Properties
    // -------------------------------------------------------------------------
    this._customMetadatas = new Map();
    this._ancestorsMap = new Map();
    this._itemModels = [];
    this.addedMetadata$ = new Subject();
  }
  // -------------------------------------------------------------------------
  // Adder Methods
  // -------------------------------------------------------------------------
  addMetadata(metadata) {
    // important: for class-level metadata, propertyName MUST be null, NOT undefined!
    // if it was omitted, correct it now.
    if (metadata.propertyName === undefined) metadata.propertyName = null;
    if (!this._customMetadatas.has(metadata.target)) {
      this._customMetadatas.set(metadata.target, new Map());
    }
    if (!this._customMetadatas.get(metadata.target).has(metadata.propertyName)) {
      this._customMetadatas.get(metadata.target).set(metadata.propertyName, []);
    }
    this._customMetadatas.get(metadata.target).get(metadata.propertyName).push(metadata);
    this.addedMetadata$.next(metadata);
  }
  // -------------------------------------------------------------------------
  // Public Methods
  // -------------------------------------------------------------------------
  // called by @ItemModelDefinition
  registerItemModel(target) {
    this._itemModels.push(target);
  }
  getItemModels() {
    return [...this._itemModels];
  }
  findMetadata(target, propertyName = null) {
    return this.findMetadatas(this._customMetadatas, target, propertyName);
  }
  findMetadataOfType(type, target, propertyName = null) {
    return this.findMetadatasOfType(this._customMetadatas, type, target, propertyName);
  }
  // note that this will only find class-level meta! property-level metadata will be ignored!
  findModelsByMetadataType(type) {
    const matches = [];
    this._customMetadatas.forEach((value, modelCtor) => {
      const metaOfType = this.findMetadatasOfType(this._customMetadatas, type, modelCtor);
      if (metaOfType.length) {
        matches.push({
          modelCtor,
          metadata: metaOfType
        });
      }
    });
    return matches;
  }
  // async version of findModelsByMetadataType, will emit newly found models if registered later.
  findModelsByMetadataType$(type) {
    const syncMatches = this.findModelsByMetadataType(type);
    return this.addedMetadata$.pipe(filter(meta => meta.metaType === type), map(meta => {
      return [{
        modelCtor: meta.target,
        metadata: [meta]
      }];
    }), startWith(syncMatches));
  }
  getAllMetadata(target) {
    return this.collectAllMetadata(this._customMetadatas, target);
  }
  clear() {
    this._customMetadatas.clear();
    this._ancestorsMap.clear();
  }
  // -------------------------------------------------------------------------
  // Private Methods
  // -------------------------------------------------------------------------
  /**
   * Get all metadata found for whole class
   */
  collectAllMetadata(metadatas, target) {
    const metadataFromTargetMap = metadatas.get(target);
    let metadataFromTarget;
    if (metadataFromTargetMap) {
      metadataFromTarget = Array.from(metadataFromTargetMap.values());
    }
    const metadataFromAncestors = [];
    for (const ancestor of this.getAncestors(target)) {
      const ancestorMetadataMap = metadatas.get(ancestor);
      if (ancestorMetadataMap) {
        const metadataFromAncestor = Array.from(ancestorMetadataMap.values());
        metadataFromAncestors.push(...metadataFromAncestor);
      }
    }
    // original function did check every meta entry for propertyName being defined to strip other types of meta?
    return metadataFromAncestors.concat(metadataFromTarget || []);
  }
  collectAllMetadataFlat(metadatas, target) {
    const deep = this.collectAllMetadata(metadatas, target);
    return deep.reduce((joined, entry) => {
      return joined.concat(...entry);
    }, []);
  }
  /**
   * Get all metadata found for a single property .
   * If property is omitted, class-level metadata will be fetched
   */
  findMetadatas(metadatas, target, propertyName = null) {
    const metadataFromTargetMap = metadatas.get(target);
    let metadataFromTarget;
    if (metadataFromTargetMap) {
      metadataFromTarget = metadataFromTargetMap.get(propertyName);
    }
    const metadataFromAncestorsTarget = [];
    for (const ancestor of this.getAncestors(target)) {
      const ancestorMetadataMap = metadatas.get(ancestor);
      if (ancestorMetadataMap) {
        if (ancestorMetadataMap.has(propertyName)) {
          metadataFromAncestorsTarget.push(...ancestorMetadataMap.get(propertyName));
        }
      }
    }
    return metadataFromAncestorsTarget.slice().reverse().concat((metadataFromTarget || []).slice().reverse());
  }
  /**
   * Get all metadata of a certain type for a single property.
   * propertyName=null => class-level metadata will be fetched
   * propertyName=string => prop-level metadata will be fetched
   * propertyName=undefined => all metadata will be fetched
   */
  findMetadatasOfType(metadatas, metaType, target, propertyName = undefined) {
    if (typeof propertyName !== 'undefined') {
      const meta = this.findMetadatas(metadatas, target, propertyName);
      return meta.filter(entry => entry.metaType === metaType);
    } else {
      const meta = this.collectAllMetadataFlat(metadatas, target);
      return meta.filter(entry => entry.metaType === metaType);
    }
  }
  getAncestors(target) {
    if (!target || !target.prototype) return [];
    if (!this._ancestorsMap.has(target)) {
      const ancestors = [];
      let baseClass = Object.getPrototypeOf(target.prototype.constructor);
      for (baseClass; typeof baseClass.prototype !== 'undefined'; baseClass = Object.getPrototypeOf(baseClass.prototype.constructor)) {
        ancestors.push(baseClass);
      }
      this._ancestorsMap.set(target, ancestors);
    }
    return this._ancestorsMap.get(target);
  }
}