import { takeWhile } from 'rxjs/operators';
/**
 * From https://github.com/joostme/takeWhileAlive
 * As package is not maintained and requires old peer dependencies, this is a local implementation.
 * Similar to newer https://github.com/ngneat/until-destroy
 *
 * note that this works only with angular! (uses ngOnDestroy hook)
 */
const isAlivePropName = '__isComponentAlive';
export function takeWhileAlive(component) {
  return takeWhile(() => component[isAlivePropName]);
}
export function AutoUnsubscribe() {
  return target => {
    const proto = target.prototype;
    const originalNgOnDestroy = proto.ngOnDestroy;
    proto[isAlivePropName] = true;
    target.prototype.ngOnDestroy = function () {
      if (originalNgOnDestroy) {
        originalNgOnDestroy.apply(this);
      }
      this[isAlivePropName] = false;
    };
  };
}
export function hasAutoUnsubscribeEnabled(component) {
  return typeof component[isAlivePropName] === 'boolean';
}