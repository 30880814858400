import { AllowEmpty, IsOptional, CrudAction } from '../validators';
import { DefaultDecorator_applyValue } from './default.decorator';
import { ClassTransformService } from '../class-transform';
const defaultOptions = {
  isOptionalCreate: false,
  isOptionalUpdate: false,
  allowEmptyString: true
};
/**
 * ItemModelProp joins several standard decorators in a manner that is required for almost any prop of ItemModels.
 * It allows to specify default values + optional validation for different editor modes (create|update)
 */
export function ItemModelProp(info = {}) {
  const config = {
    ...defaultOptions,
    ...info
  };
  return function ItemModelPropDecorator(target, propertyName) {
    const defaultMetadataStorage = ClassTransformService.getMetadataStorage();
    // Expose()(target,propertyName);
    if (typeof config.defaultValue !== 'undefined') {
      defaultMetadataStorage.addTransformMetadata({
        target: target instanceof Function ? target : target.constructor,
        propertyName,
        transformFn: ({
          value,
          executor
        }) => {
          return DefaultDecorator_applyValue(config.defaultValue, value, executor);
        },
        options: {}
      });
    }
    if (typeof config.defaultValueCreate !== 'undefined') {
      defaultMetadataStorage.addTransformMetadata({
        target: target instanceof Function ? target : target.constructor,
        propertyName,
        transformFn: ({
          value,
          executor
        }) => {
          return DefaultDecorator_applyValue(config.defaultValueCreate, value, executor);
        },
        options: {
          groups: [CrudAction.CREATE]
        }
      });
    }
    if (typeof config.defaultValueUpdate !== 'undefined') {
      defaultMetadataStorage.addTransformMetadata({
        target: target instanceof Function ? target : target.constructor,
        propertyName,
        transformFn: ({
          value,
          executor
        }) => {
          return DefaultDecorator_applyValue(config.defaultValueUpdate, value, executor);
        },
        options: {
          groups: [CrudAction.UPDATE]
        }
      });
    }
    if (config.isOptionalCreate) {
      const Decorator = config.allowEmptyString ? AllowEmpty : IsOptional;
      Decorator({
        groups: [CrudAction.CREATE]
      })(target, propertyName);
    }
    if (config.isOptionalUpdate) {
      const Decorator = config.allowEmptyString ? AllowEmpty : IsOptional;
      Decorator({
        groups: [CrudAction.UPDATE]
      })(target, propertyName);
    }
  };
}