import { buildMessage, ValidateBy } from "class-validator";
const IS_VALID_INSURANCE_ID = 'IS_VALID_INSURANCE_ID';
export function IsValidInsuranceMembershipId(validationOptions) {
  return ValidateBy({
    name: IS_VALID_INSURANCE_ID,
    // ID consists of one letter followed by 8 digits and one checksum digit.
    // https://de.wikipedia.org/wiki/Krankenversichertennummer
    // TODO: 
    // https://www.haufe.de/sozialwesen/sgb-office-professional/krankenversichertennummer-2-aufbau_idesk_PI434_HI3571881.html 
    // states this number is followed by another 9 digits identifying the insurance.
    // in case of family insurance, another 10 digits can be added.
    // checksum digit always comes last.
    constraints: [/^([A-Z]{1})([\d]{8})([\d]{1})$/, ''],
    validator: {
      validate: (value, args) => {
        // console.log(value,args)
        if (typeof value !== 'string') return false;
        const match = value.match(args.constraints[0]);
        if (match) {
          const [dummy, char, mainNumber, checksum] = match;
          const expectedChecksum = generateInsuranceMembershipIdChecksum(char, mainNumber);
          return expectedChecksum === parseInt(checksum, 10);
        } else {
          return false;
        }
      },
      defaultMessage: buildMessage((eachPrefix, args) => 'Dies ist keine gültige Krankenversichertennummer. Bitte prüfen Sie Ihre Eingabe.', validationOptions)
    }
  }, {
    message: 'Dies ist keine gültige Krankenversichertennummer. Bitte prüfen Sie Ihre Eingabe.',
    ...validationOptions
  });
}
export function generateInsuranceMembershipIdChecksum(char, mainNumber) {
  const cardNo = ('0' + (char.charCodeAt(0) - 64)).slice(-2) + mainNumber;
  let sum = 0;
  for (let i = 0; i < 10; i++) {
    let d = parseInt(cardNo[i], 10);
    if (i % 2 === 1) d *= 2;
    if (d > 9) d -= 9;
    sum += d;
  }
  return sum % 10;
}