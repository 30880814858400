import { CrudServiceFactory, AppRequestService } from '@core/frontend-shared/api';
import { from, Observable, of } from 'rxjs';
import { isAppRequest } from '@core/shared/app-request';
export class FormServiceSaveHandlerFunction {
  constructor(fs, injector, func) {
    this.fs = fs;
    this.injector = injector;
    this.func = func;
    this.returnsUpdatedFormData = false;
  }
  execute(value) {
    const result = this.func(value, this.fs);
    if (result instanceof Promise) return from(result);else if (result instanceof Observable) return result;else return of(value);
  }
}
export class FormServiceSaveHandlerApiRequest {
  constructor(fs, injector) {
    this.fs = fs;
    this.injector = injector;
    this.returnsUpdatedFormData = false;
  }
  execute(value) {
    if (Array.isArray(value)) throw new Error('FormServiceSaveHandlerApiRequest does not support saving arrays yet!');
    const service = this.injector.get(AppRequestService);
    const req = this.submitData(service, value);
    return req;
  }
  submitData(service, value) {
    if (isAppRequest(this.fs.modelFactory.Model)) {
      return service.sendRequest(this.fs.modelFactory.Model, value);
    } else {
      console.log('this model is no decorated Model or ItemModel. Cannot submit to API.', this.fs.modelFactory.Model);
      throw new Error('this model is no decorated Model or ItemModel. Cannot submit to API.');
    }
  }
}
export class FormServiceSaveHandlerApiItem {
  constructor(fs, injector) {
    this.fs = fs;
    this.injector = injector;
    this.returnsUpdatedFormData = true;
  }
  setService(service) {
    this.service = service;
  }
  execute(value) {
    if (Array.isArray(value)) throw new Error('FormServiceSaveHandlerApiItem does not support saving arrays yet!');
    const service = this.resolveService();
    const req = service.save(value);
    return req;
  }
  resolveService() {
    if (this.service) {
      return this.service;
    }
    const definitionOptions = this.fs.getEditorModelInfo().definition.options;
    if (!definitionOptions.useCrudEndpoint) {
      throw Error('FormServiceSaveHandlerApiItem error: formService Model is not configurated for submission to CrudEndpoint / by CrudService!');
    }
    const factory = this.injector.get(CrudServiceFactory);
    const service = factory.createCrudService(this.fs.modelFactory.Model);
    service.useCrudEndpoint(definitionOptions.useCrudEndpoint);
    return service;
  }
}