import { ClassName, getClassName } from '../../class-name';
import { extendsConstructor } from '../../utils';
import { Model } from '../model/model.class';
export function ModelId(id) {
  return function ModelIdDecorator(ItemClass) {
    if (!extendsConstructor(ItemClass, Model)) throw Error('All data models must extend Model base class!');
    ClassName(id)(ItemClass);
  };
}
export function getModelId(ctorOrString) {
  if (typeof ctorOrString === 'string') return ctorOrString;
  const name = getClassName(ctorOrString, true);
  if (name) return name;
  // NOTE: must not check for parent because with name obfuscation we can never know if this is a child model or not!
  throw new Error('No ModelId metadata found for model class ' + ctorOrString.name);
}