import { Router } from '@angular/router';
import { CustomRouter, CUSTOM_ROUTER_CONFIG } from './router.class';
/** thanks to Mido @ https://stackoverflow.com/questions/45514970/angular-2-override-router-navigate-method */
function flatten(arr) {
  return Array.prototype.concat.apply([], arr);
}
/**
 * This is a custom factory for only creating our custom router class.
 * Standard RouterModule.forRoot will still be used.
 * On top of that, routerProviderFactory is used to provide our CustomRouter instead of standard Router.
 * so it does not have that much to do with how the angular Router Module is implemented.
 * We just care about changes to the standard Router constructor args and make sure that the CustomRouter
 * is up to date and passes all required deps to the parent constructor.
 *
 * Since ng 14.2/15, router implementation was changed massively.
 * This is because Router can now be used without RouterModule.
 * https://github.com/angular/angular/blob/main/packages/router/src/provide_router.ts is entirely new.
 * Main PR Changes: https://github.com/angular/angular/pull/47010/files#diff-99eedd01338ea4afb9b0f1533729b9c06a95b92cc68b12b786d44ebadba8be03
 *
 * Based on config passed to forRoot, the module will cal provideXXX / withXXX functions defined in provide_router.
 * When using Router without module, these would be called directly.
 *
 * Router: https://github.com/angular/angular/blob/main/packages/router/src/router.ts
 * -- With ng15, router has no ctor dependencies anymore.
 *
 *
 * RouterModule: https://github.com/angular/angular/blob/master/packages/router/src/router_module.ts
 * -- defines a ROUTER_PROVIDERS array that Router itself is part of.
 * -- With ng15, router has no factory anymore.
 *
 *
 * and search for "provide: Router" to see what the new implementation has to look like.
 * routerFactory replaces the factory function named setupRouter as of ng13.
 * ng14/15 changed setup:
 * There is a ROUTER_PROVIDERS constant and Router is a part of it.
 */
export function routerProviderFactory(config = {}) {
  return [{
    provide: CUSTOM_ROUTER_CONFIG,
    useValue: config
  }, {
    provide: CustomRouter,
    useClass: CustomRouter
  }, {
    provide: Router,
    useExisting: CustomRouter
  }];
}