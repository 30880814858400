import _asyncToGenerator from "/home/runner/work/mainrepo/mainrepo/node_modules/.pnpm/@babel+runtime@7.23.7/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
export function loadAndShowConsole(_x) {
  return _loadAndShowConsole.apply(this, arguments);
}
function _loadAndShowConsole() {
  _loadAndShowConsole = _asyncToGenerator(function* (dialogService) {
    return import('./console/console.component').then(mod => {
      dialogService.open(mod.ConsoleComponent, {
        header: 'Dev Console',
        width: '90%',
        height: '75%',
        contentStyle: {
          "overflow": "hidden",
          "padding": 0
        },
        baseZIndex: 10000,
        draggable: true,
        dismissableMask: false,
        invisibleMask: true,
        position: 'center',
        // maximizable: true,
        data: {}
      });
    });
  });
  return _loadAndShowConsole.apply(this, arguments);
}