import { ClassTransformService, EditorDefinition, ModelHelper, createChildModel } from '@core/shared/model';
import { VIRTUAL_PROPS_ACCESSOR } from '@core/shared/virtual-properties';
import { getMediaAssetsMetadataForModel, getModelsWithMediaAssetsMetadata, getModelsWithMediaAssetsMetadata$ } from './media-assets.decorator';
import { MediaAssetSlotsMapModel } from '../models/media-asset-slots-map';
import { Subject, takeUntil } from 'rxjs';
export class MediaAssetsRegistry {
  constructor() {
    this.modelCache = {};
    this.modelNamesToDebug = [];
    this.destroy$ = new Subject();
    getModelsWithMediaAssetsMetadata$().pipe(takeUntil(this.destroy$)).subscribe(matches => {
      matches.forEach(({
        modelCtor,
        metadata
      }) => {
        this.createSlotMapModel(metadata);
        this.assignMetadataToModelsWithMediaAssetsDecorator(metadata);
      });
    });
    // const modelNamesToDebugString = config.get('core.vprops.debug',false)
    // if(modelNamesToDebugString) {
    // 	this.modelNamesToDebug = modelNamesToDebugString.split(',')
    // 	console.log('VPROPS DEBUG: Debugging is enabled for: ',this.modelNamesToDebug)
    // }
  }
  createSlotMapModel(metadata) {
    const modelCtor = metadata[0].target;
    // this.getVirtualPropertyConfigurations(modelCtor)
    const className = ModelHelper.getBaseModelName(modelCtor);
    const MediaAssetMapModel = this.generateMediaMapModelFor(modelCtor);
    this.modelCache[className] = MediaAssetMapModel;
  }
  assignMetadataToModelsWithMediaAssetsDecorator(metadata) {
    // iterate all models that have MediaAsset metadata assigned
    metadata.forEach(meta => {
      const modelCtor = meta.target;
      const className = ModelHelper.getBaseModelName(modelCtor);
      const MediaMapModelCtor = this.modelCache[className];
      if (!MediaMapModelCtor) return;
      this.assignTypeMetadataToMediaAssetsProperty(modelCtor, MediaMapModelCtor, meta);
    });
  }
  // generateDebugData() {
  // 	const registeredClassNames = Object.keys(this.registry);
  // 	const info = registeredClassNames.map(className=>{
  // 		const meta = this.metaCache[className];
  // 		return {
  // 			className,
  // 			definitions:this.registry[className],
  // 			model:this.modelCache[className]
  // 		}
  // 	})
  // 	return info;
  // }
  modelHasVirtualPropertyMetadata(modelCtor) {
    return !!getMediaAssetsMetadataForModel(modelCtor);
  }
  getAllModelsUsingMediaAssets() {
    return getModelsWithMediaAssetsMetadata();
  }
  getMediaAssetMapModelFor(modelCtor) {
    const models = this.getModels();
    const className = ModelHelper.getBaseModelName(modelCtor);
    return models[className];
  }
  getModels() {
    return this.modelCache;
  }
  /**
   * create a transformable/validateable Model dynamically
   */
  generateMediaMapModelFor(modelCtor) {
    const MediaMapModel = createChildModel(MediaAssetSlotsMapModel, modelCtor.name + 'MediaAssetsSlotMap');
    const definition = getMediaAssetsMetadataForModel(modelCtor)[0];
    const metaStorage = ClassTransformService.getMetadataStorage();
    // const debug = this.shouldDebug(name);
    // if(debug) console.log('VPROPS DEBUG: Discovered VProps for Model '+name+':',definition);
    if (!IS_PRODUCTION) {
      if (!Array.isArray(definition.options.slotNames)) {
        throw new Error('Invalid MediaAssets configuration - no slotNames found: ' + JSON.stringify(definition));
      }
    }
    // definition.options.targetProperty
    definition.options.slotNames.forEach(slotName => {
      // class-transformer meta
      metaStorage.addExposeMetadata({
        target: MediaMapModel,
        propertyName: slotName,
        options: {}
      });
      // the nested AssetMappingItems must NOT be type-transformed?
      // metaStorage.addTypeMetadata({
      // 	target:MediaMapModel, propertyName:slotName, typeFunction:()=>MediaAssetMappingItem, reflectedType:null, options:{}
      // })
    });
    const propDefinitionMap = definition.options.slotNames.reduce((definitionMap, slotName) => {
      // definitionMap[slotName] = {loadDefinition:'default'};
      definitionMap[slotName] = true;
      return definitionMap;
    }, {});
    EditorDefinition(propDefinitionMap)(MediaMapModel);
    // console.log('generated MediaMapModel',definition,getAllClassTransformerMetadata(MediaMapModel))
    return MediaMapModel;
  }
  assignTypeMetadataToMediaAssetsProperty(ParentModelCtor, MediaMapModelCtor, meta) {
    // media assets metadata does NOT store prop name in meta.propertyName! Must use meta.options.targetProperty!
    const propertyName = meta.options.targetProperty || VIRTUAL_PROPS_ACCESSOR;
    ClassTransformService.getMetadataStorage().addTypeMetadata({
      target: ParentModelCtor,
      propertyName: propertyName,
      reflectedType: null,
      typeFunction: () => MediaMapModelCtor,
      options: {}
    });
  }
  shouldDebug(modelCtorOrName) {
    const className = typeof modelCtorOrName === 'string' ? modelCtorOrName : ModelHelper.getBaseModelName(modelCtorOrName);
    return this.modelNamesToDebug.includes(className);
  }
}