import { MediaAssetType } from '../interfaces';
import { MediaTransformationQuality } from './media-transformation.interface';
export const DEFAULT_MEDIA_TRANSFORM_PRESET_ID = 'default';
/** injectable in both platforms! */
export class MediaTransformationsRegistry {
  constructor() {
    this.transformations = {
      image: new Map(),
      video: new Map(),
      document: new Map(),
      raw: new Map()
    };
    // each preset consists of a list of transformation names 
    this.presets = {
      image: new Map(),
      video: new Map(),
      document: new Map(),
      raw: new Map()
    };
    this.registerTransformation(MediaAssetType.image, 'sd', {
      width: 480
    });
    this.registerTransformation(MediaAssetType.video, 'fullHd', {
      width: 1920,
      quality: MediaTransformationQuality.good
    });
    this.registerTransformation(MediaAssetType.video, 'hd', {
      width: 1280,
      quality: MediaTransformationQuality.good
    });
    this.registerTransformation(MediaAssetType.video, 'eco', {
      width: 768,
      quality: MediaTransformationQuality.eco
    });
    this.registerPreset(MediaAssetType.image, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, []);
    this.registerPreset(MediaAssetType.video, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, ['fullHd', 'hd', 'eco'], true);
    this.registerPreset(MediaAssetType.document, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, []);
    this.registerPreset(MediaAssetType.raw, DEFAULT_MEDIA_TRANSFORM_PRESET_ID, []);
  }
  registerTransformation(type, name, transformation) {
    this.transformations[type].set(name, {
      transformation
    });
  }
  getTransformation(type, name) {
    const trans = this.transformations[type].get(name);
    if (!trans) throw new Error('MediaTransformation ' + type + ':' + name + ' does not exist!');
    return trans;
  }
  getAllTransformationsForType(type) {
    return this.transformations[type];
  }
  registerPreset(type, name, transformationIds, required = false) {
    const knownTransformations = this.getAllTransformationsForType(type);
    for (const tName of transformationIds) {
      if (!knownTransformations.has(tName)) throw new Error('Registering Media Transformation Preset failed: Transformation named "' + tName + '" is unknown');
    }
    this.presets[type].set(name, {
      name,
      required,
      transformationIds
    });
  }
  getPreset(type, name) {
    if (name === true) name = DEFAULT_MEDIA_TRANSFORM_PRESET_ID;
    const preset = this.presets[type].get(name);
    if (!preset) throw new Error('MediaTransformationsRegistry: unknown resource preset ' + type + ' - ' + name + ' requested!');
    const knownTransformations = this.getAllTransformationsForType(type);
    const transformations = new Map();
    preset.transformationIds.forEach(tName => {
      transformations.set(tName, knownTransformations.get(tName));
    });
    return {
      ...preset,
      transformations
    };
  }
  getTransformationsFromPreset(preset) {
    return Array.from(preset.transformations.values()).map(info => info.transformation);
  }
  getAllPresetsForType(type) {
    const presets = this.presets[type];
    const out = [];
    presets.forEach((data, name) => {
      out.push(this.getPreset(type, name));
    });
    return out;
  }
}