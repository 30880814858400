import { isConstructor } from '@core/shared/utils';
import { Expose, customMetadataStorage } from '@core/shared/model';
import { MEDIA_PROPS_ACCESSOR } from './constants';
// not exported as considered private. Use provided functions to get metadata.
const MEDIA_ASSETS_PROP_TOKEN = '__MEDIA_ASSETS_PROP_TOKEN__';
export function MediaAssets(slotNames, config = {}) {
  return target => {
    if (!IS_PRODUCTION && getMediaAssetsMetadataForModel(config).length) {
      console.log('existing metadata:', getMediaAssetsMetadataForModel(config));
      throw new Error('only one property of a Model can be decorated with @MediaAssets!');
    }
    const propertyKey = config.targetProperty || MEDIA_PROPS_ACCESSOR;
    customMetadataStorage.addMetadata({
      metaType: MEDIA_ASSETS_PROP_TOKEN,
      target: target,
      // propertyName: propertyKey,
      options: {
        ...config,
        targetProperty: propertyKey,
        slotNames
      }
    });
    // no typing here, will be added by MediaAssetRegistry as soon as it is constructed.
    Expose()(target, propertyKey);
  };
}
export function getModelsWithMediaAssetsMetadata() {
  return customMetadataStorage.findModelsByMetadataType(MEDIA_ASSETS_PROP_TOKEN);
}
export function getModelsWithMediaAssetsMetadata$() {
  return customMetadataStorage.findModelsByMetadataType$(MEDIA_ASSETS_PROP_TOKEN);
}
export function getMediaAssetsMetadataForModel(model) {
  const ctor = isConstructor(model) ? model : model.constructor;
  return customMetadataStorage.findMetadataOfType(MEDIA_ASSETS_PROP_TOKEN, ctor);
}