/**
 * sometimes, Angulars implementation of providers is a bit whacky.
 * To make sure a singleton provider is truely singleton,
 * this allows to wrap a factory function with an internal cache.
 */
export function trueSingletonFactory(innerFactory) {
  let cache = null;
  return (...args) => {
    if (cache) return cache;
    cache = innerFactory(...args);
    return cache;
  };
}