import { getItemModelText } from '@core/shared/model';
import { BaseCrudService } from './base-crud.service';
import { catchError, map, of, share, throwError } from 'rxjs';
import { extractDataFromApiResponse } from '@core/shared/request';
import { AppMessage } from '../../messages';
/**
 * SingleCrudService is used for a special kind of CrudEndpoint.
 * Think of a route like /courses/:id/exam.
 * The exam endpoint is a child related to a certain course.
 * It supports CRUD, but without ReadMany.
 */
// not injected, use CrudServiceFactory to create!
export class SingleCrudService extends BaseCrudService {
  constructor(http, modelFactoryProvider, clientCache, state) {
    super(modelFactoryProvider, clientCache, state);
    this.http = http;
    this.modelFactoryProvider = modelFactoryProvider;
    this.clientCache = clientCache;
    this.state = state;
  }
  readMany() {
    throw new Error('Cant read many using SingleCrudService!');
  }
  readOne(id, hot = true) {
    // simple solution to allow working with URL aliases like 12-item or 52:article
    if (typeof id === 'string') id = parseInt(id, 10);
    const req = this.http.get(this.generateApiPath({
      id
    })).pipe(share()).pipe(map(response => {
      return this.ItemFactory.fromData(extractDataFromApiResponse(response, 'SingleCrudService::readOne ' + this.ItemFactory.Model.name));
    })).pipe(catchError(err => {
      if (err.status === 404) {
        // ok in this case, means the item has not yet been created
        if (err instanceof AppMessage) err.discard();
        return of(null);
      } else {
        return throwError(() => err);
      }
    }));
    if (hot) req.subscribe(data => {});
    return req;
  }
  save(item) {
    const req = this.update(item).pipe(catchError(err => {
      if (err.status === 404) {
        // ok in this case, means the item has not yet been created
        if (err instanceof AppMessage) err.discard();
        return this.create(item);
      } else {
        return throwError(() => err);
      }
    }));
    return req;
  }
  create(item, hot = true) {
    item = this.processItemData(item);
    const req = this.http.post(this.generateApiPath(item), item).pipe(share()).pipe(map(response => {
      return this.ItemFactory.fromData(extractDataFromApiResponse(response, 'SingleCrudService::create ' + this.ItemFactory.Model.name));
    }));
    if (hot) req.subscribe(data => {});
    return req;
  }
  update(item, hot = true) {
    if (!item.id) return throwError(() => new Error(getItemModelText('noId', item)));
    if (item instanceof this.ItemFactory.Model) item = this.processItemData(item);
    const req = this.http.patch(this.generateApiPath(item), item).pipe(share()).pipe(map(response => {
      return this.ItemFactory.fromData(extractDataFromApiResponse(response, 'SingleCrudService::update ' + this.ItemFactory.Model.name));
    }));
    if (hot) req.subscribe(data => {});
    return req;
  }
  delete(item, hot = true) {
    if (!item.id) return throwError(() => new Error(getItemModelText('noId', item)));
    item = this.processItemData(item);
    const req = this.http.delete(this.generateApiPath(item)).pipe(share()).pipe(map(response => {
      return response.error === false && response.status === 200;
    }));
    if (hot) req.subscribe(data => {});
    return req;
  }
  createNew(itemToClone) {
    if (itemToClone) {
      return this.ItemFactory.clone(itemToClone);
    } else {
      return this.ItemFactory.newInstance();
    }
  }
  toggleState(item, hot = true) {
    if (typeof item.enabled !== "undefined") {
      item = this.ItemFactory.patch(item, {
        enabled: !item.enabled
      });
      return this.update(item, hot);
    } else {
      return throwError(() => new Error("Fehler: Dieses Item hat keinen Aktivierungsstatus."));
    }
  }
}