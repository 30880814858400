import { arrayIncludesAll } from '../utils';
export function matchContextGroups(baseContext, matcher) {
  // if no context is defined, we default to truthy match
  if (!baseContext || !baseContext.length) return true;
  // matcher has general true/false value
  if (typeof matcher === 'boolean') return matcher === true;
  // matcher is empty
  if (!matcher || !matcher.length) return false;
  return matcher.some(matchingGroup => {
    if (Array.isArray(matchingGroup)) {
      return arrayIncludesAll(baseContext, matchingGroup);
    } else {
      return baseContext.includes(matchingGroup);
    }
  });
}