import { DevToolProviderToken } from '@core/shared/dev-tools';
import { BaseFeature, FeatureType } from '@core/shared/feature';
export class CoreBaseFeature extends BaseFeature {
  static {
    this.featureInfo = {
      context: {"package":"pkg-core-feature","featureId":"common-core"},
      abilityActions: false,
      plugins: [{
        forPlatform: 'frontend',
        importFrom: 'frontend-shared',
        type: FeatureType.Permanent,
        pluginClassName: 'CoreFrontendSharedFeature'
      }, {
        forPlatform: 'frontend',
        importFrom: 'frontend-management',
        type: FeatureType.Permanent,
        pluginClassName: 'CoreFrontendManagementFeature'
      }, {
        forPlatform: 'admin',
        importFrom: 'frontend-shared',
        type: FeatureType.Permanent,
        pluginClassName: 'CoreFrontendSharedFeature'
      }],
      providers: [{
        token: DevToolProviderToken,
        forPlatform: 'frontend',
        providerName: 'clientCacheDevToolProvider',
        importFrom: 'frontend-shared'
      }, {
        token: DevToolProviderToken,
        forPlatform: 'admin',
        providerName: 'clientCacheDevToolProvider',
        importFrom: 'frontend-shared'
      }, {
        token: DevToolProviderToken,
        forPlatform: 'frontend',
        providerName: 'modelMetadataDevToolDefinition',
        importFrom: 'frontend-shared'
      }, {
        token: DevToolProviderToken,
        forPlatform: 'admin',
        providerName: 'modelMetadataDevToolDefinition',
        importFrom: 'frontend-shared'
      }]
    };
  }
  getSharedDefinition() {
    const errorResolvers = {
      'error': () => '/error',
      'error-0': () => '/error/0',
      'error-403': () => '/error/403',
      'error-router': () => '/error/router'
    };
    return {
      routerLinkResolvers: {
        frontend: {
          ...errorResolvers,
          management: () => '/management'
        },
        admin: errorResolvers
      }
    };
  }
}