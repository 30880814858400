/**
 * ModelFactory is not injectable. To use, inject ModelFactoryProvider and use its methods to create a factory.
 */
/**
 * TODO: Why do models need to @Expose() values to make @Default(value) work?
 * If unexposed, fromData will still work, but default fallback is missing.
 */
export class ModelFactory {
  constructor(
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Model, classTransformService) {
    this.Model = Model;
    this.classTransformService = classTransformService;
  }
  newInstance() {
    return this.classTransformService.plainToInstance(this.Model, {});
  }
  fromData(data) {
    return this.classTransformService.plainToInstance(this.Model, data, {});
  }
  clone(item) {
    const data = {
      ...item
    };
    if (typeof data.id !== 'undefined') data.id = undefined;
    return this.classTransformService.plainToInstance(this.Model, data, {});
  }
  patch(model, optionalData = {}) {
    return this.classTransformService.plainToInstance(this.Model, {
      ...model,
      ...optionalData
    }, {});
  }
}