// partially inspired by dot-prop
const disallowedKeys = ['__proto__', 'prototype', 'constructor'];
const isValidPath = pathSegments => !pathSegments.some(segment => disallowedKeys.includes(segment));
function isObj(obj) {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}
function getPathSegments(path) {
  const pathArray = path.split('.');
  const parts = [];
  for (let i = 0; i < pathArray.length; i++) {
    let p = pathArray[i];
    // our paths do not contain \, no need for this.
    // while (p[p.length - 1] === '\\' && pathArray[i + 1] !== undefined) {
    // 	p = p.slice(0, -1) + '.';
    // 	p += pathArray[++i];
    // }
    parts.push(p);
  }
  return parts;
}
export function getDotProp(object, path, defaultVal = null) {
  let match;
  if (object[path]) {
    // exact string match
    match = object[path];
  } else {
    // deep property search
    const crumbs = path.split('.');
    match = crumbs.reduce((subobject, crumb) => {
      return subobject && typeof subobject[crumb] !== 'undefined' ? subobject[crumb] : defaultVal;
    }, object);
  }
  if (typeof match !== 'undefined') return match;
  return defaultVal;
}
;
export function setDotProp(object, path, value) {
  if (typeof object !== 'object' || typeof path !== 'string') return object;
  const root = object;
  const pathArray = getPathSegments(path);
  if (!isValidPath(pathArray)) return false;
  for (let i = 0; i < pathArray.length; i++) {
    const p = pathArray[i];
    if (typeof object[p] !== 'object') object[p] = {};
    if (i === pathArray.length - 1) {
      object[p] = value;
      return true;
    }
    object = object[p];
  }
  return false;
}
;
export function deleteDotProp(object, path) {
  if (!isObj(object) || typeof path !== 'string') return false;
  const pathArray = getPathSegments(path);
  if (!isValidPath(pathArray)) return false;
  let currParent = object;
  for (let i = 0; i < pathArray.length; i++) {
    const segment = pathArray[i];
    if (i === pathArray.length - 1) {
      delete currParent[segment];
      return true;
    }
    currParent = currParent[segment];
    if (Array.isArray(currParent)) {
      // array handling conflicts a bit with the for-loop.
      // instead of rewriting the whole function,
      // we re-execute it on array entries and abort the parent loop by returning.
      const subPath = pathArray.slice(i).join('.');
      const results = currParent.map(entry => {
        if (isObj(entry)) return deleteDotProp(entry, subPath);
      });
      return results.some(r => r === true);
    } else if (!isObj(currParent)) {
      return false;
    }
  }
}
;
export function hasDotProp(object, path) {
  if (!isObj(object) || typeof path !== 'string') return false;
  const pathArray = getPathSegments(path);
  if (!isValidPath(pathArray)) return false;
  for (let i = 0; i < pathArray.length; i++) {
    if (isObj(object)) {
      if (i === pathArray.length - 1) {
        return pathArray[i] in object;
      } else {
        object = object[pathArray[i]];
      }
    } else {
      return false;
    }
  }
  return false;
}
;