import { dayjs } from '@core/shared/utils/dayjs';
export var MembershipReportHelper;
(function (MembershipReportHelper) {
  function getReportTitle(item) {
    return "Bericht bis " + this.formatReportDate(item.toDate);
  }
  MembershipReportHelper.getReportTitle = getReportTitle;
  function formatReportDate(date) {
    return dayjs(date).format('DD.MM.YYYY HH:mm');
  }
  MembershipReportHelper.formatReportDate = formatReportDate;
  // functions commented out due to transition of referrer system from being company based to separate 
  // export function filterReportDataByReferrer(report:GenerateMembershipReportResponse|null, referrerId:number):GenerateMembershipReportResponse|null {
  // 	if(!report) return null;
  // 	const reportCategories = extractReportCategories(report);
  // 	return reportCategories.reduce((filteredResponse, categoryGroup)=>{
  // 		const filteredCategoryList:MembershipReportCategory[] = [];
  // 		categoryGroup.data.forEach(category=>{
  // 			const filteredEntries = category.entries.filter(entry=>{
  // 				return entry.referrer?.id === referrerId;
  // 			})
  // 			if(filteredEntries.length) {
  // 				filteredCategoryList.push({
  // 					...category,
  // 					entries:filteredEntries
  // 				})
  // 			}
  // 		});
  // 		if(filteredCategoryList.length) {
  // 			filteredResponse[categoryGroup.type] = filteredCategoryList;
  // 		}
  // 		return filteredResponse
  // 	},{} as GenerateMembershipReportResponse);
  // }
  // export function findReferrersWithinReportData(report:GenerateMembershipReportResponse|null):CompanyItem[]|null {
  // 	if(!report) return null;
  // 	const referrers:CompanyItem[] = [];
  // 	const reportCategories = extractReportCategories(report)
  // 	reportCategories.forEach(categoryGroup=>{
  // 		categoryGroup.data.forEach(category=>{
  // 			category.entries.forEach(entry=>{
  // 				if(entry.referrer) referrers.push(entry.referrer)
  // 			})
  // 		})
  // 	})
  // 	return arrayUniqueByKey(referrers,'id')
  // }
  function extractReportCategories(data) {
    const typeKeys = Object.keys(data);
    const extracted = typeKeys.reduce((groups, type) => {
      if (data[type]) {
        groups.push({
          type,
          data: data[type]
        });
      }
      return groups;
    }, []);
    return extracted;
  }
  MembershipReportHelper.extractReportCategories = extractReportCategories;
  function getRegistrationDataFieldsAsDotProps(fields) {
    const dotProps = [];
    Object.keys(fields).forEach(subform => {
      const fieldsInForm = fields[subform];
      dotProps.push(...fieldsInForm.map(fieldName => subform + '.' + fieldName));
    });
    return dotProps;
  }
  MembershipReportHelper.getRegistrationDataFieldsAsDotProps = getRegistrationDataFieldsAsDotProps;
})(MembershipReportHelper || (MembershipReportHelper = {}));