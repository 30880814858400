/**
 * recommended URL Source: Inject DOCUMENT and pass document.location.href.
 */
export function getCurrentDomainInfo(url) {
  const urlInfo = new URL(url);
  if (urlInfo.hostname === 'localhost') {
    return {
      topleveldomain: false,
      domain: 'localhost',
      subdomain: false,
      port: urlInfo.port
    };
  }
  const domainParts = urlInfo.hostname.split('.');
  const topleveldomain = domainParts.pop();
  const domain = domainParts.pop();
  const subdomain = domainParts.length > 0 ? domainParts.join('.') : false;
  return {
    topleveldomain,
    domain,
    subdomain,
    port: urlInfo.port
  };
}
export function domainInfoToHostName(info) {
  let domain = info.domain;
  if (info.subdomain) domain = info.subdomain + '.' + domain;
  if (info.topleveldomain) domain += '.' + info.topleveldomain;
  if (info.port) domain += ':' + info.port;
  return domain;
}